import React, { useEffect, useState } from "react";
import { Card, makeStyles, Box } from "@material-ui/core";
import axios from "axios";
import { connect } from "react-redux";
import countryPaymentMethodMap from "src/country-payment-method-map";
import { SetErrors, SetSnackNotice } from "src/redux/app/app-actions";
import { ClearStoreCart, SetStoreCart } from "src/redux/cart/cart.actions";
import { SetCustomerInfo } from "src/redux/current-customer/current-customer-actions";
import { SetStoreInfo } from "src/redux/current-store/current-store-actions";




const PaymentStep = ({ price, currencyPreference, storeInfo, setSnackNotice, setCustomerInfo, setErrors, reloadParent, setPaymentElement, createOrder, creatingOrder }) => {
    const useStyles = makeStyles(theme => ({
        root: {
            width: '100%',
            marginBottom: theme.spacing(2)
        },
        warning: {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText
        },
        error: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText
        },
        success: {
            backgroundColor: theme.palette.success.main,
            color: 'white'
        },
        paymentMethodSelectionSection: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        paymentMethodBox:{
            display: 'grid',
            justifyItems: 'center',
        },
        paymentMethodOption: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(5),
            marginRight: theme.spacing(1),
            flex: 1,
            transition: 'all 0.25s',
            cursor: 'pointer',
            minWidth: '200px',
            maxWidth: '250px',
            marginBottom: theme.spacing(1),
            '& svg': {
                height: '32px',
                marginRight: theme.spacing(1)
            },
            '& img': {
                height: '32px',
                marginRight: theme.spacing(1)
            }
        },
        paymentMethodLogo: {
            height: "100px",
            width: "auto",
        },
        active: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        }
    }));
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState('');

    const classes = useStyles();


    useEffect(() => {

        reloadParent();
        if (selectedMethod) {
            setPaymentElement(countryPaymentMethodMap[selectedMethod].paymentElement(price, paymentMethods.find(payMethod => payMethod.name === selectedMethod), createOrder, creatingOrder));
        }
        else {
            setPaymentElement(null);

        }


    }, [paymentMethods, selectedMethod, creatingOrder]);

    useEffect(() => {
        getStorePaymentMethods();
    }, [currencyPreference]);




    const getStorePaymentMethods = () => {
        if (storeInfo) {
            setSnackNotice({
                message: 'Retrieving store payment methods',
                severity: 'info'
            });
            axios({
                url:
                    'https://kripson-store-server-8qq76.ondigitalocean.app/stores/getPaymentMethods',
                method: 'POST',
                data: {
                    sessionId: storeInfo.sessionId,
                    storeId: storeInfo._id,
                    filter: { availableCountries: { $all: [currencyPreference] } }
                }
            })
                .then(response => {
                    if (response.data.status === 1) {
                        setSnackNotice({
                            message: response.data.message,
                            severity: 'success'
                        });
                        console.log('test');
                        setPaymentMethods(response.data.result);
                    } else if (response.data.status === 3) {
                        setCustomerInfo({});
                        setSnackNotice({
                            severity: 'warning',
                            message: response.data.message
                        });
                    } else {
                        setErrors(response.data.errors);
                    }
                })
                .catch(error => {
                    setSnackNotice({
                        message: 'Payment methods could not be retrieved.',
                        severity: 'error'
                    });
                });
        }
    };


    return (<Box class={classes.paymentMethodSelectionSection}>
        {paymentMethods.map(method => <Box className={classes.paymentMethodBox}> <Card
            className={`${classes.paymentMethodOption} ${selectedMethod === method.name ? classes.active : ''
                }`}
            onClick={e => setSelectedMethod(method.name)}
        >
            <img className={classes.paymentMethodLogo} src={`/static/images/${countryPaymentMethodMap[method.name].logo}`} alt={method.name} />

        </Card>
            <span>{method.name}</span>
        </Box>)}
    </Box>)
}




const mapStateToProps = (state) => ({
    storeId: state.currentStore.storeInfo._id,
    stores: state.cart.stores,
    storeInfo: state.currentStore.storeInfo,
    categories: state.currentStore.categories,
    currentCustomer: state.currentCustomer.customerInfo,
    currencyPreference: state.currentStore.currencyPreference,
});

const mapDispatchToProps = (dispatch) => ({
    setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
    setStoreCart: (cartItems, storeId) => dispatch(SetStoreCart(cartItems, storeId)),
    setErrors: (errors) => dispatch(SetErrors(errors)),
    setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo)),
    ClearStoreCart: (storeId) => dispatch(ClearStoreCart(storeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentStep);