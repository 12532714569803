import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Box,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  verificationAlert: {
    position: 'fixed',
    right: '10px',
    bottom: '10px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginLeft: '10px',
    },
    '& a': {
      textDecoration: 'underline',
      marginLeft: '10px',
    }
  }
}));

const Account = ({ currentCustomer }) => {
  const classes = useStyles();
  const [newProfilePicture, setNewProfilePicture] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    if (!(currentCustomer && currentCustomer._id)) {
      navigate('/login');
    }
  }, [currentCustomer]);

  return (
    <Page
      className={classes.root}
      title="Account"
    >

      <Container maxWidth="lg">
      <Box mb={3}>
        <Typography color="textPrimary" variant="h2">
          Account
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="body2">
          Your account info
        </Typography>
      </Box>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <Profile currentCustomer={currentCustomer} setNewProfilePicture={setNewProfilePicture} />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <ProfileDetails currentCustomer={currentCustomer} newProfilePicture={newProfilePicture} />
          </Grid>
        </Grid>
      </Container>

    </Page>
  );
};

const mapDispatchToProps = dispatch => (
  {

  });
const mapStateToProps = state =>
({

  currentCustomer: state.currentCustomer.customerInfo,
});


export default connect(mapStateToProps, mapDispatchToProps)(Account);
