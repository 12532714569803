import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import {
  SetCurrencyPreferrence
} from 'src/redux/current-store/current-store-actions';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';

import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Typography } from '@material-ui/core';
import { flagCurrencyMap } from 'src/flagCurrencyMap';
import FlagIcon from '../flag-component/flag-component';
import { useTheme } from '@material-ui/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    // '&:focus': {
    //   backgroundColor: theme.palette.primary.main,
    //   '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //     color: theme.palette.common.white,
    //   },
    // },
  }
}))(MenuItem);

function CurrencyMenu({
  storeInfo,
  currencyOption,
  setCurrencyPreferrence,
  currencyPreference
}) {
  const useStyles = makeStyles(theme => ({
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    },
    success: {
      // backgroundColor: theme.palette.success.main,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.success.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    },
    info: {
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.info.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white
        }
      }
    },
    selectedOption: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      }
    }
  }));

  const classes = useStyles();

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <Typography variant="body1">{currencyPreference}</Typography>
        <ArrowDropDownIcon />
      </Box>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {currencyOption && currencyOption.length ? (
          currencyOption.map((currencyOption, idx) => {
            return <StyledMenuItem key={idx} onClick={() => setCurrencyPreferrence(currencyOption)} className={currencyPreference === currencyOption ? classes.selectedOption : ''}>
              <ListItemIcon>
                <FlagIcon code={flagCurrencyMap[currencyOption]} size={'lg'} />
              </ListItemIcon>
              <ListItemText primary={currencyOption} />
            </StyledMenuItem>

          })

        ) : (
          <StyledMenuItem>
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="No options available" />
          </StyledMenuItem>
        )}
      </StyledMenu>
    </>
  );
}

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  currencyPreference: state.currentStore.currencyPreference
});

const mapDispatchToProps = dispatch => ({
  setCurrencyPreferrence: (countryCode) =>
    dispatch(SetCurrencyPreferrence(countryCode)),
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyMenu);
