import axios from "axios";
import Decimal from "decimal.js-light";

export const convertToSelectedCurrency = (amount, currentStore) => {
  const { storeInfo, currencyPreference, currencyExchangeRate, currencyExchangeRateExpiry } = currentStore;
  if (storeInfo.storeCurrency === currencyPreference) {
    return amount;
  }

  return new Decimal(amount)
    .mul(currencyExchangeRate)
    .toDecimalPlaces(2)
    .toString();
};
