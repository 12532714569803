import "react-perfect-scrollbar/dist/css/styles.css";
import React, { useState, useEffect } from "react";
import { useRoutes, useNavigate } from "react-router-dom";
import { fade, ThemeProvider, Card, Box, Typography, makeStyles, colors } from "@material-ui/core";
import GlobalStyles from "src/components/GlobalStyles";
import "src/mixins/chartjs";
import theme from "src/theme";
import routes from "src/routes";
import { connect } from "react-redux";
import contrast from "contrast";
import { SetErrors, SetSnackNotice } from "./redux/app/app-actions";
import * as Swal from "sweetalert2";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { fonts } from "./fontSrc";
import FontLoader from "./components/font-loader/font-loader";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Link } from "react-router-dom";
import { green, yellow } from "@material-ui/core/colors";
import Footer from "./components/footer/footer";
import KripsonStoreChat from "./components/kripsonstore-home-chat/kripsonstore-home-chat";
import { FetchCurrencyExchangeRate } from "./redux/current-store/current-store-actions";

const useStyles = makeStyles((theme) => ({
  verificationAlert: {
    position: "fixed",
    right: "10px",
    bottom: "10px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    "& p": {
      marginLeft: "10px",
    },
    "& a": {
      textDecoration: "underline",
      marginLeft: "10px",
    },
  },
}));

const App = ({ notice, errors, setErrors, storeInfo,fetchCurrencyExchangeRate, setSnackNotice, currentCustomer, currencyPreference }) => {
  const classes = useStyles();
  const routing = useRoutes(routes);
  const [currentTheme, setCurrentTheme] = useState(theme);
  const [currentFont, setCurrentFont] = useState("Roboto");
  const [fontLink, setFontLink] = useState("");

  useEffect(() => {
    if (storeInfo && storeInfo.font) {
      setCurrentFont(storeInfo.font);
    }
  }, [storeInfo]);

  useEffect(() => {
    setFontLink(<FontLoader font={fonts[currentFont]} />);
  }, [currentFont]);

  const [snackBar, setSnackBar] = useState("");

  useEffect(()=>{
    if(currencyPreference){
      fetchCurrencyExchangeRate(storeInfo.storeCurrency, currencyPreference, storeInfo._id);
    }
  }, [currencyPreference])

  useEffect(() => {
    setSnackBar(
      <Snackbar open={notice.message && notice.message.length ? true : false} key={notice.message && notice.message.length ? notice.message : "snackBarKey"} autoHideDuration={5000} onClose={() => setSnackNotice({ message: "", severity: "success" })} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert severity={notice.severity}>{notice.message && notice.message.length ? notice.message : ""}</Alert>
      </Snackbar>
    );
  }, [notice]);

  useEffect(() => {
    if (errors && errors.length) {
      Swal.fire({
        title: "<strong>Oops, something went wrong</strong>",
        icon: "error",
        html: `<div>${errors && errors.length ? errors.reduce((acc, error) => acc + `<p>${error}</p>`, "") : ""}</div>`,
        showCloseButton: true,
        confirmButtonColor: storeInfo.colors.primary ? storeInfo.colors.primary : "#000",
      }).then(() => {
        setErrors([]);
      });
    }
  }, [errors, storeInfo.colors.primary]);

  useEffect(() => {
    console.log(storeInfo.colors.primary);
    setCurrentTheme({
      ...currentTheme,
      typography: {
        ...currentTheme.typography,
        fontFamily: fonts[storeInfo.font || "Roboto"].fontFamily,
        h3: {
          ...currentTheme.typography.h3,
          fontFamily: fonts[storeInfo.font || "Roboto"].fontFamily,
        },
        h4: {
          ...currentTheme.typography.h4,
          fontFamily: fonts[storeInfo.font || "Roboto"].fontFamily,
        },
        h5: {
          ...currentTheme.typography.h5,
          fontFamily: fonts[storeInfo.font || "Roboto"].fontFamily,
        },
        h6: {
          ...currentTheme.typography.h6,
          fontFamily: fonts[storeInfo.font || "Roboto"].fontFamily,
        },
        h7: {
          ...currentTheme.typography.h7,
          fontFamily: fonts[storeInfo.font || "Roboto"].fontFamily,
        },
        h8: {
          ...currentTheme.typography.h8,
          fontFamily: fonts[storeInfo.font || "Roboto"].fontFamily,
        },
        // 'body1':{
        //   ...currentTheme.typography.body1,
        //   fontFamily: fonts[storeInfo.font || 'Roboto'].fontFamily,
        // },
        // 'body2':{
        //   ...currentTheme.typography.body2,
        //   fontFamily: fonts[storeInfo.font || 'Roboto'].fontFamily,
        // },
        button: {
          ...currentTheme.typography.button,
          fontFamily: fonts[storeInfo.font || "Roboto"].fontFamily,
        },
      },
      palette: {
        ...currentTheme.palette,
        primary: {
          ...currentTheme.palette.primary,
          main: storeInfo.colors.primary,
          contrastText: contrast(storeInfo.colors.primary) === "light" ? "#000" : "#fff",
        },
        background: {
          ...currentTheme.palette.background,
          default: storeInfo.colors.background || colors.common.white
        },
        text: {
          ...currentTheme.palette.text,
          primary: storeInfo.colors.title || '#000000'
        }
      },
      overrides: {
        Mui: {
          selected: {
            label: {
              backgroundColor: "red",
            },
          },
        },
        MuiButton: {
          containedPrimary: {
            "&:hover": {
              backgroundColor: fade(storeInfo.colors.primary, currentTheme.palette.action.hoverOpacity * 20),
            },
          },
        },
        MuiTreeItem: {
          root: {
            "&:focus": {
              backgroundColor: "transparent",
            },
            "&$selected > $content $label:hover, &$selected:focus > $content $label , &$selected > $content $label": {
              // backgroundColor: (0, _styles.fade)(theme.palette.primary.main, theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity),
              backgroundColor: "transparent",
              color: storeInfo.colors.primary,
              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                backgroundColor: "transparent",
              },
            },
          },
          iconContainer: {
            display: "none",
          },
        },
        MuiDropzonePreviewList: {
          root: {
            display: "grid",
            gridTemplateColumns: `repeat(auto-fit, minmax(250px, 1fr))`,
          },
          imageContainer: {
            maxWidth: "unset",
          },
          removeButton: {
            top: "20px",
            right: "40px",
          },
        },
      },
    });
  }, [storeInfo.colors.primary, storeInfo]);



  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyles />
      {fontLink}
      {routing}

      {snackBar}
      <Box>
        {/* <Footer /> */}
        <KripsonStoreChat />
      </Box>



      {currentCustomer && currentCustomer._id && !currentCustomer.isVerified ? (
        <Box>
          <Card className={classes.verificationAlert}>
            {currentCustomer && currentCustomer.isVerified ? (
              <>
                <CheckCircleIcon style={{ color: green[500] }} />
                <Typography variant="body2">Verified Account</Typography>
              </>
            ) : (
              <>
                <ErrorIcon style={{ color: yellow[700] }} />
                <Typography variant="body2">
                  Your account is not verified <Link to="/account/verify">Verify Now</Link>
                </Typography>
              </>
            )}
          </Card>
        </Box>
      ) : (
        ""
      )}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  notice: state.app.notice,
  storeInfo: state.currentStore.storeInfo,
  errors: state.app.errors,
  currentCustomer: state.currentCustomer.customerInfo,
  currencyPreference: state.currentStore.currencyPreference
});

const mapDispatchToProps = (dispatch) => ({
  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
  fetchCurrencyExchangeRate: (convertFrom, convertTo, storeId) => dispatch(FetchCurrencyExchangeRate(convertFrom, convertTo, storeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
