import React from 'react';
import { connect } from 'react-redux';
import { FetchStoresStart } from 'src/redux/app/app-actions';
import { SetStoreInfo } from 'src/redux/current-store/current-store-actions';
import './Logo.scss';
const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src={props?.storeInfo?.storeBanner?.url ?? '/static/images/logo.svg'}
      {...props}
      className={'Logo'}
    />
  );
};

const mapStateToProps = (state) => ({
  storeInfo: state.currentStore.storeInfo,
  isFetchingStores: state.app.isFetchingStores,
});

const mapDispatchToProps = (dispatch) => ({
  FetchStoresStart: (filter) => dispatch(FetchStoresStart(filter)),
  // setPreLoader: (notice) => dispatch(SetPreLoader(notice)),
  setStoreInfo: (info) => dispatch(SetStoreInfo(info)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
