import React, { useEffect, useState } from "react";
import "./checkout.styles.scss";
import CheckoutItem from "../../components/checkout-item/checkout-item.component.jsx";
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField, useMediaQuery } from "@material-ui/core";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import CheckoutDetailForm from "src/components/checkout-detail-form/checkout-detail-form";
import axios from "axios";
import { SetSnackNotice, SetErrors } from "../../redux/app/app-actions";
import { ApplyDiscountCodes, ClearStoreCart, SetStoreCart } from "../../redux/cart/cart.actions";
import { validateData } from "../../utils/validation";
import { Typography } from "@material-ui/core";
import Decimal from "decimal.js-light";
import { SetCustomerInfo } from "src/redux/current-customer/current-customer-actions";
import { makeStyles } from "@material-ui/styles";
import ChipInput from "material-ui-chip-input";
import CheckOutItemCard from "src/components/checkout-item-card/checkout-item-card";
import getSymbolFromCurrency from "currency-symbol-map";
import { convertToSelectedCurrency } from "src/utils/convertToSelectedCurrency";
import { FetchCurrencyExchangeRate } from "src/redux/current-store/current-store-actions";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  card: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  orderSummary: {
    display: "flex",
    flexDirection: "column",
    minHeight: "300px",
  },
  orderSummaryContent: {
    display: "grid",
    alignContent: "start",
    gridRowGap: theme.spacing(2),
    flex: 1,
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
    fontWeight: "500",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiChip-deleteIcon": {
      color: "unset",
    },
  },
}));

const CarItemList = ({ cartItems, setSnackNotice, currentStore, fetchingCurrencyRate, applyDiscountCodes, storeCarts, setErrors, storeInfo, total, SetStoreCart }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [discountCodesApplied, setDiscountCodesApplied] = useState([]);

  const checkDiscountCodeValidity = async (code) => {
    setSnackNotice({
      message: "Checking code validity",
      severity: "info",
    });
    const thisDiscountData = await axios({
      url: "https://kripson-store-server-8qq76.ondigitalocean.app/discounts/getDiscounts",
      method: "POST",
      data: {
        storeId: storeInfo._id,
        filter: {
          code: code,
          endDate: { $gte: Date.now() },
        },
      },
    });

    if (thisDiscountData.data.result.length) {
      const thisDiscount = thisDiscountData.data.result[0];

      if (discountCodesApplied.length > 0 && discountCodesApplied[0] !== code && !thisDiscount.canUseInConjuction) {
        setSnackNotice({
          message: `The code ${code} cannot be used in combination with other discount codes`,
          severity: "error",
        });
      } else {
        setSnackNotice({
          message: `Valid code`,
          severity: "success",
        });
        return true;
      }
    } else {
      setSnackNotice({
        message: `The code (${code}) is not valid`,
        severity: "error",
      });
      return false;
    }
  };

  useEffect(() => {
    if (storeCarts && Object.keys(storeCarts).length && storeInfo && storeInfo._id) {
      const storeCart = storeCarts[storeInfo._id];
      if (storeCart) {
        setDiscountCodesApplied(storeCart.discountCodesApplied ? storeCart.discountCodesApplied : []);
      }
    }
  }, [storeCarts, storeInfo]);

  const handleAdd = async (code) => {
    const result = await checkDiscountCodeValidity(code);

    if (result) {
      applyDiscountCodes(discountCodesApplied.concat(code), storeInfo._id, cartItems, {});
    } else {
    }
  };

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12} lg={8}>
        {isSmallScreen ? (
          ""
        ) : (
          <Card className="checkout-page">
            <div className="checkout-header">
              <div className="header-block">
                <Typography color="textPrimary" variant="h5">
                  Product
                </Typography>
              </div>
              <div className="header-block">
                <Typography color="textPrimary" variant="h5">
                  Title
                </Typography>
              </div>
              <div className="header-block">
                <Typography color="textPrimary" variant="h5">
                  Quantity
                </Typography>
              </div>
              <div className="header-block">
                <Typography color="textPrimary" variant="h5">
                  Price
                </Typography>
              </div>
              <div className="header-block">
                <Typography color="textPrimary" variant="h5">
                  Size
                </Typography>
              </div>
              <div className="header-block">
                <Typography color="textPrimary" variant="h5">
                  Remove
                </Typography>
              </div>
            </div>

            <Divider />
            {cartItems.map((item, idx) => (
              <>
                <CheckoutItem key={item.item._id + idx} cartItem={item} />
                <Divider />
              </>
            ))}
          </Card>
        )}

        {isSmallScreen ? cartItems.map((item, idx) => <CheckOutItemCard key={item.item._id + idx} cartItem={item} />) : ""}
      </Grid>
      <Grid item xs={12} lg={4}>
        <Card className={`footer-block ${classes.card} ${classes.orderSummary}`}>
          <CardHeader title=" Order Summary" subheader="September 14, 2016"></CardHeader>
          <Divider />
          <CardContent className={classes.orderSummaryContent}>
            <Typography variant="h5">Apply Discount Codes</Typography>
            <ChipInput
              name="discountTags"
              label="Discount codes"
              classes={{
                chip: classes.chip,
              }}
              variant="outlined"
              helperText="Type code and press Enter"
              fullWidth
              fullWidthInput
              value={discountCodesApplied}
              onAdd={(chip) => {
                if (cartItems && cartItems.length > 0) {
                  handleAdd(chip);
                } else {
                  setSnackNotice({
                    message: "Your cart is empty.",
                    severity: "error",
                  });
                }
              }}
              onDelete={(chip) => {
                applyDiscountCodes(
                  discountCodesApplied.filter((c) => c !== chip),
                  storeInfo._id,
                  cartItems,
                  {}
                );
              }}
            />
            {/* <Box mt={3}>
              <Button variant="contained" color="primary" onClick={() => applyDiscountCodes(discountCodesApplied, storeInfo._id, cartItems, {})} fullWidth disabled={cartItems.length === 0 || discountCodesApplied.length === 0}>
                Apply
              </Button>
            </Box> */}
          </CardContent>
          <Divider />
          <Box className="total" display="flex" justifyContent="flex-end" px={2} py={1}>
            <Typography className="price" color="textPrimary" variant="h4" align="center">
              Total: {fetchingCurrencyRate ? <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} /> : `  ${getSymbolFromCurrency(currentStore.currencyPreference)} ` + convertToSelectedCurrency(total, currentStore)}
            </Typography>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  storeId: state.currentStore.storeInfo._id,
  stores: state.cart.stores,
  storeInfo: state.currentStore.storeInfo,
  currentCustomer: state.currentCustomer.customerInfo,
  storeCarts: state.cart.stores,
  currentStore: state.currentStore,
  fetchingCurrencyRate: state.currentStore.fetchingCurrencyRate
});

const mapDispatchToProps = (dispatch) => ({
  applyDiscountCodes: (codes, storeId, cartItems, customer) => dispatch(ApplyDiscountCodes(codes, storeId, cartItems, customer)),
  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
  setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo)),
  ClearStoreCart: (storeId) => dispatch(ClearStoreCart(storeId)),
  SetStoreCart: (items, storeId) => dispatch(SetStoreCart(items, storeId)),
  fetchCurrencyExchangeRate: (convertFrom, convertTo, storeId) => dispatch(FetchCurrencyExchangeRate(convertFrom, convertTo, storeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarItemList);
