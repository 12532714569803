import React, { useState, useEffect } from 'react';
import {
    Container,
    Card,
    Button,
    Box,
    Typography,
    makeStyles,
    TextField,
    TableCell,
    TableRow,
    TableHead,
    TableContainer,
    Table,
    TableBody,
    Paper,
    ButtonBase,
    useMediaQuery,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import { connect } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SetSnackNotice, SetErrors } from '../../../redux/app/app-actions';
import { SetCustomerInfo } from '../../../redux/current-customer/current-customer-actions';
import { Link as RouterLink } from 'react-router-dom';
import OrderHistoryCard from 'src/components/order-history-card/order-history-card';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    verifyAccountContainer: {
        display: 'grid',
        padding: theme.spacing(2)
    },
    verifyButtonBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

    },
    resendLink: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: 'white'
    },
    table: {
        minWidth: 650,
    },
    tableContainer: {
        margin: 'auto'
    },
    cardGrid: {
        display: 'grid',
        gridRowGap: theme.spacing(1)
    },
    orderCardContainer: {
        display: 'grid',
        gridRowGap: theme.spacing(1)
    },
    paginator: {
        justifySelf: 'center'
    }
}));

const OrderHistory = ({ currentCustomer, setSnackNotice, setCustomerInfo, setErrors }) => {
    const classes = useStyles();
    const [orders, setOrders] = useState([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const isSmallScreen = useMediaQuery("(max-width:1050px)");
    const navigate = useNavigate();

    useEffect(() => {
        if (!(currentCustomer && currentCustomer._id)) {
          navigate('/login');
        }
      }, [currentCustomer]);

    useEffect(() => {
        if (totalOrders && page >= Math.ceil(totalOrders / limit)) {
            setPage(Math.ceil(totalOrders / limit) - 1);
        }
    }, [totalOrders]);

    useEffect(() => {
        (async () => {
            const filter = {};

            if (currentCustomer._id) {
                const response = await axios({
                    url: 'https://kripson-store-server-8qq76.ondigitalocean.app/customers/getOrders',
                    method: 'POST',
                    data: {
                        filter: { customerId: currentCustomer._id },
                        page: page || 0,
                        limit: limit,
                        skip: page * limit,
                        sessionId: currentCustomer.sessionId
                    }
                });

                if (response.data.status === 1) {
                    setTotalOrders(response.data.totals);
                    if (response.data.result.length === 0) {
                        setPage(0);
                    }
                    setOrders(response.data.result);
                } else if (response.data.status === 3) {
                    setCustomerInfo({});
                } else {
                    return [];
                }
            }
        })();
    }, [currentCustomer, page, limit]);

    useEffect(() => {
        if (!currentCustomer.sessionId) {
            navigate('/login');
        }
    }, [currentCustomer]);

    const handleLimitChange = event => {
        setLimit(event.target.value);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <Page
            className={classes.root}
            title="Order History"
        >
            <Container maxWidth="lg">
                <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                        Order History
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2">
                        Your order history
                    </Typography>
                </Box>

                {isSmallScreen
                    ?
                    //if small screen show cards
                    <Box className={classes.orderCardContainer}>
                        <Box className={classes.cardGrid}>
                            {orders ? orders.map((order, idx) => <OrderHistoryCard order={order} key={idx} />) : ''}
                        </Box>
                    </Box>
                    :
                    //if large screen show table
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Order Number</TableCell>
                                    <TableCell align="left">Store Name</TableCell>
                                    <TableCell align="left">Order total</TableCell>
                                    <TableCell align="left">Order Date</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders ? orders.map((order, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell component="th" scope="row">
                                            {order.orderNumber}
                                        </TableCell>
                                        <TableCell align="left">{order.storeName}</TableCell>
                                        <TableCell align="left">{`Rs ${order.total}`}</TableCell>
                                        <TableCell align="left">{`${new Date(order.orderDate)}`}</TableCell>
                                        <TableCell align="left">
                                            <RouterLink to={`/account/orders/${order.storeId}/${order.orderId}`}><Button variant="contained" color="primary">
                                                Details
                                            </Button></RouterLink>
                                        </TableCell>
                                    </TableRow>
                                )) : ''}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
                    <Pagination
                        color="primary"
                        count={Math.ceil(totalOrders / limit) || 1}
                        size="small"
                        className={classes.paginator}
                        onChange={(e, epage) => {
                            handlePageChange(e, epage - 1);
                        }}
                        variant="outlined"
                        shape="rounded"
                    />
                </Box>

            </Container>
        </Page>
    );
};

const mapDispatchToProps = dispatch => (
    {
        setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
        setErrors: (errors) => dispatch(SetErrors(errors)),
        setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo)),
    });
const mapStateToProps = state =>
({

    currentCustomer: state.currentCustomer.customerInfo,

});


export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
