import React, { useEffect, useState } from "react";
import "./checkout-item.styles.scss";

import { connect } from "react-redux";
import { RemoveItem } from "../../redux/cart/cart.actions.js";
import { DecreaseQuantity } from "../../redux/cart/cart.actions.js";
import { AddItem } from "../../redux/cart/cart.actions.js";
import DeleteIcon from "@material-ui/icons/Delete";
import { Typography } from "@material-ui/core";
import Decimal from "decimal.js-light";
import { FetchCurrencyExchangeRate } from "src/redux/current-store/current-store-actions";
import getSymbolFromCurrency from "currency-symbol-map";
import { convertToSelectedCurrency } from "src/utils/convertToSelectedCurrency";
import { Skeleton } from "@mui/material";

function CheckoutItem({ cartItem, RemoveItem, fetchingCurrencyRate, storeInfo, DecreaseQuantity, AddItem, storeId, currentStore, fetchCurrencyExchangeRate }) {
  const { title, images, price, selectedSize } = cartItem.item;
  const [totalDiscountedAmount, setTotalDiscountedAmount] = useState('0');
  const { quantity, discountData } = cartItem;

  useEffect(() => {

    let total = new Decimal('0');
    if (cartItem.hasOwnProperty('discountData')) {
      for (const key in discountData) {
        total = total.plus(new Decimal(discountData[key].discountAmountApplied));
      }
    }
    setTotalDiscountedAmount(total.toString());
  }, [cartItem, discountData])


  return (
    <div className="checkout-item">
      <div className="image-container">
        <img src={images[0].url} alt="item" />
      </div>
      <Typography className="title" color="textPrimary" variant="h5">
        {title}
      </Typography>
      <span className="quantity">
        <div className="arrow" onClick={() => DecreaseQuantity(cartItem.item, storeId)}>
          &#10094;
        </div>
        <Typography className="value" color="textPrimary" variant="h5">
          {quantity}
        </Typography>
        {
          quantity !== cartItem.item.stockQuantity[cartItem.item.selectedSize] ? <div className="arrow" onClick={() => AddItem(cartItem.item, storeId)}>
            &#10095;
          </div> : ''
        }
      </span>
      <Typography className="price" color="textPrimary" variant="h5">
      {fetchingCurrencyRate ? <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={100} /> :
        totalDiscountedAmount !== '0' ?
          <><del>{`  ${getSymbolFromCurrency(currentStore.currencyPreference)} ` + convertToSelectedCurrency(new Decimal(price).times(quantity).toString(), currentStore)
          }</del> <ins style={{ color: 'red' }}>{`  ${getSymbolFromCurrency(currentStore.currencyPreference)} ` + convertToSelectedCurrency(new Decimal(price).times(quantity).sub(totalDiscountedAmount).toString(), currentStore)}</ins></>

          : `  ${getSymbolFromCurrency(currentStore.currencyPreference)} ` + convertToSelectedCurrency(new Decimal(price).times(quantity).toString(), currentStore)}
      </Typography>
      <Typography className="size" color="textPrimary" variant="h5">
        {selectedSize}
      </Typography>
      <DeleteIcon className="remove-button" onClick={() => RemoveItem(cartItem.item, storeId)} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  stores: state.cart.stores,
  storesCart: state.cart.stores,
  currentStore: state.currentStore,
  fetchingCurrencyRate: state.currentStore.fetchingCurrencyRate
});

const mapDispatchToProps = (dispatch) => ({
  RemoveItem: (item, storeId) => dispatch(RemoveItem(item, storeId)),
  AddItem: (item, storeId) => dispatch(AddItem(item, storeId)),
  DecreaseQuantity: (item, storeId) => dispatch(DecreaseQuantity(item, storeId)),
  fetchCurrencyExchangeRate: (convertFrom, convertTo, storeId) => dispatch(FetchCurrencyExchangeRate(convertFrom, convertTo, storeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutItem);
