import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  Chip,
  Container,
  Hidden,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import OrderProductCard from 'src/components/order-product-card/order-product-card';
import Page from 'src/components/Page';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import AwesomeSliderStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import '../../../customStyles.scss';
import { useNavigate, useParams } from "react-router-dom";
import { FetchCurrencyExchangeRate, FetchStoreOrdersStart } from "../../../redux/current-store/current-store-actions";
import { SetErrors, SetSnackNotice } from "../../../redux/app/app-actions";
import { connect } from "react-redux";
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import * as Swal from "sweetalert2";
import EditOrderForm from "./edit-order-form";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import momentTimeZone from 'moment-timezone';
import RefundForm from './refund-form';
import { AddCircleOutline } from '@material-ui/icons';
import Decimal from 'decimal.js-light';
import { SetCustomerInfo } from 'src/redux/current-customer/current-customer-actions';
import CustomNoRowsOverlay from '../../../components/custom-no-rows/custom-no-rows';
import NoDataView from 'src/views/errors/NoDataView';
import getSymbolFromCurrency from 'currency-symbol-map';
import { convertToSelectedCurrency } from 'src/utils/convertToSelectedCurrency';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3),
    width: '100%',
  },
  orderDetailsSection: {
    padding: '20px !important',
    display: 'grid',
    alignContent: 'start',
    gridRowGap: '20px'
  },
  orderColor: {
    height: '25px',
    width: '25px',
    borderRadius: '25px'
  },
  sizeOptionContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 50px)',
    gridColumnGap: '10px'
  },
  sizeChoice: {
    backgroundColor: 'white',
    display: 'grid',
    justifyContent: 'center'
  },
  statusSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  detailsSectionRows: {
  },
  container: {
    maxHeight: 440,
  },
  customerDetailsSection: {
    marginBottom: '16px',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    marginLeft: theme.spacing(1),
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginLeft: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    marginLeft: theme.spacing(1)
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  }

}));

const OrderDetailView = ({ setSnackNotice, currentCustomer, setErrors, setCustomerInfo, currentStore, fetchCurrencyExchangeRate }) => {
  const classes = useStyles();

  const { storeId, orderId } = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState({});
  const [storeInfo, setStoreInfo] = useState(null);
  const [mode, setMode] = useState('detailMode');
  const [productImages, setProductImages] = useState({});
  const [content, setContent] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);


  useEffect(() => {
    retrieveStore(storeId);
  }, [storeId])

  const retrieveStore = (storeId) => {
    axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/stores',
      method: 'POST',
      data: {
        sessionId: currentCustomer.sessionId,
        filter: {
          _id: storeId
        }
      }
    }).then((response) => {
      if (response.data.status === 1) {
        setStoreInfo(response.data.result[0])
      }
      else if (response.data.status === 3) {
        setCustomerInfo({});
        setSnackNotice({
          severity: 'warning',
          message: response.data.message
        });
      }
      else {
        setErrors(response.data.errors);
        setSnackNotice({
          message: 'The store that was associated with this order has been shut down.',
          severity: 'error'
        });
      }

    });
  }

  const retrieveOrder = (orderId, storeId) => {
    axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/orders/getOrderById',
      method: 'POST',
      data: {
        sessionId: currentCustomer.sessionId,
        storeId: storeId,
        orderId: orderId
      }
    }).then((response) => {
      if (response.data.status === 1) {
        if (!response.data.result.length) {
          setContent(<NoDataView message="Looks like the order has been deleted from our database." />)
        }
        else {
          setOrder(response.data.result[0])
        }

      }
      else if (response.data.status === 3) {
        setCustomerInfo({});
        setSnackNotice({
          severity: 'warning',
          message: response.data.message
        });
      }
      else {
        setErrors(response.data.errors);
        setSnackNotice({
          message: 'Product update failed',
          severity: 'error'
        });
      }

    });
  }

  const retrieveOrderProductImages = (productIds, storeId) => {
    axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/products/getProductImagesById',
      method: 'POST',
      data: {
        sessionId: currentCustomer.sessionId,
        storeId: storeId,
        productIds: productIds
      }
    }).then((response) => {
      if (response.data.status === 1) {
        const productImagesMap = {};
        console.log(response.data)
        response.data.result.map(product => {
          productImagesMap[product._id] = product.images[0].url;
          return;
        });
        setProductImages(productImagesMap);
      }
      else if (response.data.status === 3) {
        setCustomerInfo({});
        setSnackNotice({
          severity: 'warning',
          message: response.data.message
        });
      }
      else {
        setErrors(response.data.errors);
        setSnackNotice({
          message: 'Product update failed',
          severity: 'error'
        });
      }

    });
  }

  useEffect(() => {
    if (!(currentCustomer && currentCustomer._id)) {
      navigate('/login');
    }
  }, [currentCustomer]);

  useEffect(() => {

    if (orderId && storeId) {
      retrieveOrder(orderId, storeId);
    }
  }, [orderId, storeId]);


  useEffect(() => {
    if (order && order.products && order.products.length) {
      retrieveOrderProductImages(order.products.map(product => product._id), storeId)
    }
  }, [order]);



  useEffect(() => {
    if (mode === 'detailMode' && orderId && storeId) {
      retrieveOrder(orderId, storeId);
    }
  }, [mode]);


  const deleteOrder = async (orderId) => {
    setSnackNotice({
      message: `Deleting order`,
      severity: 'info'
    });

    const response = await axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/orders/removeOrder',
      method: 'POST',
      onUploadProgress: progressEvent => console.log(progressEvent),
      data: {
        sessionId: currentCustomer.sessionId,
        orderId,
        storeId
      },
    });
    if (response.data.status === 1) {
      navigate(`/app/orders`);

      setSnackNotice({
        message: `Order deleted`,
        severity: 'success'
      });
    }
    else if (response.data.status === 3) {
      setCustomerInfo({});
      setSnackNotice({
        severity: 'warning',
        message: response.data.message
      });
    }
    else {
      setErrors(response.data.errors);
    }
  }


  const fireDeleteConfirmation = (orderId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        deleteOrder(orderId);

        // await Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success'
        // )
      }
    })
  }

  const getOrderStatusIcon = (status) => {
    switch (status) {
      case 'processing':
        return <CachedIcon />;
      case 'cancelled':
      case 'refunded':
        return <ClearIcon />;
      case 'delivered':
        return <CheckIcon />;
      default:
        return;
    }
  }

  const getOrderStatusClass = (status) => {
    switch (status) {
      case 'processing':
        return classes.warning;
      case 'cancelled':
      case 'refunded':
        return classes.error;
      case 'delivered':
        return classes.success;
      default:
        return;
    }
  }



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: 'productImage', label: 'Product', minWidth: 100 },
    { id: 'title', label: 'Title', minWidth: 100 },
    {
      id: 'quantity',
      label: 'Quantity',
      minWidth: 50,
      align: 'center',
      format: (value) => value.toLocaleString('en-IN'),
    },
    {
      id: 'selectedSize',
      label: 'Size',
      minWidth: 50,
      align: 'center',
      format: (value) => value.toFixed(2),
    },
    {
      id: 'price',
      label: 'Price',
      minWidth: 100,
      align: 'left',
      format: (value) => value.toLocaleString('en-IN'),
    },
    {
      id: 'removeButton',
      label: '',
      minWidth: 25,
      align: 'left',
      format: (value) => value.toFixed(2),
    },
  ];


  useEffect(() => {
    if (order) {
      switch (mode) {
        case 'editMode':
          setContent(<EditOrderForm order={order} setMode={setMode} productImages={productImages} storeInfo={storeInfo} />);
          break;
        case 'refundWatchMode':
          setContent(<RefundForm order={order} mode={mode} setMode={setMode} productImages={productImages} storeInfo={storeInfo} />);
          break;
        case 'refundMode':
          setContent(<RefundForm order={order} mode={mode} setMode={setMode} productImages={productImages} storeInfo={storeInfo} />);
          break;
        default:
          setContent(<Page className={classes.root} title="Order Details">
            <Box mb={2} className={classes.statusSection}>
              <Box
                display="flex"
                alignItems="center"
                style={{ flexWrap: 'wrap', marginRight: '5px', marginBottom: '5px' }}
              >
                <Typography variant="h5">Created Date : </Typography>

                <Typography variant="h5">
                  {momentTimeZone(order.createdDate)
                    .tz('Asia/Kathmandu')
                    .format('DD/MM/YYYY')}
                </Typography>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                style={{ marginBottom: '5px', flexWrap: 'wrap' }}
              >
                <Box display="flex" alignItems="center" style={{ flexWrap: 'wrap' }}>
                  <Chip
                    className={getOrderStatusClass(order.status)}
                    icon={getOrderStatusIcon(order.status)}
                    label={order.status}
                    style={{ marginBottom: '2px' }}
                  />
                  {order && order.tags
                    ? order.tags.map(tag => (
                      <Chip
                        style={{ marginLeft: '0',  marginBottom: '2px' }}
                        className={classes.error}
                        label={tag}
                      ></Chip>
                    ))
                    : ''}
                </Box>
              </Box>
            </Box>

            <Paper className={classes.customerDetailsSection}>
              <Box p={2}>
                <Typography fullWidth variant="h3" mb={2}>
                  Customer Details
                </Typography>
              </Box>

              <Divider />
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h5">Name</Typography>
                    <Typography variant="body2">
                      {order && Object.keys(order).length ? order.customer.name : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h5">Contact Details</Typography>
                    <Typography variant="body2">
                      {order && Object.keys(order).length
                        ? order.customer.phonenumber
                        : ''}
                    </Typography>
                    <Typography variant="body2">
                      {order && Object.keys(order).length ? order.customer.email : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="h5">Shipping Address</Typography>
                    <Typography variant="body2">
                      {order && Object.keys(order).length
                        ? order.customer.address
                        : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </Box>
            </Paper>
            {(order && order.tags && order.tags.includes('fully refunded')) || Date.now() > order.createdDate + (storeInfo && storeInfo.refundDuration ? storeInfo.refundDuration : 0) * 86400000 ? (
              <>
                <Hidden xsDown>
                  <Box my={2} display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setMode('refundWatchMode')}
                    >
                      See refund history
                    </Button>
                  </Box>
                </Hidden>

                <Hidden smUp>
                  <Box my={2} display="block">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setMode('refundMode')}
                      fullWidth
                    >
                      See refund history
                    </Button>
                  </Box>
                </Hidden>
              </>
            ) : (
              <>
                <Hidden xsDown>
                  <Box my={2} display="flex" justifyContent="flex-end">
                    <Button
                      startIcon={<AddCircleOutline />}
                      variant="contained"
                      color="primary"
                      disabled={Date.now() > (order.createdDate + (storeInfo && storeInfo.refundDuration ? storeInfo.refundDuration : 0) * 86400000)}
                      onClick={() => setMode('refundMode')}
                    >
                      Create Refund
                    </Button>
                  </Box>
                </Hidden>

                <Hidden smUp>
                  <Box my={2} display="block">
                    <Button
                      startIcon={<AddCircleOutline />}
                      variant="contained"
                      color="primary"
                      disabled={Date.now() > (order.createdDate + (storeInfo && storeInfo.refundDuration ? storeInfo.refundDuration : 0) * 86400000)}
                      onClick={() => setMode('refundMode')}
                      fullWidth
                    >
                      Create Refund
                    </Button>
                  </Box>
                </Hidden>
              </>
            )}
            <Box display={{ xs: 'none', md: 'block' }}>
              <Paper>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map(column => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order && order.products
                        ? order.products
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((product, idx) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={product._id + product.selectedSize}
                              >
                                {columns.map(column => {
                                  let value = '';
                                  const discountData = product.discountData;
                                  let discountApplied = new Decimal('0');
                                  if (discountData && Object.keys(discountData)) {
                                    for (const key in discountData) {
                                      discountApplied = discountApplied.plus(
                                        discountData[key]['discountAmountApplied']
                                      );
                                    }
                                  }

                                  switch (column.id) {
                                    case 'productImage':
                                      value = Object.keys(productImages).length ? (
                                        <Avatar src={productImages[product._id]} />
                                      ) : (
                                        ''
                                      );
                                      break;
                                    case 'price':
                                      value =
                                        discountApplied.toString() !== '0' ? (
                                          <>
                                            <del>

                                              {storeInfo ? storeInfo.storeCurrencySymbol : ''} {new Decimal(product.price)
                                                .times(product.quantity)
                                                .toString()}
                                            </del>
                                            <ins style={{ color: 'red' }}>
                                              {storeInfo ? storeInfo.storeCurrencySymbol : ''} {new Decimal(product.price)
                                                .times(product.quantity)
                                                .sub(discountApplied)
                                                .toString()}
                                            </ins>
                                          </>
                                        ) : (
                                          <span>{storeInfo ? storeInfo.storeCurrencySymbol : ''} {` ${new Decimal(product.price)
                                            .times(product.quantity)
                                            .sub(discountApplied)
                                            .toString()}`}</span>
                                        )
                                      break;
                                    default:
                                      value = product[column.id];
                                  }

                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      {column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })
                        : ''}
                      <TableRow>
                        <TableCell>
                          <Typography variant="h4">Order Total</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <Typography variant="h4">
                            {storeInfo ? storeInfo.storeCurrencySymbol : ''}  {order ? order.total : ''}
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={order && order.products ? order.products.length : 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
            <Box display={{ xs: 'block', md: 'none' }} mt={5}>
              <Typography variant="h3" style={{ marginBottom: '16px' }}>
                Ordered Products
              </Typography>
              {order && order.products
                ? order.products.map((product, idx) => (
                  <OrderProductCard
                    product={product}
                    storeInfo={storeInfo}
                    productImage={
                      product && productImages ? productImages[product._id] : ''
                    }
                  />
                ))
                : ''}
            </Box>
            <Hidden xsDown>
              <Box my={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => setMode('editMode')}
                  style={{ marginRight: '8px' }}
                >
                  Edit
                </Button>
                {/* <Button
                          className={classes.error}
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          onClick={() => fireDeleteConfirmation(orderId)}
                        >
                          Delete
                        </Button> */}
              </Box>
            </Hidden>

            <Hidden smUp>
              <Box my={2} display="block">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => setMode('editMode')}
                  style={{ marginBottom: '8px' }}
                >
                  Edit
                </Button>
                {/* <Button
                          fullWidth
                          className={classes.error}
                          style={{ marginLeft: '0' }}
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          onClick={() => fireDeleteConfirmation(orderId)}
                        >
                          Delete
                        </Button> */}
              </Box>
            </Hidden>
          </Page>)
          break;
      }
    }
    else {
      setContent('');
    }

  }, [mode, order, productImages, storeInfo])





  return content;
};

const mapStateToProps = state =>
({
  currentCustomer: state.currentCustomer.customerInfo,
  currentStore: state.currentStore
});

const mapDispatchToProps = dispatch => (
  {
    setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
    setErrors: (errors) => dispatch(SetErrors(errors)),
    setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo)),
    fetchCurrencyExchangeRate: (convertFrom, convertTo, storeId) => dispatch(FetchCurrencyExchangeRate(convertFrom, convertTo, storeId)),
  });

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailView);
