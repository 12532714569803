import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Divider,
  CardHeader,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import CardMedia from "@material-ui/core/CardMedia";
import * as FA from 'react-fontawesome';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1),
    transform: 'scale(1.25)'
  },
  cardHeader:{
    minHeight: '200px',
    minWidth: '300px',
    backgroundSize:'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition:'center center',
    '&:hover':{
      '& $hoverImage':{
        display: 'block'
      }
    }
   

  },
  hoverImage:{
    display:'none',
    minHeight: '200px',
    minWidth: '100%',
    backgroundSize:'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition:'center center'
  }
}));

const SimilarProductCard = ({ className, product, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      
    
      <div className={classes.cardHeader} style={{ backgroundImage:`url(${product.images[0].url})`}}>
      <div className={classes.hoverImage} style={product.images.length > 1 ?{backgroundImage:`url(${product.images[1].url})`}:{backgroundImage:`url(${product.images[0].url})`}}></div>

      </div>
    </Card>
  );
};

SimilarProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default SimilarProductCard;
