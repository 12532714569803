import {createSelector} from 'reselect';

export const selectCart = state => state.cart;

export const selectStoreCart = (storeId)=>createSelector(

	[selectCart],
	cart=>cart[storeId]
	);

export const selectCartItems = (storeId)=>createSelector(

	[selectCart],
	cart=>cart[storeId].cartItems
	);



export const selectCartItemsCount = createSelector(

	[selectCartItems],
	cartItems=>cartItems.reduce((totalNumberOfItems,cartItem)=>totalNumberOfItems + cartItem.quantity,0)


	)

export const selectCartItemsTotal = createSelector(
	[selectCartItems],
	cartItems=>cartItems.reduce((totalCostOfItems,cartItem)=>totalCostOfItems + cartItem.quantity * cartItem.price,0)
	);


export const getCartStatus = createSelector(
	[selectCart],
	(cart)=>cart.hidden
	);