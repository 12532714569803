import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import InputIcon from "@material-ui/icons/Input";
import Logo from "src/components/Logo/Logo";
import { Decimal } from "decimal.js-light";
import { connect } from "react-redux";
import CurrencyMenu from "src/components/currency-menu/currency-menu";
import AccountMenu from "src/components/account-menu/account-menu";
import LogoutIcon from "@mui/icons-material/Logout";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 60,
    height: 60,
  },
  link: {
    color: "inherit",
  },
}));

const TopBar = ({
  className,
  currentCustomer,
  onMobileNavOpen,
  storeInfo,
  stores,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    console.log(storeInfo, stores);
    if (storeInfo._id && stores && stores.hasOwnProperty(storeInfo._id)) {
      setCartItems([...stores[storeInfo._id].cartItems]);
    }
  }, [storeInfo, stores]);

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          {storeInfo && storeInfo.includeOnlinePayment ? (
            <RouterLink
              className={classes.link}
              to={storeInfo._id ? `/stores/${storeInfo._id}/checkout` : ""}
            >
              <IconButton color="inherit">
                <Badge
                  badgeContent={cartItems.reduce(
                    (acc, currrentItem) => acc + currrentItem.quantity,
                    0
                  )}
                  color="error"
                >
                  <ShoppingCartOutlinedIcon />
                </Badge>
              </IconButton>
            </RouterLink>
          ) : (
            ""
          )}
          <IconButton color="inherit">
            <Badge color="error">
              <CurrencyMenu currencyOption={["AUD", "NPR"]} />
            </Badge>
          </IconButton>
          {currentCustomer && currentCustomer._id ? (
            <AccountMenu />
          ) : (
            <RouterLink className={classes.link} to="/login">Login</RouterLink>
          )}
        </Hidden>
        <Hidden lgUp>
          {storeInfo && storeInfo.includeOnlinePayment ? (
            <RouterLink
              className={classes.link}
              to={storeInfo._id ? `/stores/${storeInfo._id}/checkout` : ""}
            >
              <IconButton color="inherit">
                <Badge
                  badgeContent={cartItems.reduce(
                    (acc, currrentItem) => acc + currrentItem.quantity,
                    0
                  )}
                  color="error"
                >
                  <ShoppingCartOutlinedIcon />
                </Badge>
              </IconButton>
            </RouterLink>
          ) : (
            ""
          )}
          <IconButton color="inherit">
            <Badge color="error">
              <CurrencyMenu currencyOption={["AUD", "NPR"]}/>
            </Badge>
          </IconButton>
          {currentCustomer && currentCustomer._id ? (
            <AccountMenu />
          ) : (
            <RouterLink className={classes.link} to="/login">Login</RouterLink>
          )}
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

const mapStateToProps = (state) => ({
  stores: state.cart.stores,
  storeInfo: state.currentStore.storeInfo,
  currentCustomer: state.currentCustomer.customerInfo,
});

const mapDispatchToProps = (dispatch) => ({
  // FetchStoreProductsStart: (requiredInfo) => dispatch(FetchStoreProductsStart(requiredInfo)),
  // setPreLoader: (notice) => dispatch(SetPreLoader(notice)),
  // AddItem: (item, storeId) => dispatch(AddItem(item, storeId))
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
