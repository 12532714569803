import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
    Box,
    Button,
    Checkbox,
    Container,
    FormHelperText,
    Link,
    TextField,
    Typography,
    makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { SetPreLoader } from "../../redux/app/app-actions";
import { SetSnackNotice, SetErrors } from "../../redux/app/app-actions";
import { connect } from "react-redux";
import axios from 'axios';
import { DropzoneArea } from "material-ui-dropzone";
import imageCompression from "browser-image-compression";
import { validateData } from '../../utils/validation';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },

    navigationButtons: {
        minWidth: '100px'
    },

    policyCheck: {
        paddingLeft: 0,
        marginLeft: '-3px',
        marginBottom: '5px'
    }

}));

const RegisterCustomerView = ({ setSnackNotice, setAppErrors }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [prestine, setPrestine] = useState(true);
    const [errors, setErrors] = useState({});


    const [newCustomer, setNewCustomer] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        password: '',
        profilePicture: null,
        policy: false,
    });


    useEffect(() => {
        if(!prestine)
        {
            let confirmError = '';
            console.log(newCustomer);
            if (newCustomer.password !== confirmPassword) {
    
                confirmError = 'Password and confirmation do not match';
    
            }
            
            (async () => {
                const validationResponse = await validateData('newCustomer', newCustomer);
                const errorMap = {};

                if (validationResponse.errorMap) {
                    validationResponse.errorMap.forEach(error => {
                        errorMap[error.path] = error.message;
                    })
                }
                setErrors(confirmError ? { ...errorMap, confirmPassword: confirmError } : {...errorMap});
            })();
        }
       
        setPrestine(false);

    }, [newCustomer, confirmPassword]);

    const imageConversion = async (files) => {
        const compressedFileArray = [];
        for (let file of files) {

            const options = {
                maxSizeMB: 0.4,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            };
            try {
                const compressedFile = await imageCompression(file, options);
                compressedFileArray.push(compressedFile);

            } catch (error) {
                console.log(error);
            }
        }


        const base64dataarray = [];
        let counter = base64dataarray.length;

        compressedFileArray.forEach((file, idx) => {
            let reader = new FileReader();
            reader.onloadend = function () {
                base64dataarray[counter].base64data = reader.result;
                if (idx === files.length - 1) {
                    setNewCustomer({
                        ...newCustomer,
                        profilePicture: base64dataarray[0]
                    })

                }

                counter++;


            };
            reader.readAsDataURL(file);
            base64dataarray.push({ type: file.type });
        })
    };


    const handleChange = (e) => {
        if(e.target.name === 'policy') {
            setNewCustomer({
                ...newCustomer,
                policy: e.target.checked
            })
        }
        else
        {
            setNewCustomer({
                ...newCustomer,
                [e.target.name]: e.target.value
            })
        }
  
    }





    const registerNewCustomer = async () => {
        if (!Object.keys(errors).length) {
            setSnackNotice({
                message: "Registering your account",
                severity: "warning"
            });
            setIsSubmitting(true);
            const response = await axios({
                url: 'https://kripson-store-server-8qq76.ondigitalocean.app/customers/registerNewCustomer',
                method: 'POST',
                data: {...newCustomer}
            });


            if (response.data.status === 1) {
                navigate('/login');
                setSnackNotice({
                    message: "Registered successfully",
                    severity: "success"
                });
            } else {
                setAppErrors(response.data.errors);
            }
            setIsSubmitting(false);
        }
        else {
            console.log(errors);
        }
    }


    return (
        <Page
            className={classes.root}
            title="Register"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">

                        <Box mb={3}>
                            <Typography
                                color="textPrimary"
                                variant="h3"
                            >
                                Create an account and enjoy special member perks
                  </Typography>
                            <Typography
                                color="textSecondary"
                                gutterBottom
                                variant="body2"
                            >
                                Use your email to create a new account
                  </Typography>
                        </Box>
                        <TextField
                            error={errors.name}
                            fullWidth
                            helperText={errors.name}
                            label="Name"
                            margin="normal"
                            name="name"
                            onChange={handleChange}
                            value={newCustomer.name}
                            variant="outlined"
                        />
                        <TextField
                            error={Boolean(errors.email)}
                            fullWidth
                            helperText={errors.email}
                            label="Email"
                            margin="normal"
                            name="email"

                            onChange={handleChange}
                            value={newCustomer.email}
                            variant="outlined"
                        />
                        <TextField
                            error={Boolean(errors.phone)}
                            fullWidth
                            helperText={errors.phone}
                            label="Mobile number"
                            margin="normal"
                            name="phone"
                            onChange={handleChange}
                            type="number"
                            value={newCustomer.phone}
                            variant="outlined"
                        />
                        <TextField
                            error={Boolean(errors.address)}
                            fullWidth
                            helperText={errors.address}
                            label="Address"
                            margin="normal"
                            name="address"
                            onChange={handleChange}
                            type="text"
                            value={newCustomer.address}
                            variant="outlined"
                        />
                        <TextField
                            fullWidth
                            label="Password"
                            margin="normal"
                            name="password"
                            onChange={handleChange}
                            type="password"
                            value={newCustomer.password}
                            variant="outlined"
                            error={errors.password}
                            helperText={errors.password}
                        />
                        <TextField
                            fullWidth
                            label="Confirm password"
                            margin="normal"
                            name="confirm"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            type="password"
                            value={confirmPassword}
                            variant="outlined"
                            error={errors.confirmPassword}
                            helperText={errors.confirmPassword}
                        />
                        <Box
                            alignItems="center"
                            display="flex"
                        >
                            <Checkbox
                                checked={newCustomer.policy}
                                name="policy"
                                onChange={handleChange}
                                className={classes.policyCheck}
                            />
                            <Typography
                                color="textSecondary"
                                variant="body1"
                            >
                                I have read the
                    {' '}
                                <Link
                                    color="primary"
                                    component={RouterLink}
                                    to="#"
                                    underline="always"
                                    variant="h6"
                                >
                                    Terms and Conditions
                    </Link>
                            </Typography>
                        </Box>
                        {Boolean(errors.policy) && (
                            <FormHelperText error>
                                {'Please, accept our terms and conditions.'}
                            </FormHelperText>
                        )}

                        <Box my={2}>
                            <Button
                                onClick={registerNewCustomer}
                                color="primary"
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                variant="contained"
                            >
                                Sign up now
                  </Button>
                        </Box>
                        <Typography
                            color="textSecondary"
                            variant="body1"
                        >
                            Have an account?
                  {' '}
                            <Link
                                component={RouterLink}
                                to="/login"
                                variant="h6"
                            >
                                Sign in
              </Link>
                        </Typography>
                </Container>
            </Box>
        </Page>
    );
};


const mapStateToProps = state =>
({

});

const mapDispatchToProps = dispatch => (
    {
        setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
        setAppErrors: (errors) => dispatch(SetErrors(errors)),
    });

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCustomerView);
