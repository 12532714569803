import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SetErrors, SetSnackNotice } from "src/redux/app/app-actions";
import { Box, Card, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { makeStyles, withStyles } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import StarIcon from "@material-ui/icons/Star";

const BorderLinearProgress5 = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: "150px",
    marginRight: theme.spacing(1),
  },
  barColorPrimary: {
    backgroundColor: `${theme.palette.success.main} !important`,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const BorderLinearProgress4 = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: "150px",
    marginRight: theme.spacing(1),
  },
  barColorPrimary: {
    backgroundColor: `${theme.palette.warning.main} !important`,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const BorderLinearProgress3 = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: "150px",
    marginRight: theme.spacing(1),
  },
  barColorPrimary: {
    backgroundColor: `${theme.palette.warning.main} !important`,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const BorderLinearProgress2 = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: "150px",
    marginRight: theme.spacing(1),
  },
  barColorPrimary: {
    backgroundColor: `${theme.palette.warning.main} !important`,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const BorderLinearProgress1 = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: "150px",
    marginRight: theme.spacing(1),
  },
  barColorPrimary: {
    backgroundColor: `${theme.palette.error.main} !important`,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const ProductReviewBreakdown = ({ reviewGroupedData }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    productReviewOverall: {
      display: "grid",
      padding: theme.spacing(2),
      justifyContent: "center",
      textAlign: "center",
      gridRowGap: theme.spacing(2),
      minWidth: "250px",
      flexGrow: 1,
      flexShrink: 0,
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      marginLeft: theme.spacing(1),
      borderRadius: 5,
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      maxWidth: "150px",
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: "white",
    },
    strong: {
      fontWeight: "450",
    },
    numberStarGroup: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center,",
      marginRight: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  return (
    <Card className={classes.productReviewOverall}>
      <Box display="flex" alignItems="center">
        <Box className={classes.numberStarGroup}>
          <Typography variant="h6" color="textSecondary">
            5
          </Typography>
          <StarIcon className={classes.warning} />
        </Box>
        <BorderLinearProgress5 variant="determinate" value={100} />
        <Typography variant="h6" color="textSecondary">
          {(reviewGroupedData.find((group) => group._id === 5) || { count: 0 }).count}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box className={classes.numberStarGroup}>
          <Typography variant="h6" color="textSecondary">
            4
          </Typography>
          <StarIcon className={classes.warning} />
        </Box>
        <BorderLinearProgress4 variant="determinate" value={80} />
        <Typography variant="h6" color="textSecondary">
          {(reviewGroupedData.find((group) => group._id === 4) || { count: 0 }).count}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box className={classes.numberStarGroup}>
          <Typography variant="h6" color="textSecondary">
            3
          </Typography>
          <StarIcon className={classes.warning} />
        </Box>
        <BorderLinearProgress3 variant="determinate" value={60} />
        <Typography variant="h6" color="textSecondary">
          {(reviewGroupedData.find((group) => group._id === 3) || { count: 0 }).count}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box className={classes.numberStarGroup}>
          <Typography variant="h6" color="textSecondary">
            2
          </Typography>
          <StarIcon className={classes.warning} />
        </Box>
        <BorderLinearProgress2 variant="determinate" value={40} />
        <Typography variant="h6" color="textSecondary">
          {(reviewGroupedData.find((group) => group._id === 2) || { count: 0 }).count}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box className={classes.numberStarGroup}>
          <Typography variant="h6" color="textSecondary">
            1
          </Typography>
          <StarIcon className={classes.warning} />
        </Box>
        <BorderLinearProgress1 variant="determinate" value={20} />
        <Typography variant="h6" color="textSecondary">
          {(reviewGroupedData.find((group) => group._id === 1) || { count: 0 }).count}
        </Typography>
      </Box>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductReviewBreakdown);
