import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, Divider, Typography, Button, Box, makeStyles, Chip } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { getFormattedDate } from 'src/utils/date-time';
import SendIcon from '@material-ui/icons/Send';


const OrderHistoryCard = ({ order }) => {

    const useStyles = makeStyles((theme)=>({
        footerBox:{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(2)
        },
        moneyChip:{
            backgroundColor:'#b3ffb3'
        }
    }))

    const classes = useStyles();

    
    return <Card>
        <CardHeader title={'Order #:  ' + order.orderNumber} subheader={getFormattedDate(order.orderDate)}>

        </CardHeader>
        <Divider />
        <CardContent>
            <Typography variant="h6">Store: {order.storeName}</Typography>
        </CardContent>
        <Divider />
        <Box className = {classes.footerBox}>
            <Chip label ={order.total} className={classes.moneyChip}> </Chip>
            <RouterLink to={`/account/orders/${order.storeId}/${order.orderId}`}><Button variant="contained" color="primary" endIcon={<SendIcon/>}>
                Details
            </Button></RouterLink>
        </Box>

    </Card>
}

export default OrderHistoryCard;