
import React from 'react';
import ESewaPaymentButton from './components/esewa-payment-button/esewa-payment-button';
import StripeCheckoutButton from './components/stripe-pay-button/stripe-pay-button';

const countryPaymentMethodMap = {
    STRIPE: {
        logo: "stripe_logo.svg",
        name: "stripe",
        availableCountries: ['AUD'],
        paymentElement: (price, paymentMethod, createOrder, creatingOrder ) => <StripeCheckoutButton price={price} paymentMethod={paymentMethod} createOrder={createOrder} creatingOrder={creatingOrder} />,
        controls: [
            {
                name: 'publishableKey',
                type: 'text',
                helperText: 'Please, provide your stripe publishable key',
            },
            {
                name: 'secretKey',
                type: 'text',
                helperText: 'Please, provide your stripe secret key',
            }
        ]
    },
    ESEWA: {
        logo: "esewa_logo.png",
        name: "esewa",
        availableCountries: ['AUD','NPR'],
        paymentElement: (price, paymentMethod, createOrder, creatingOrder ) => <ESewaPaymentButton price={price} paymentMethod={paymentMethod} createOrder={createOrder} creatingOrder={creatingOrder} />,
        controls: [
            {
                name: 'merchantId',
                type: 'text',
                helperText: 'Please, provide your merchant Id provided by esewa to you',
            }
        ]
    }

};


export default countryPaymentMethodMap;