import React, {useEffect} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import axios from 'axios';
import {SetSnackNotice} from "../../redux/app/app-actions";
import {SetCustomerInfo, FetchCustomerOrderHistory} from '../../redux/current-customer/current-customer-actions';
import {connect} from "react-redux";
import { ClearCart } from 'src/redux/cart/cart.actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = ({ setCustomerInfo, currentCustomer, fetchCustomerOrderHistory, setSnackNotice, clearCart}) => {
  const classes = useStyles();
  const navigate = useNavigate();


  useEffect(() => {
    if(currentCustomer.sessionId){
      navigate('/account');
    }
  },[currentCustomer])
  const login = async (values)=>
  {

    const response = await axios({
      url:'https://kripson-store-server-8qq76.ondigitalocean.app/customers/login',
      method:'POST',
      data: {...values},
    });

    if(response.data.status === 1)
    {
      setCustomerInfo(response.data.result);
      setSnackNotice({
        message: 'Logged in successfully',
        severity: 'success'
      })
      clearCart();
      navigate('/account');
    }
    else
    {
      setSnackNotice({
        message: 'Login failed, please supply correct credentials',
        severity: 'error'
      })
    }
  }
  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values,{setSubmitting}) => {
              login(values);
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in to your account
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  <Link
                    component={RouterLink}
                    to="/forgotPassword"
                    variant="h6"
                  >
                    Forgot Password?
                  </Link>

                </Typography>
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/registerCustomer"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

const mapStateToProps = state =>
  ({
    currentCustomer: state.currentCustomer.customerInfo,
  });

const mapDispatchToProps = dispatch => (
  {
    setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo)),
    fetchCustomerOrderHistory: (customerId)=>dispatch(FetchCustomerOrderHistory(customerId)),
    setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
    clearCart: () => dispatch(ClearCart())
  });

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
