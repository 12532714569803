import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";




const FontLoader = ({ className, font, ...rest }) => {

  const [fontLink, setLink] = useState('');



  useEffect(() => {

    if (font && font.url) {
      setLink(<link rel="stylesheet" href={font.url}></link>);
    }
  }, [font]);

  return (<>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
    {fontLink}

  </>);
};

const mapStateToProps = state =>
({
  storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => (
  {
  });

export default connect(mapStateToProps, mapDispatchToProps)(FontLoader);
