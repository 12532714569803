import {takeLatest, call, put} from 'redux-saga/effects';
import axios from 'axios';
import {FetchStoresError, FetchStoresSuccess} from "./app-actions";




export function* fetchStoresStart()
{
  yield takeLatest('FETCH_STORES_START', fetchStoresStartAsync);
}




export function* fetchStoresStartAsync(action)
{

  try
  {
    const stores = yield fetchStores(action.payload);

    yield put(FetchStoresSuccess(stores));
  }
  catch(e)
  {
    yield put(FetchStoresError(e.message));
  }
}




const fetchStores = async (filter)=>
{
  console.log(filter);
  const response = await axios({
    url:'https://kripson-store-server-8qq76.ondigitalocean.app/stores',
    method:'POST',
    data:{filter}
  });
  if(response.data.status === 1)
  {
    return(response.data.result);
  }
  else
  {
    return ([]);
  }

};





