import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SetErrors, SetSnackNotice } from "src/redux/app/app-actions";
import ProductReviewOverall from "../product-review-overall/product-review-overall";
import { makeStyles } from "@material-ui/styles";
import ProductReviewBreakdown from "../product-review-breakdown/product-review-breakdown";
import { Grid, Hidden, useMediaQuery, Box, Button, Accordion, AccordionSummary, Typography, Avatar, Card, IconButton, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProductReviewForm from "../product-review-form/product-review-form";
import { Rating } from "@material-ui/lab";
import { getFormattedDate } from "src/utils/date-time";
import EditIcon from "@material-ui/icons/Edit";
import { Delete, ReplyOutlined } from "@material-ui/icons";
import ProductReviewEditForm from "../product-review-edit-form/product-review-edit-form";
import { SetCustomerInfo } from "src/redux/current-customer/current-customer-actions";
import { validateData } from "src/utils/validation";
import ProductReviewReplyForm from "../product-review-reply-form/product-review-reply-form";
import { useLocation, useParams } from "react-router-dom";
import MessengerCustomerChat from 'react-messenger-customer-chat';

const KripsonStoreChat = ({ storeInfo, integrations }) => {

    const location = useLocation();
    const [pageId, setPageId] = useState('');

    useEffect(() => {
        if (location.pathname.startsWith('/stores')) {
            if (integrations && integrations.find(integration => integration.name === 'Messenger')) {
                setPageId(integrations.find(integration => integration.name === 'Messenger').pageId);
            }
            else {
                setPageId('');
            }
        }
        else {
            setPageId('110230337977546');
        }
    }, [location]);
    return <> {pageId ? <MessengerCustomerChat
        pageId={pageId}
        appId="250958916506829"
    /> : ''}
    </>
}


const mapStateToProps = (state) => ({
    storeInfo: state.currentStore.storeInfo,
    currentCustomer: state.currentCustomer.customerInfo,
    integrations: state.currentStore.integrations
});

const mapDispatchToProps = (dispatch) => ({
    setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo)),
    setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
    setErrors: (errors) => dispatch(SetErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KripsonStoreChat);


