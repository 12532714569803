import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { SetErrors, SetSnackNotice } from "src/redux/app/app-actions";
import { Button, Typography } from "@material-ui/core";
import { Dialog } from "@mui/material";
import "./stripe-checkout-form.scss";

function StripeCheckoutForm({ open, handleClose, priceForStripe, storeInfo, createOrder }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    console.log(window.location);

    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const res = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:3000",
      },
      redirect: 'if_required'
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    console.log(res);
    if(res && res.paymentIntent && res.paymentIntent.status === 'succeeded' && res.paymentIntent.amount === Number(priceForStripe)){
        createOrder({paymentMethod: 'STRIPE', stripePaymentIntentId: res.paymentIntent.id});
        handleClose();
    }

    if (res && res.error && (res.error.type === "card_error" || res.error.type === "validation_error")) {
      setMessage(res.error.message);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <Dialog open={open} onClose={handleClose} className="dialog">
      <form id="payment-form" onSubmit={handleSubmit}>
        <Typography variant="h6">Total: {storeInfo.storeCurrency + " " + priceForStripe / 100}</Typography>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              <Button variant="outlined" style={{ backgroundColor: "#625AFA", color: "white" }}>
                Pay now
              </Button>
            )}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  currencyPreference: state.currentStore.currencyPreference,
  currentCustomer: state.currentCustomer.customerInfo,
  storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StripeCheckoutForm);
