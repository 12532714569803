export const getFormattedDate = (epoch)=>{
    const date = new Date(epoch);
    let month =  (Number(date.getMonth()) + 1);
    let day =  Number(date.getDate());

    if(month < 10){
        month = '0' + String(month)
    }
    if(day < 10){
        day = '0' + String(day)
    }
    return  day + '/' + month + '/' + date.getFullYear();
}