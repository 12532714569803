import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Hidden,
  Grid,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";

import { SetPreLoader } from "../../redux/app/app-actions";
import { connect } from "react-redux";
import Page from "../../components/Page";
import { FetchStoresStart } from "../../redux/app/app-actions";
import StoreCard from "../../components/store-card/store-card";
import { Link } from "react-router-dom";
import { SetStoreInfo } from "../../redux/current-store/current-store-actions";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import HomeSearchSection from "src/components/home-search-section/home-search-section";
import { useTheme } from "@material-ui/styles";
import Footer from "../../components/footer/footer";
import KripsonStoreChat from "src/components/kripsonstore-home-chat/kripsonstore-home-chat";
import { useNavigate } from "react-router-dom";

const Home = ({
  setPreLoader,
  stores,
  FetchStoresStart,
  setStoreInfo,
  isFetchingStores,
}) => {
  const theme = useTheme();
  const [showStores, setShowStores] = useState(false);
  const [keyword, setKeyword] = useState("");
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (stores.length) {
  //     navigate(`/stores/${stores[0]._id}`);
  //   }
  // }, [stores]);

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
    },
    skeletons: {
      flexGrow: isSmallScreen ? 1 : "unset",
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    title: {
      display: "flex",
      alignItems: "end",
    },
    storeCardContainer: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      flexGrow: isSmallScreen ? 1 : "unset",
    },
    storeIcon: {
      height: "32px",
      width: "auto",
      marginLeft: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    FetchStoresStart({});
    setStoreInfo({
      ownerFirstName: "",
      ownerLastName: "",
      storeEmail: "",
      storeName: "",
      storeAddress: "",
      colors: { primary: "#000000" },
    });
  }, []);

  useEffect(() => {
    if (isFetchingStores) {
      setShowStores(false);
    } else {
      setTimeout(() => setShowStores(true), 2000);
    }
  }, [isFetchingStores]);
  return <Page
      title={"Kripson Store"}
      className={classes.root}
      id="store-exploration-section"
    >
      <HomeSearchSection updateKeyword={setKeyword} />

      <Box mt={2} p={1}>
        <Box display="flex" alignItems="center">
          <Typography
            align="center"
            color="textPrimary"
            variant="h3"
            className={classes.title}
          >
            Explore Stores
          </Typography>
          {/* <img src="/static/images/store.png" alt="store icon" className={classes.storeIcon}></img> */}
        </Box>
        {keyword && keyword.length ? (
          <Typography
            align="center"
            color="textSecondary"
            variant="h6"
            className={classes.title}
          >
            Showing results for '{keyword}'
          </Typography>
        ) : (
          ""
        )}
        <Box display="flex" flexWrap="wrap">
          {stores && showStores ? (
            stores.map((store) => (
              <Box
                className={classes.storeCardContainer}
                onClick={() => setStoreInfo({ ...store })}
              >
                <Link to={"/stores/" + store._id}>
                  <StoreCard
                    store={store}
                    className={store.storeName}
                  ></StoreCard>
                </Link>
              </Box>
            ))
          ) : (
            <>
              <Skeleton
                variant="rect"
                animation="wave"
                width={isSmallScreen ? 200 : 250}
                height={isSmallScreen ? 250 : 300}
                className={classes.skeletons}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                width={isSmallScreen ? 200 : 250}
                height={isSmallScreen ? 250 : 300}
                className={classes.skeletons}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                width={isSmallScreen ? 200 : 250}
                height={isSmallScreen ? 250 : 300}
                className={classes.skeletons}
              />
              <Skeleton
                variant="rect"
                animation="wave"
                width={isSmallScreen ? 200 : 250}
                height={isSmallScreen ? 250 : 300}
                className={classes.skeletons}
              />
            </>
          )}
        </Box>
      </Box>
    </Page>
};

const mapStateToProps = (state) => ({
  stores: state.app.stores,
  isFetchingStores: state.app.isFetchingStores,
});

const mapDispatchToProps = (dispatch) => ({
  FetchStoresStart: (filter) => dispatch(FetchStoresStart(filter)),
  // setPreLoader: (notice) => dispatch(SetPreLoader(notice)),
  setStoreInfo: (info) => dispatch(SetStoreInfo(info)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
