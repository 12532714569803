import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SetErrors, SetSnackNotice } from "src/redux/app/app-actions";
import { Card, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";

const ProductReviewOverall = ({ totalNumberofReviews, totalStars, style }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    productReviewOverall: {
      display: "grid",
      padding: theme.spacing(2),
      justifyItems: "center",
      textAlign: "center",
      gridRowGap: theme.spacing(2),
      minWidth: "250px",
      flexGrow: 1,
      flexShrink: 0,
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      maxWidth: "150px",
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: "white",
    },
    strong: {
      fontWeight: "450",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    console.log(totalNumberofReviews, totalStars);
  }, [totalNumberofReviews, totalStars]);

  return (
    <Card className={classes.productReviewOverall} style={style}>
      <Typography variant="h4" color="textPrimary">
        Overall Rating
      </Typography>
      <Typography variant="h1" color="textPrimary">
        {totalNumberofReviews ? ((totalStars / (totalNumberofReviews * 5)) * 5).toFixed(2) +  '/ 5' : "No reviews"}
      </Typography>
      <Rating value={((totalStars / (totalNumberofReviews * 5)) * 5).toFixed(2)} readOnly precision={0.25} />
      <Typography variant="h6" color="textSecondary">
        {totalNumberofReviews} review/s
      </Typography>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductReviewOverall);
