export const SetCustomerInfo = (customerInfo) => ({
  type: 'SET_CUSTOMER_INFO',
  payload: customerInfo
})

export const FetchCustomerOrderHistory = (customerId)=>({
  type: 'FETCH_CUSTOMER_ORDER_HISTORY',
  payload: customerId
})

export const FetchCustomerOrderHistorySuccess = (categories)=>({
  type: 'FETCH_CUSTOMER_ORDER_HISTORY_SUCCESS',
  payload: categories
})

export const FetchCustomerOrderHistoryError = (e)=>({
  type: 'FETCH_CUSTOMER_ORDER_HISTORY_ERROR',
  payload: e
})



