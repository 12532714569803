import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';




const AnimatedButton = ({ loading, startIcon, buttonLabel, loadingLabel, confirmedLabel, onClick, styles }) => {

    const useStyles = makeStyles((theme)=>({
        loader:{
            padding:`${theme.spacing(4)}px ${theme.spacing(2)}px`,
            marginBottom: theme.spacing(2)
        }
    }))

    const classes = useStyles();
    const [previousLoading, setPreviousLoading] = useState(false);
    const [content, setContent] = useState('');
    const theme = useTheme();

    useEffect(() => {
        console.log(loading);
        if (loading) {
            setContent(<><CircularProgress size={32} style={{marginRight: theme.spacing(1)}} /> {`${loadingLabel || 'Loading'}`}</>);
            console.log(previousLoading);
            setPreviousLoading(true);
        }
        else {
            console.log(previousLoading);
            if (previousLoading) {
                setContent(<>        <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_byhf43ei.json" background="transparent" speed="1" style={{ width: '32px', height: '32px', marginRight: theme.spacing(1)}} loop autoplay></lottie-player>{confirmedLabel || 'confirmed'}</>);
                console.log(previousLoading);
                setTimeout(() => {
                    setContent(startIcon ? <> <startIcon /> {buttonLabel}</> : buttonLabel);
                }, 2250);
            }
            else {
                setContent(startIcon ? <> <startIcon /> {buttonLabel}</> : buttonLabel);
            }

            setPreviousLoading(false);

        }
    }, [loading])


    return <Button onClick={onClick}  disabled={loading} variant="contained" color="primary" style={styles}>


        {content}
    </Button>
}

export default AnimatedButton;