import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField, makeStyles } from "@material-ui/core";
import axios from "axios";
import { connect } from "react-redux";
import { SetCustomerInfo } from "src/redux/current-customer/current-customer-actions";
import { SetStoreInfo } from "src/redux/current-store/current-store-actions";
import { SetErrors, SetSnackNotice } from "src/redux/app/app-actions";
import { validateData } from "../../../utils/validation";

const useStyles = makeStyles(() => ({
  root: {},
}));

const ProfileDetails = ({ className, newProfilePicture, currentCustomer, setCustomerInfo, setSnackNotice, setErrors, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: null,
    email: null,
    phone: null,
    address: null,
    password: null,
    confirmPassword: null,
  });

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const validateValues = async () => {
    const validationResponse = await validateData("updateCustomer", values);
    const errorMap = {};

    if (validationResponse.errorMap) {
      validationResponse.errorMap.forEach((error) => {
        errorMap[error.path] = error.message;
      });
    }
    setFormErrors({
      ...errorMap,
    });
  };

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setFormErrors({ ...formErrors, password: "New password and confirmation password do not match", confirmPassword: "New password and confirmation password do not match" });
    } else {
      setFormErrors({ ...formErrors, password: "", confirmPassword: "" });
    }
  }, [newPassword, confirmPassword]);

  useEffect(() => {
    validateValues();
  }, [values]);

  useEffect(() => {
    setValues({
      ...currentCustomer,
    });
  }, [currentCustomer]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const updateCustomerInfo = () => {
    const { name, email, phone, address } = values;

    if (!(formErrors.name && formErrors.email && formErrors.address && formErrors.phone && formErrors.password && formErrors.confirmPassword)) {
      let profilePictureToSend = { ...currentCustomer.profilePicture };
      let profilePictureUpdated = false;
      if (newProfilePicture) {
        profilePictureToSend = {
          ...newProfilePicture,
        };
        profilePictureUpdated = true;
      }

      axios({
        url: "https://kripson-store-server-8qq76.ondigitalocean.app/customers/updateCustomerInfo",
        method: "POST",
        data:
          newPassword && newPassword.length
            ? {
                ...currentCustomer,
                password: newPassword,
                ...values,
                sessionId: currentCustomer.sessionId,
                profilePicture: profilePictureToSend,
                profilePictureUpdated: profilePictureUpdated,
                deletedKey: profilePictureToSend ? (currentCustomer.profilePicture && currentCustomer.profilePicture.key) || null : null,
              }
            : {
                ...currentCustomer,
                ...values,
                sessionId: currentCustomer.sessionId,
                profilePicture: profilePictureToSend,
                profilePictureUpdated: profilePictureUpdated,
                deletedKey: profilePictureToSend ? (currentCustomer.profilePicture && currentCustomer.profilePicture.key) || null : null,
              },
      }).then((response) => {
        if (response.data.status === 1) {
          setCustomerInfo(response.data.result);
          setSnackNotice({
            severity: "success",
            message: 'Acount info updated successfully',
          });
        } else if (response.data.status === 3) {
          setCustomerInfo({});
          setSnackNotice({
            severity: "warning",
            message: response.data.message,
          });
        } else {
          setSnackNotice({
            message: response.data.message,
            severity: "error",
          });
          setErrors(response.data.errors);
        }
      });
    }
  };

  return (
    <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText={formErrors.name || "Please specify your name"}
                error={formErrors.name}
                label="Name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                helperText={formErrors.email || "Please specify your email"}
                error={formErrors.email}
                name="email"
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                helperText={formErrors.phone || "Please specify your phone number"}
                error={formErrors.phone}
                name="phone"
                onChange={handleChange}
                type="text"
                value={values.phone}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address"
                helperText={formErrors.address || "Please specify your address"}
                error={formErrors.address}
                name="address"
                onChange={handleChange}
                required
                value={values.address}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Card>
          <CardHeader subheader="Update password" title="Password" />
          <Divider />
          <CardContent>
            <TextField
              fullWidth
              label="New password"
              margin="normal"
              name="password"
              onChange={(e) => setNewPassword(e.target.value)}
              type="password"
              value={newPassword}
              variant="outlined"
              error={formErrors.password}
              helperText={formErrors.password ? formErrors.password : ""}
            />
            <TextField
              fullWidth
              label="Confirm password"
              margin="normal"
              name="confirm"
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              value={confirmPassword}
              variant="outlined"
              error={formErrors.confirmPassword}
              helperText={formErrors.confirmPassword ? formErrors.confirmPassword : ""}
            />
          </CardContent>
          <Divider />
        </Card>
        <Box display="flex" justifyContent="flex-end" p={2} mb={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={updateCustomerInfo}
            disabled={formErrors.name || formErrors.email || formErrors.address || formErrors.phone || formErrors.password || formErrors.confirmPassword}
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo)),
  setStoreInfo: (storeInfo) => dispatch(SetStoreInfo(storeInfo)),
  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
