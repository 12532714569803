const INITIAL_STATE = {
  customerInfo:{},
  orderHistory:[]
}

// eslint-disable-next-line import/prefer-default-export
export const currentCustomerReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case 'SET_CUSTOMER_INFO':
      return {
        ...state,
        customerInfo: {
          ...action.payload
        }
      };
    case 'FETCH_CUSTOMER_ORDER_HISTORY_SUCCESS':
      return {
        ...state,
        orderHistory: [...action.payload]
      };
    case 'FETCH_CUSTOMER_ORDER_HISTORY_ERROR':
      return {
        ...state,
        orderHistory: []
      };
    default:
      return state;
  }
}
