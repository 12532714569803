import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import CardMedia from "@material-ui/core/CardMedia";
import * as FA from 'react-fontawesome';
import {connect} from 'react-redux';
import { FetchCurrencyExchangeRate } from 'src/redux/current-store/current-store-actions';
import { convertToSelectedCurrency } from 'src/utils/convertToSelectedCurrency';
import getSymbolFromCurrency from 'currency-symbol-map'
import { Skeleton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1),
    transform: 'scale(1.25)'
  }
}));

const ProductCard = ({ className, storeInfo, currentStore, product, currencyPreference,fetchingCurrencyRate, currencyExchangeRateExpiry, currencyExchangeRate, fetchCurrencyExchangeRate, ...rest }) => {
  const classes = useStyles();

  

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardMedia
        component="img"
        alt={product.title}
        height="240"
        image={product.images[0].url}
        title={product.title}
      />
      <CardContent>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {product.title}
        </Typography>
        {/*<Typography*/}
        {/*  align="center"*/}
        {/*  color="textSecondary"*/}
        {/*  variant="body2"*/}
        {/*>*/}
        {/*  {product.description}*/}
        {/*</Typography>*/}
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2}>
        <Grid
          container
          justify="space-between"
          spacing={2}
        >
          <Grid
            className={classes.statsItem}
            item
          >
            {/*<AccessTimeIcon*/}
            {/*  className={classes.statsIcon}*/}
            {/*  color="action"*/}
            {/*/>*/}

            <Typography
              color="textSecondary"
              style={{display: 'flex', alignItems: 'center'}}
              variant="body2"
            >
              <FA className={classes.statsIcon}
                name="money"></FA>
                {fetchingCurrencyRate ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100}/>:
               `  ${getSymbolFromCurrency(currentStore.currencyPreference)} `+ convertToSelectedCurrency(product.price, currentStore)}
            </Typography>
          </Grid>
          <Grid
            className={classes.statsItem}
            item
          >
            <FA className={classes.statsIcon}
              name="bullhorn"></FA>
            <Typography
              color="textSecondary"
              display="inline"
              variant="body2"
            >
              {product.status}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};


const mapStateToProps = (state) => ({
  storeInfo: state.currentStore.storeInfo,
  currentStore: state.currentStore,
  fetchingCurrencyRate: state.currentStore.fetchingCurrencyRate
});

const mapDispatchToProps = (dispatch) => ({
  fetchCurrencyExchangeRate: (convertFrom, convertTo, storeId) => dispatch(FetchCurrencyExchangeRate(convertFrom, convertTo, storeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
