export const SetStoreInfo = (storeInfo) => ({
  type: 'SET_STORE_INFO',
  payload: storeInfo
});


export const ResetStoreInfo = () => ({
  type: 'RESET_STORE_INFO',
});

export const SetCurrencyPreferrence = (countryCode) => ({
  type: 'SET_CURRENCY_PREFERENCE',
  payload: countryCode
})

export const SetCurrencyRate = (payload) => ({
  type: 'SET_CURRENCY_RATE',
  payload: payload
})

export const FetchCurrencyExchangeRate = (convertFrom, convertTo, storeId) => ({
  type: 'FETCH_CURRENCY_EXCHANGE_RATE',
  payload: {
    currencyToConvertFrom: convertFrom,
    currencyToConvertTo: convertTo,
    storeId: storeId
  }
})

export const FetchStoreCategoriesStart = (storeId)=>({
  type: 'FETCH_STORE_CATEGORIES_START',
  payload: storeId
})

export const FetchStoreCategoriesSuccess = (categories)=>({
  type: 'FETCH_STORE_CATEGORIES_SUCCESS',
  payload: categories
})

export const FetchStoreCategoriesError = (e)=>({
  type: 'FETCH_STORE_CATEGORIES_ERROR',
  payload: e
})

export const FetchStoreIntegrationsStart = (storeId)=>({
  type: 'FETCH_STORE_INTEGRATIONS_START',
  payload: storeId
})

export const FetchStoreIntegrationsSuccess = (integrations)=>({
  type: 'FETCH_STORE_INTEGRATIONS_SUCCESS',
  payload: integrations
})

export const FetchStoreIntegrationsError = (e)=>({
  type: 'FETCH_STORE_INTEGRATIONS_ERROR',
  payload: e
})


export const FetchStoreProductsStart = (requiredInfo)=>({
  type: 'FETCH_STORE_PRODUCTS_START',
  payload: requiredInfo
})

export const FetchStoreProductsSuccess = (products)=>({
  type: 'FETCH_STORE_PRODUCTS_START',
  payload: products
})

export const FetchStoreProductsError = (e)=>({
  type: 'FETCH_STORE_PRODUCTS_START',
  payload: e
})


