import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import { FetchStoreNotificationsStart, FetchStoreProductsStart } from 'src/redux/current-store/current-store-actions';
import { SetCustomerInfo } from 'src/redux/current-customer/current-customer-actions';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Avatar } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import axios from 'axios';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },

})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    // '&:focus': {
    //   backgroundColor: theme.palette.primary.main,
    //   '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //     color: theme.palette.common.white,
    //   },
    // },
  },
}))(MenuItem);

function AccountMenu({ storeId, FetchStoreNotificationsStart, currentCustomer, setCustomerInfo, setSnackNotice, setErrors }) {


  const useStyles = makeStyles((theme) => ({
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    },
    success: {
      // backgroundColor: theme.palette.success.main,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.success.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
    accountMenuLinks: {
      color: theme.palette.text.primary
    },
    profileAvatar: {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }));

  const logout = async () => {
    const response = await axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/customers/logout',
      method: 'POST',
      data: {
        sessionId: currentCustomer.sessionId,
      }
    });

    if (response.data.status === 1 || response.data.status === 3) {

      setCustomerInfo({});
      setSnackNotice({
        severity: 'success',
        message: 'Logged out successfully',
      });
    }
    else {
      setErrors(response.data.errors)
    }
  }

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const getNotificationTypeIcon = (type) => {
    switch (type) {
      case 'warning':
        return <CachedIcon fontSize="small" />;
      case 'error':
        return <ClearIcon fontSize="small" />;
      case 'success':
        return <CheckIcon fontSize="small" />;
      default:
        return;
    }
  }


  const getNotificationTypeClass = (type) => {
    switch (type) {
      case 'warning':
        return classes.warning;
      case 'error':
        return classes.error;
      case 'success':
        return classes.success;
      default:
        return;
    }
  }

  return (
    <>
      <Avatar
        aria-controls="customized-menu"
        aria-haspopup="true"
        src={currentCustomer && currentCustomer.profilePicture && currentCustomer.profilePicture.url ? currentCustomer.profilePicture.url : ''}
        onClick={handleClick}
        className={classes.profileAvatar} />

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        <Link to={'/account'} onClick={handleClose} className={classes.accountMenuLinks}>
          <StyledMenuItem>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={'Your Account'} />
          </StyledMenuItem></Link>
        <Link to={'/account/orderHistory'} onClick={handleClose} className={classes.accountMenuLinks}>
          <StyledMenuItem>
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary={'Your Order History'} />
          </StyledMenuItem></Link>
        <Link to={'/account/refundRequests'} onClick={handleClose} className={classes.accountMenuLinks}>
          <StyledMenuItem>
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary={'Refund Requests'} />
          </StyledMenuItem></Link>
        <StyledMenuItem onClick={() => logout()}>   
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={'Logout'} />
        </StyledMenuItem>

      </StyledMenu>
    </>
  );
}


const mapStateToProps = state =>
({
  storeId: state.currentStore.storeInfo._id,
  currentCustomer: state.currentCustomer.customerInfo
});

const mapDispatchToProps = dispatch => (
  {
    setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
    setErrors: (errors) => dispatch(SetErrors(errors)),
    setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo))
  });

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
