import Decimal from 'decimal.js-light';

export const addItemToCart = (cartItems, itemToBeAdded, quantityToBeAdded) => {
	const existingitem = cartItems.find((item) => item.item._id === itemToBeAdded._id && item.item.selectedSize === itemToBeAdded.selectedSize);
	console.log(cartItems);
	if (existingitem) {

		return cartItems.map(item => {

			if (item.item._id === itemToBeAdded._id && item.item.selectedSize === itemToBeAdded.selectedSize) {
				return { ...item, quantity: item.quantity + quantityToBeAdded }
			}
			else {
				return item
			}
		})
	}

	console.log(itemToBeAdded);

	return [...cartItems, { item: { ...itemToBeAdded }, quantity: quantityToBeAdded }]
}



export const removeItemFromCart = (cartItems, itemToBeRemoved) => {


	return cartItems.filter(cartItem => !(cartItem.item._id === itemToBeRemoved._id && cartItem.item.selectedSize === itemToBeRemoved.selectedSize));


}


export const decreaseItemQuantity = (cartItems, itemToBeDecreased) => {

	return cartItems.map((item) => {
		if (item.item._id === itemToBeDecreased._id && item.item.selectedSize === itemToBeDecreased.selectedSize) {

			return { ...item, quantity: item.quantity - 1}
		}
		else {
			return item
		}
	}).filter(item => item.quantity !== 0);


}