import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Divider,
  useMediaQuery,
  CardHeader,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import CardMedia from "@material-ui/core/CardMedia";
import * as FA from 'react-fontawesome';



const StoreCard = ({ className, store, ...rest }) => {

  const isSmallScreen = useMediaQuery("(max-width:550px)");
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',

    },
    statsItem: {
      alignItems: 'center',
      display: 'flex'
    },
    statsIcon: {
      marginRight: theme.spacing(1),
      transform: 'scale(1.25)'
    },
    cardHeader: {
      minHeight: isSmallScreen ? '250px' : '300px',
      minWidth: isSmallScreen ? '200px' : '250px',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      color: 'white',
      position: 'relative',
    },
    backDrop: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '20%',
      bottom: '0',
      backgroundColor: 'rgba(0,0,0,0.5)',
      padding: theme.spacing(1),
    }
  }));

  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >

      <div className={classes.cardHeader} style={{ backgroundImage: `url(${store.storeBanner.url})` }}>


        <Box className={classes.backDrop}>
          <Typography
            align="left"
            gutterBottom
            variant="h3"
          >
            {store.storeName}
          </Typography>
        </Box>



      </div>
    </Card>
  );
};

StoreCard.propTypes = {
  className: PropTypes.string,
  store: PropTypes.object.isRequired
};

export default StoreCard;
