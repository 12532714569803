import React from "react";
import { Navigate } from "react-router-dom";
import MainLayout from "src/layouts/MainLayout";
import LoginView from "src/views/auth/LoginView";
import NotFoundView from "src/views/errors/NotFoundView";
import ProductListView from "src/views/product/ProductListView";
import RegisterView from "src/views/auth/RegisterView";
import RegisterCustomerView from "src/views/kripsonstore/registerCustomer";
import ProductDetailsView from "./views/product/product-details-view/product-details-view";
import Home from "./views/kripsonstore/home";
import StoreLayout from "./layouts/StoreLayout";
import CheckOut from "./views/checkout/checkout.page";
import Account from "./views/account/AccountView";
import VerifyView from "./views/account/AccountView/account-verification";
import OrderHistory from "./views/account/AccountView/order-history";
import OrderDetailView from "./views/order/order-detail-view/order-detail-view";
import RefundRequestListView from "./views/account/AccountView/refund-request/refund-request-list-view";
import RefundRequestDetailView from "./views/account/AccountView/refund-request/refund-request-detail-view";
import DiscountList from "./views/discount-list/discount-list";
import ForgotPassword from './views/auth/forgot-password';
import RefundPolicies from "./views/custom/refund-policies";
import Socials from "./views/socials/socials";
import OrderError from "./views/order/order-error/order-error";

const routes = [
  {
    path: "stores/:storeId",
    element: <StoreLayout />,
    children: [
      {
        path: "products",
        children: [
          {
            path: "",
            element: <ProductListView />,
          },
          {
            path: ":category",
            element: <ProductListView />,
          },
          {
            path: ":category/:productId",
            element: <ProductDetailsView />,
          },
        ],
      },
      {
        path: "discountOffers",
        children: [
          {
            path: "",
            element: <DiscountList />,
          },
          {
            path: ":category/:productId",
            element: <ProductDetailsView />,
          },
        ],
      },
      {
        path: "checkout",
        element: <CheckOut />,
      },
      {
        path: "refundPolicies",
        element: <RefundPolicies />,
      },
      {
        path: "socials",
        element: <Socials />,
      },
      { path: "orderError", element: <OrderError/> }
    ],
  },
  {
    path: "",
    element: <MainLayout />,
    children: [
      { path: "home", element: <Home /> },
      { path: "login", element: <LoginView /> },
      { path: 'forgotPassword', element: <ForgotPassword /> },
      { path: "register", element: <RegisterView /> },
      { path: "registerCustomer", element: <RegisterCustomerView /> },
      { path: "404", element: <NotFoundView /> },
      {
        path: "account",
        children: [
          { path: "", element: <Account /> },
          { path: "verify", element: <VerifyView /> },
          { path: "orderHistory", element: <OrderHistory /> },
          {
            path: "refundRequests",
            children: [
              {
                path: '',
                element: <RefundRequestListView />
              },
              {
                path: ':storeId/:refundRequestId',
                element: <RefundRequestDetailView />
              }
            ]
          },
          {
            path: "orders",
            children: [
              {
                path: ":storeId/:orderId",
                element: <OrderDetailView />,
              },
            ],
          },
        ],
      },
      { path: "", element: <Navigate to="home" /> },
      { path: "*", element: <Navigate to="404" /> },
    ],
  },
];

export default routes;
