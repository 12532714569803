import axios from "axios";
import React, { useEffect, useState } from "react";
// import "./product-review-section.scss";
import { connect } from "react-redux";
import { SetErrors, SetSnackNotice } from "src/redux/app/app-actions";
import ProductReviewOverall from "../product-review-overall/product-review-overall";
import { makeStyles } from "@material-ui/styles";
import ProductReviewBreakdown from "../product-review-breakdown/product-review-breakdown";
import { Grid, Hidden, useMediaQuery, Box, Button, Accordion, AccordionSummary, Typography, TextField } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Rating } from "@material-ui/lab";
import { validateData } from "src/utils/validation";
import { SetCustomerInfo } from "src/redux/current-customer/current-customer-actions";
import { useParams } from "react-router-dom";

const ProductReviewReplyForm = ({ storeInfo, review, refreshReviews, currentCustomer, setCustomerInfo, setSnackNotice, setErrors, setMode }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    ProductReviewReplyForm: {
      display: "grid",
      padding: theme.spacing(2),
      gridRowGap: theme.spacing(2),
      border: '1px solid rgba(0,0,0,0.15)',
      borderRadius: '5px'
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      maxWidth: "150px",
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: "white",
    },
    strong: {
      fontWeight: "450",
    },
  }));

  const classes = useStyles();
  const isSmallScreen = useMediaQuery("(max-width:557px)");
  const {category} = useParams();


  const [reply, setReply] = useState({
      origin: 'customer'
  });

  const handleChange = (e) => {
    setReply({
      ...reply,
      [e.target.name]: e.target.value,
    });
  };


  const editReview = async () => {
    try {
      const validationResponse = await validateData("editProductReview", review);

      if (!validationResponse.validity) {
        setErrors(validationResponse.errors);

        return;
      }

      const createdDate = Date.now();
      const result = await axios({
        url: "https://kripson-store-server-8qq76.ondigitalocean.app/reviews/editProductReview",
        method: "POST",
        data: { storeId: storeInfo._id, categoryId: category, review: {...review, replies: (review.replies || []).concat({...reply, deleted: false, createdDate: createdDate, updatedDate: createdDate})}, sessionId: currentCustomer.sessionId},
      });

      console.log(result);

      if (result.data.status === 0) {
        setErrors(result.data.errors);
        refreshReviews();
        return;
      }

      if(result.data.status === 2 || result.data.status === 3){
        setCustomerInfo({});   setSnackNotice({
            severity: "warning",
            message: "Your session has expired, please, login again.",
          });
          
      }

      setSnackNotice({
        severity: "success",
        message: result.data.message,
      });
      refreshReviews();
      setMode('detail');
    } catch (e) {
      setSnackNotice({
        severity: "error",
        message: "Something went wrong, please try again.",
      });
      refreshReviews();
      return;
    }
  };

  return (
    <div className={classes.ProductReviewReplyForm}>
      <Typography variant="h3" component="legend">Reply</Typography>
  
      <TextField name="comment" label="Comment" onChange={handleChange} variant="outlined" value={reply.comment} required />
      <div className="button-group">
        <Button variant="contained" color="primary" onClick={editReview} style={{marginRight: '4px'}}>
          Submit
        </Button>
        <Button variant="contained" className={classes.error} onClick={()=> setMode('detail')}>
          Cancel
        </Button>
       
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  storeInfo: state.currentStore.storeInfo,
  currentCustomer: state.currentCustomer.customerInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductReviewReplyForm);
