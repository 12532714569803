import getSymbolFromCurrency from "currency-symbol-map";
import React, { useEffect } from "react";
import StripeCheckout from "react-stripe-checkout";
import { SetErrors, SetSnackNotice } from "src/redux/app/app-actions";
import { connect } from "react-redux";
import AnimatedButton from "../animated-button/animated-button";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useState } from "react";
import StripeCheckoutForm from "./stripe-checkout-form";

const StripeCheckoutButton = ({ price, currencyPreference, paymentMethod, storeInfo, createOrder, creatingOrder }) => {
  const priceForStripe = price * 100;

  const stripePromise = loadStripe("pk_test_4jK6z7xjo23yDKaEXYbdagd300s3gLcThO");

  const [clientSecret, setClientSecret] = useState("");

  const [paymentFormOpen, setPaymentFormOpen] = useState(false);

    const togglePaymentForm = ()=>{
        setPaymentFormOpen(!paymentFormOpen);
    }

  useEffect(() => {
    (async () => {
      const paymentIntentResponse = await axios({
        url: "https://kripson-store-server-8qq76.ondigitalocean.app/orders/createStripePaymentIntent",
        method: "POST",
        data: {
          total: priceForStripe,
          storeId: storeInfo._id,
        },
      });

      if (paymentIntentResponse.status === 200) {
        setClientSecret(paymentIntentResponse.data.clientSecret);
      } else {
        alert("Something went wrong, let us try it again");
        window.reload();
      }
    })();
  }, []);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    // <StripeCheckout
    //     label='Pay Now'
    //     name={storeInfo.storeName}
    //     email="admin@kripsonstore.com"
    //     description={`Your total is ${getSymbolFromCurrency(currencyPreference)} ${price}`}
    //     amount={priceForStripe}
    //     panelLabel="Pay Now"
    //     currency={currencyPreference}
    //     token={onToken}
    //     stripeKey={paymentMethod.publishableKey}
    // >
    //     <AnimatedButton loading={creatingOrder} buttonLabel={'Confirm and Pay'} loadingLabel={'Creating Order'} confirmedLabel={'Order created'} />
    // </StripeCheckout>
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <StripeCheckoutForm open={paymentFormOpen} handleClose={togglePaymentForm} priceForStripe={priceForStripe} createOrder={createOrder}/>
        </Elements>
      )}

      <AnimatedButton onClick={togglePaymentForm} loading={creatingOrder} buttonLabel={"Confirm and Pay"} loadingLabel={"Creating Order"} confirmedLabel={"Order created"} styles={{ backgroundColor: "#625AFA", color: 'white' }}/>
    </>
  );
};

const mapStateToProps = (state) => ({
  currencyPreference: state.currentStore.currencyPreference,
  currentCustomer: state.currentCustomer.customerInfo,
  storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StripeCheckoutButton);
