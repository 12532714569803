import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { SetErrors, SetSnackNotice } from "src/redux/app/app-actions";
import { Button } from "@mui/material";

function ESewaPaymentButton({ price, currencyPreference, fetchingCurrencyRate, paymentMethod, storeInfo, createOrder, creatingOrder }) {
  const onPayClick = () => {
    var path = "https://uat.esewa.com.np/epay/main";
    var params = {
      amt: price,
      psc: 0,
      pdc: 0,
      txAmt: 0,
      tAmt: price,
      pid: "ee2c3ca1-696b-4cc5-a6be-2c40d929d453",
      scd: "EPAYTEST",
      su: `http://localhost:3000/stores/${storeInfo._id}/orderError`,
      fu: `http://localhost:3000/stores/${storeInfo._id}/orderError`,
    };

    var form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", path);

    for (var key in params) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);
      form.appendChild(hiddenField);
    }

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <div className="ESewaPaymentButton">
      <Button variant="contained" disabled={fetchingCurrencyRate} onClick={onPayClick} style={{backgroundColor: '#65C149'}}>
        Pay Now
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currencyPreference: state.currentStore.currencyPreference,
  currentCustomer: state.currentCustomer.customerInfo,
  storeInfo: state.currentStore.storeInfo,
  fetchingCurrencyRate: state.currentStore.fetchingCurrencyRate,
});

const mapDispatchToProps = (dispatch) => ({
  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ESewaPaymentButton);
