const INITIAL_STATE = {
  notice: {
    message: '',
    severity: 'success',
  },
  stores: '',
  isFetchingStores: false,
  errors: [],
}

// eslint-disable-next-line import/prefer-default-export
export const appReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case 'SET_SNACK_NOTICE':
      return {
        ...state,
        notice: action.payload
      };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload
      };
    case 'FETCH_STORES_START':
      return {
        ...state,
        isFetchingStores: true,
      }
    case 'FETCH_STORES_SUCCESS':
      return {
        ...state,
        stores: [...action.payload],
        isFetchingStores: false,
      };
    case 'FETCH_STORES_ERROR':
      return {
        ...state,
        stores: [],
        isFetchingStores: false,
      };
    default:
      return state;
  }
}
