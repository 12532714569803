import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SetErrors, SetSnackNotice } from '../../../../redux/app/app-actions';
import axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from "src/components/Page";
import {
  Avatar,
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Container,
  useMediaQuery
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { Link as RouterLink } from 'react-router-dom';
import { SetCustomerInfo } from 'src/redux/current-customer/current-customer-actions';
import RefundRequestCard from 'src/components/refund-request-card/refund-request-card';
import { Pagination } from '@material-ui/lab';



const RefundRequestListView = ({ currentCustomer, setSnackNotice, setCustomerInfo, setErrors }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery("(max-width:1050px)");
  const [refundRequests, setRefundRequests] = useState([]);
  const [totalRefundRequests, setTotalRefundRequests] = useState(0);
  
  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      padding: isSmallScreen ? theme.spacing(1) :theme.spacing(3),
      width: '100%',
    },
    avatar: {
      marginRight: theme.spacing(2)
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: 'white'
    },
    cardGrid: {
      display: 'grid',
      gridRowGap: theme.spacing(1)
    },
    refundCardContainer: {
      display: 'grid',
      gridRowGap: theme.spacing(1)
    },
    paginator: {
      justifySelf: 'center'
    }
  }));

  const classes = useStyles();

  useEffect(() => {
    if (totalRefundRequests && page >= Math.ceil(totalRefundRequests / limit)) {
      setPage(Math.ceil(totalRefundRequests / limit) - 1);
    }
  }, [totalRefundRequests]);

  useEffect(() => {
    if (!(currentCustomer && currentCustomer._id)) {
      navigate('/login');
    }
  }, [currentCustomer]);

  useEffect(() => {
    (async () => {
      const filter = {};

      if (currentCustomer._id) {
        const response = await axios({
          url: 'https://kripson-store-server-8qq76.ondigitalocean.app/customers/getRefundRequests',
          method: 'POST',
          data: {
            filter: { customerId: currentCustomer._id },
            page: page || 0,
            limit: limit,
            skip: page * limit,
            sessionId: currentCustomer.sessionId
          }
        });

        if (response.data.status === 1) {
          setTotalRefundRequests(response.data.totals);
          if (response.data.result.length === 0) {
            setPage(0);
          }
          setRefundRequests(response.data.result);
        } else if (response.data.status === 3) {
          setCustomerInfo({});
        } else {
          return [];
        }
      }
    })();
  }, [currentCustomer, page, limit]);

  useEffect(() => {
    if (!(currentCustomer && currentCustomer._id)) {
      navigate('/login');
      setSnackNotice({
        severity: 'warning',
        message: 'Please, login first'
      })
    }
  }, [currentCustomer]);


  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getRefundRequestStatusIcon = status => {
    switch (status) {
      case 'pending':
        return <CachedIcon />;
      case 'declined':
        return <ClearIcon />;
      case 'approved':
        return <CheckIcon />;
      default:
        return;
    }
  };

  const getRefundRequestStatusClass = status => {
    switch (status) {
      case 'pending':
        return classes.warning;
      case 'declined':
        return classes.error;
      case 'approved':
        return classes.success;
      default:
        return;
    }
  };

  return (
    <Page title="Refund Requests" className={classes.root}>
      <Container maxWidth="lg">
        <Box mb={3}>
          <Typography color="textPrimary" variant="h2">
            Refund Requets
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            Your refund request history
          </Typography>
        </Box>


        {isSmallScreen
          ?
          //if small screen show cards
          <Box className={classes.refundCardContainer}>
            <Box className={classes.cardGrid}>
              {refundRequests ? refundRequests.map((refundRequest, idx) => <RefundRequestCard refundRequest={refundRequest} key={idx} />) : ''}
            </Box>
            <Pagination
              color="primary"
              count={Math.ceil(totalRefundRequests / limit) || 1}
              className={classes.paginator}
              size="small"
              onChange={(e, epage) => {
                handlePageChange(e, epage - 1);
              }}
              variant="outlined"
              shape="rounded"
            />
          </Box>
          :
          <Card>
            <PerfectScrollbar>

              <Box minW_idth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Store Id</TableCell>
                      <TableCell>Refund Request Number</TableCell>
                      <TableCell>Requested Date</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {refundRequests && refundRequests.map(request => (
                      <TableRow
                        hover
                        key={request._id}
                      >
                        <TableCell>
                          <Box alignItems="center" display="flex">
                            <Typography color="textPrimary" variant="body1">
                              {request.storeId}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{request.refundRequestNumber}</TableCell>
                        <TableCell>
                          {new Date(request.refundRequestDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          <RouterLink to={`/account/refundRequests/${request.storeId}/${request.refundRequestId}`}>
                            <Button variant="contained" color="primary">
                              Details
                            </Button>
                          </RouterLink>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>

            </PerfectScrollbar>
            <TablePagination
              component="div"
              count={totalRefundRequests}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[1, 5, 10, 25]}
            />
          </Card>
        }
      </Container>
    </Page>
  );
};

const mapStateToProps = state => ({
  currentCustomer: state.currentCustomer.customerInfo
});

const mapDispatchToProps = dispatch => ({

  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
  setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefundRequestListView);
