import React, { useEffect, useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Chip, ListItem, makeStyles, Typography } from "@material-ui/core";
import StyledTreeView from "../../../components/styled-tree-view/StyledTreeView";
import { connect } from "react-redux";
import axios from "axios";
import "./NavItem.scss";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  chip: {
    height: "24px",
    width: "24px",
    position: "relative",
    "& *": {
      display: "flex",
      justifyContent: "center",
    },
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: "white",
  },
  button: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.primary.main,
    },
    justifyContent: "space-between",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "100%",
    
    "& .MuiButton-label":{
      display: 'flex',
      justifyContent: 'flex-start'
    }
  },
  productButton: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "4px 0",
    textTransform: "none",
    width: "100%",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
}));

const NavItem = ({ className, href, title, icon, storeInfo, ...rest }) => {
  const classes = useStyles();

  return (
    <ListItem className={clsx(classes.item, className)} disableGutters {...rest}>
      <Button startIcon={icon ? icon : <></>} activeClassName={classes.active} className={title === "Products" ? classes.productButton : classes.button} component={RouterLink} to={href}>
        {title}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
  storeInfo: state.currentStore.storeInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(NavItem);
