import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SetErrors, SetSnackNotice } from "src/redux/app/app-actions";
import ProductReviewOverall from "../product-review-overall/product-review-overall";
import { makeStyles } from "@material-ui/styles";
import ProductReviewBreakdown from "../product-review-breakdown/product-review-breakdown";
import { Grid, Hidden, useMediaQuery, Box, Button, Accordion, AccordionSummary, Typography, Avatar, Card, IconButton, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProductReviewForm from "../product-review-form/product-review-form";
import { Rating } from "@material-ui/lab";
import { getFormattedDate } from "src/utils/date-time";
import EditIcon from "@material-ui/icons/Edit";
import { Delete, ReplyOutlined } from "@material-ui/icons";
import ProductReviewEditForm from "../product-review-edit-form/product-review-edit-form";
import { SetCustomerInfo } from "src/redux/current-customer/current-customer-actions";
import { validateData } from "src/utils/validation";
import ProductReviewReplyForm from "../product-review-reply-form/product-review-reply-form";
import { useParams } from "react-router-dom";

const ProductReviewCard = ({ storeInfo, review, refreshReviews, currentCustomer, setCustomerInfo, setSnackNotice, setErrors }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [mode, setMode] = useState("detail");
  const {category} = useParams();

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    productReviewCard: {
      display: "grid",
      padding: isSmallScreen ? theme.spacing(2) : theme.spacing(3),
      gridRowGap: theme.spacing(2),
    },
    productReviewReplyBox: {
      display: "grid",
      padding: isSmallScreen ? theme.spacing(1) : theme.spacing(2),
      gridRowGap: theme.spacing(1),
    },
    productReviewReply: {
      padding: isSmallScreen ? "0" : theme.spacing(1),
      border: isSmallScreen ? "none" : "1px solid rgba(0,0,0,0.15)",
      borderRadius: theme.spacing(1),
    },
    ratingSection: {
      display: "grid",
      gridTemplateColumns: isSmallScreen ? "auto" : "auto 1fr auto",
      gridColumnGap: "10px",
      gridRowGap: isSmallScreen ? "4px" : "0",
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      marginBottom: isSmallScreen ? "4px" : "0",
      marginRight: isSmallScreen ? "0" : "4px",
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      marginBottom: isSmallScreen ? "4px" : "0",
      marginRight: isSmallScreen ? "0" : "4px",
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: "white",
    },
    strong: {
      fontWeight: "450",
    },
    ratings: {
      marginLeft: "-3px",
    },
    commentSection: {
      display: isSmallScreen ? "grid" : "flex",
      alignItems: "center",
      marginTop: isSmallScreen ? theme.spacing(1) : "",
      "& p": {
        marginBottom: isSmallScreen ? theme.spacing(1) : "",
      },
    },
  }));

  const classes = useStyles();

  const deleteReview = async () => {
    try {
      const validationResponse = await validateData("editProductReview", review);

      if (!validationResponse.validity) {
        setErrors(validationResponse.errors);

        return;
      }

      const result = await axios({
        url: "https://kripson-store-server-8qq76.ondigitalocean.app/reviews/editProductReview",
        method: "POST",
        data: { storeId: storeInfo._id, categoryId: category, review: { ...review, deleted: true }, sessionId: currentCustomer.sessionId },
      });

      console.log(result);

      if (result.data.status === 0) {
        setErrors(result.data.errors);
        refreshReviews();
        return;
      }

      if (result.data.status === 2 || result.data.status === 3) {
        setCustomerInfo({});
        setSnackNotice({
          severity: "warning",
          message: "Your session has expired, please, login again.",
        });
      }

      setSnackNotice({
        severity: "success",
        message: "Review deleted successfully.",
      });
      refreshReviews();
      setMode("detail");
    } catch (e) {
      setSnackNotice({
        severity: "error",
        message: "Something went wrong, please try again.",
      });
      return;
    }
  };

  const deleteReviewReply = async (index) => {
    try {
      const validationResponse = await validateData("editProductReview", review);

      if (!validationResponse.validity) {
        setErrors(validationResponse.errors);

        return;
      }

      let replyToDelete = review.replies[index];

      replyToDelete.deleted = true;

      const result = await axios({
        url: "https://kripson-store-server-8qq76.ondigitalocean.app/reviews/editProductReview",
        method: "POST",
        data: { storeId: storeInfo._id, categoryId: category, review: { ...review }, sessionId: currentCustomer.sessionId },
      });

      console.log(result);

      if (result.data.status === 0) {
        setErrors(result.data.errors);
        refreshReviews();
        return;
      }

      if (result.data.status === 2 || result.data.status === 3) {
        setCustomerInfo({});
        setSnackNotice({
          severity: "warning",
          message: "Your session has expired, please, login again.",
        });
      }

      setSnackNotice({
        severity: "success",
        message: "Review deleted successfully.",
      });
      refreshReviews();
      setMode("detail");
    } catch (e) {
      setSnackNotice({
        severity: "error",
        message: "Something went wrong, please try again.",
      });
      return;
    }
  };

  return mode === "edit" ? (
    <ProductReviewEditForm review={review} setMode={setMode} refreshReviews={refreshReviews} />
  ) : (
    <Card className={classes.productReviewCard}>
      <Box className={classes.ratingSection}>
        <Avatar src={review.profilePicture} />
        <Box>
          <Typography variant={isSmallScreen ? "h6" : "h5"}>{review.userName}</Typography>
          <Rating readOnly value={review.stars} precision={0.25} className={classes.ratings} />
        </Box>
        <Typography variant={isSmallScreen ? "h6" : "h5"}>{getFormattedDate(review.createdDate)}</Typography>
      </Box>
      <Box className={classes.commentSection}>
        <Typography variant="body1">{review.comment}</Typography>
      </Box>
      {review.replies && review.replies.length ? (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
            <Typography variant="h6">See replies ({review.replies.filter((reply) => !reply.deleted).length})</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.productReviewReplyBox}>
            {review.replies.map((reply, idx) =>
              !reply.deleted ? (
                <Box className={classes.productReviewReply}>
                  <Box className={classes.ratingSection}>
                    <Avatar src={reply.origin === "customer" ? review.profilePicture : storeInfo.storeBanner.url} />
                    <Box>
                      <Typography variant={isSmallScreen ? "h6" : "h5"}>{reply.origin === "customer" ? review.userName : "Store Owner"}</Typography>
                    </Box>
                    <Typography variant={isSmallScreen ? "h6" : "h5"}>{getFormattedDate(reply.createdDate)}</Typography>
                  </Box>
                  <Box className={classes.commentSection}>
                    <Typography variant="body1">{reply.comment}</Typography>
                    {reply.origin === "customer" ? (
                      isSmallScreen ? (
                        <Button startIcon={<Delete />} className={classes.error} fullWidth onClick={() => deleteReviewReply(idx)}>
                          Delete
                        </Button>
                      ) : (
                        <IconButton>
                          <Delete onClick={() => deleteReviewReply(idx)} />
                        </IconButton>
                      )
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              ) : (
                ""
              )
            )}
          </AccordionDetails>
        </Accordion>
      ) : (
        ""
      )}
      {currentCustomer && currentCustomer._id && currentCustomer._id === review.userId ? (
        mode === "reply" ? (
          <ProductReviewReplyForm review={review} setMode={setMode} refreshReviews={refreshReviews} />
        ) : (
          <>
            <Hidden xsDown>
              <Box className={classes.buttonGroup} display="flex" justifyContent="flex-start">
                <Button variant="contained" onClick={() => setMode("edit")} className={classes.warning} startIcon={<EditIcon />}>
                  Edit
                </Button>
                <Button variant="contained" onClick={deleteReview} className={classes.error} startIcon={<Delete />}>
                  Delete
                </Button>
                <Button variant="contained" onClick={() => setMode("reply")} className={classes.success} startIcon={<ReplyOutlined />}>
                  Reply
                </Button>
              </Box>
            </Hidden>

            <Hidden smUp>
              <Box className={classes.buttonGroup}>
                <Button fullWidth variant="contained" onClick={() => setMode("edit")} className={classes.warning} startIcon={<EditIcon />}>
                  Edit
                </Button>
                <Button fullWidth variant="contained" onClick={deleteReview} className={classes.error} startIcon={<Delete />}>
                  Delete
                </Button>
                <Button fullWidth variant="contained" onClick={() => setMode("reply")} className={classes.success} startIcon={<ReplyOutlined />}>
                  Reply
                </Button>
              </Box>
            </Hidden>
          </>
        )
      ) : (
        ""
      )}
    </Card>
  );
};

const mapStateToProps = (state) => ({
  storeInfo: state.currentStore.storeInfo,
  currentCustomer: state.currentCustomer.customerInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo)),
  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductReviewCard);
