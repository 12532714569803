import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';


const NoResults = ({ notice, icon }) => {
    const useStyles = makeStyles(theme => ({
        noResults: {
            display: 'grid',
            width: '100%',
            minHeight: '50vh',
            alignContent: 'center',
            gridRowGap: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
            '& h1': {
                fontSize: theme.spacing(30),
            },
            '& svg':{
                minHeight: '150px',
                minWidth: '150px',
            }
        }
    }))
    const classes = useStyles();
    return <Box className={classes.noResults} justifyContent="center" >
        <Typography variant="h1" align="center" color="textSecondary">{icon ? icon : '?'}</Typography>
        <Typography variant="h4" align="center" color="textSecondary">{notice ? notice : 'No results found.'}</Typography>
    </Box>

}


export default NoResults;