import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Container,
    Link,
    TextField,
    Typography,
    makeStyles,
    InputAdornment
} from '@material-ui/core';
import Page from 'src/components/Page';
import axios from 'axios';
import { SetSnackNotice, SetErrors } from "../../redux/app/app-actions";
import { FetchStoreCategoriesStart, SetStoreInfo } from "../../redux/current-store/current-store-actions";
import DialpadIcon from '@material-ui/icons/Dialpad';
import { connect } from "react-redux";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LockIcon from '@material-ui/icons/Lock';
import { validateData } from 'src/utils/validation';
import { validatePassword } from '../../utils/password-validation';
import PasswordRuleBox from 'src/components/password-rule-box/password-rule-box';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        display: 'grid',
        alignContent: 'center',
    },
    resendButton: {
        backgroundColor: theme.palette.success.main
    }
}));

const ForgotPasswordView = ({ setSnackNotice, setStoreInfo, storeInfo, FetchStoreCategoriesStart, setErrors }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        if (storeInfo.sessionId) {
            navigate('/app/dashboard');
        }
    }, [storeInfo])


    const sendVerification = async () => {
        const validationResponse = await validateData('forgotPassword', { email: email });

        if (!validationResponse.validity) {
            setErrors(validationResponse.errors);
            return;
        }
        try {
            axios({
                url: `https://kripson-store-server-8qq76.ondigitalocean.app/customers/sendVerification`,
                method: 'POST',
                data: {
                    purpose: 'passwordReset',
                    email: email
                }
            }).then((response) => {
                if (response.data.status === 1) {
                    setSnackNotice({
                        message: 'Verification code sent',
                        severity: 'success'
                    });
                }
                else if (response.data.status === 3) {

                    setSnackNotice({
                        severity: 'warning',
                        message: response.data.message
                    });
                }
                else {
                    setSnackNotice({
                        message: response.data.message,
                        severity: 'error'
                    })
                    setErrors(response.data.errors)
                }
            })
        }
        catch (err) {
            setErrors([err]);
        }

    }

    const [passwordErrors, setPasswordErrors] = useState({
        min: true,
        uppercase: true,
        lowercase: true,
        digits: true,
        symbols: true,
        spaces: true
    });



    const validateNewPassword = password => {
        const validationErros = validatePassword(password);

        if (validationErros.length) {
            const newPasswordErros = {};
            Object.keys(passwordErrors).forEach(key => {
                if (validationErros.includes(key)) {
                    newPasswordErros[key] = true;
                } else {
                    newPasswordErros[key] = false;
                }
            });

            setPasswordErrors(newPasswordErros);

        } else {

            setPasswordErrors({
                min: false,
                uppercase: false,
                lowercase: false,
                digits: false,
                symbols: false,
                spaces: false
            });
        }
        setPassword(password);
    };

    useEffect(() => {
        if (confirmPassword === password) {
            setError(null);
        } else {
            setError('Password and confirmation do not match');
        }
    }, [confirmPassword, password]);


    const resetPassword = async () => {

        try {
            setSnackNotice({
                message: 'Resetting password',
                severity: 'info'
            });
            const response = await axios({
                url: 'https://kripson-store-server-8qq76.ondigitalocean.app/customers/resetPassword',
                method: 'POST',
                data: {
                    email: email,
                    password: password,
                    verificationCode
                },
            });

            if (response.data.status === 1) {

                setSnackNotice({
                    message: response.data.message,
                    severity: 'success'
                });
                navigate('/login');
            }
            else {
                setErrors(response.data.errors)
            }
        }
        catch (err) {
            setErrors([err]);
        }

    }
    return (
        <Page
            className={classes.root}
            title="Forgot Password"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">
                    <Box mb={3}>
                        <Typography
                            color="textPrimary"
                            variant="h2"
                        >
                            Reset Password
                        </Typography>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                        >
                            Reset your account password by entering the verification code.
                        </Typography>
                    </Box>
                    <Box my={1} mb={3}>
                        <TextField

                            fullWidth

                            label="Email Address"
                            margin="normal"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AlternateEmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                            type="email"
                            value={email}
                            variant="outlined"
                        />

                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.resendButton}
                            size="large"
                            disabled={! email.length}
                            onClick={sendVerification}
                            fullWidth
                        >
                            Send verification code
                        </Button>



                    </Box>


                    <TextField

                        fullWidth

                        label="Verification Code"
                        margin="normal"
                        name="email"
                        onChange={(e) => setVerificationCode(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <DialpadIcon />
                                </InputAdornment>
                            ),
                        }}
                        type="text"
                        value={verificationCode}
                        variant="outlined"
                    />
                    <TextField

                        fullWidth

                        label="New Password"
                        margin="normal"
                        name="password"
                        onChange={(e) => validateNewPassword(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                        }}
                        type="password"
                        value={password}
                        variant="outlined"
                        error={password && error}
                        helperText={password && error ? error : ''}
                    />
                    <TextField

                        fullWidth

                        label="Confirm Password"
                        margin="normal"
                        name="password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                        }}
                        type="password"
                        value={confirmPassword}
                        variant="outlined"
                        error={password && error}
                        helperText={password && error ? error : ''}
                    />

                    <PasswordRuleBox passwordErrors={passwordErrors} />
                    <Box my={2}>
                        <Button
                            color="primary"
                            fullWidth
                            size="large"
                            variant="contained"
                            disabled={(password || confirmPassword) && (!Object.keys(passwordErrors).every((key) => !passwordErrors[key]) || error)}
                            onClick={resetPassword}
                        >
                            Reset Password
                        </Button>
                    </Box>
                    <Typography
                        color="textSecondary"
                        variant="body1"
                    >
                        Don&apos;t have an account?
                        {' '}
                        <Link
                            component={RouterLink}
                            to="/register"
                            variant="h6"
                        >
                            Sign up
                        </Link>
                    </Typography>
                </Container>
            </Box>
        </Page>
    );
};

const mapStateToProps = state =>
({
    storeInfo: state.currentStore.storeInfo
});

const mapDispatchToProps = dispatch => (
    {
        setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
        setErrors: (errors) => dispatch(SetErrors(errors))
    });

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordView);
