import React, {useState, useEffect} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import Page from 'src/components/Page';
import {SwatchesPicker} from 'react-color';
import {SetSnackNotice} from "../../redux/app/app-actions";
import {connect} from "react-redux";
import axios from 'axios';
import MenuItem from "@material-ui/core/MenuItem";
import {categories} from './storeCategories';
import {DropzoneArea} from "material-ui-dropzone";
import imageCompression from "browser-image-compression";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  navigationButtons: {
    minWidth: '100px'
  },

  policyCheck: {
    paddingLeft: 0,
    marginLeft: '-3px',
    marginBottom: '5px'
  }

}));

const RegisterView = ({setSnackNotice}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [registerationStep, setRegisterationStep] = useState(1);

  const [newStoreInfo, setNewStoreInfo] = useState({
    brandColor: '#000000',
    ownerFirstName: '',
    ownerLastName: '',
    storeEmail: '',
    password: '',
    policy: false,
    storeName: '',
    storeAddress: '',
    storeCategory: '',
    storeBanner:''
  });

  const imageConversion = async (files) => {
    const compressedFileArray = [];
    for (let file of files) {

      const options = {
        maxSizeMB: 0.4,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      try {
        const compressedFile = await imageCompression(file, options);
        compressedFileArray.push(compressedFile);

      } catch (error) {
        console.log(error);
      }
    }


    const base64dataarray = [];
    let counter = base64dataarray.length;

    compressedFileArray.forEach((file, idx) => {
      let reader = new FileReader();
      reader.onloadend = function () {
        base64dataarray[counter].base64data = reader.result;
        if (idx === files.length - 1) {
          setNewStoreInfo({
            ...newStoreInfo,
            storeBanner: base64dataarray[0]
          })

        }

        counter++;


      };
      reader.readAsDataURL(file);
      base64dataarray.push({type: file.type});
    })
  };


  useEffect(() => {
    if (newStoreInfo.brandColor && newStoreInfo.ownerFirstName && newStoreInfo.ownerLastName && newStoreInfo.storeEmail && newStoreInfo.password && newStoreInfo.policy && newStoreInfo.storeAddress && newStoreInfo.storeName && newStoreInfo.storeCategory && newStoreInfo.storeBanner) {
      console.log(newStoreInfo);
      registerNewStore();
    }
  }, [newStoreInfo]);


  const registerNewStore = async () => {
    setSnackNotice({
      message:'Registering your new store',
      severity: 'info'
    });
    const response = await axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/stores/createStore',
      method: 'POST',
      data: newStoreInfo
    });

    if (response.data.status === 1) {
      navigate('/login');
    } else if (response.data.status === 0) {
      console.log(response.data);
    }


  }


  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          {registerationStep === 1 ? <Formik
            initialValues={{
              storeEmail: newStoreInfo.storeEmail,
              ownerFirstName: newStoreInfo.ownerFirstName,
              ownerLastName: newStoreInfo.ownerLastName,
              password: newStoreInfo.password,
              policy: newStoreInfo.policy
            }}
            validationSchema={
              Yup.object().shape({
                storeEmail: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                ownerFirstName: Yup.string().max(255).required('First name is required'),
                ownerLastName: Yup.string().max(255).required('Last name is required'),
                password: Yup.string().max(255).required('password is required'),
                policy: Yup.boolean().oneOf([true], 'This field must be checked')
              })
            }
            onSubmit={(values) => {
              setNewStoreInfo({
                ...newStoreInfo,
                ...values
              });
              setRegisterationStep(registerationStep === 3 ? 3 : registerationStep + 1);
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <form>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Register your store
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use your email to register your store
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.ownerFirstName && errors.ownerFirstName)}
                  fullWidth
                  helperText={touched.ownerFirstName && errors.ownerFirstName}
                  label="First name"
                  margin="normal"
                  name="ownerFirstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ownerFirstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.ownerLastName && errors.ownerLastName)}
                  fullWidth
                  helperText={touched.ownerLastName && errors.ownerLastName}
                  label="Last name"
                  margin="normal"
                  name="ownerLastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ownerLastName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.storeEmail && errors.storeEmail)}
                  fullWidth
                  helperText={touched.storeEmail && errors.storeEmail}
                  label="Email Address"
                  margin="normal"
                  name="storeEmail"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.storeEmail}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box
                  alignItems="center"
                  display="flex"
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                    className={classes.policyCheck}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    I have read the
                    {' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>
                <Grid justify="flex-end" direction='row' container mt={3}>
                  <Grid container justify="flex-end" sm={6} xs={12} spacing={3}>
                    <Grid item>
                      {registerationStep !== 1 ?
                        <Button className={classes.navigationButtons} variant="contained" color="secondary"
                                onClick={() => setRegisterationStep(registerationStep === 1 ? 1 : registerationStep - 1)}>
                          Back
                        </Button> : ""}
                    </Grid>

                    {registerationStep !== 3 ?
                      <Grid item><Button className={classes.navigationButtons} variant="contained" color="primary"
                                         onClick={handleSubmit}>
                        Next
                      </Button> </Grid> : ""}


                  </Grid>
                </Grid>
              </form>
            )}
          </Formik> : ''}

          {registerationStep === 2 ? <Formik
            initialValues={{
              storeName: newStoreInfo.storeName,
              storeAddress: newStoreInfo.storeAddress,
            }}
            validationSchema={
              Yup.object().shape({
                storeName: Yup.string().max(255).required('Store name is required'),
                storeAddress: Yup.string().max(255).required('Business Address is required'),
              })
            }
            onSubmit={async (values,{setSubmitting}) => {
              setNewStoreInfo({
                ...newStoreInfo,
                ...values
              });
              setSubmitting(false);
              setRegisterationStep(registerationStep === 3 ? 3 : registerationStep + 1);
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <form>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Please, provide your business details
                  </Typography>

                </Box>
                <TextField
                  error={Boolean(touched.storeName && errors.storeName)}
                  fullWidth
                  helperText={touched.storeName && errors.storeName}
                  label="Store name"
                  margin="normal"
                  name="storeName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.storeName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.storeAddress && errors.storeAddress)}
                  fullWidth
                  helperText={touched.storeAddress && errors.storeAddress}
                  label="Store address"
                  margin="normal"
                  name="storeAddress"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.storeAddress}
                  variant="outlined"
                />
                <Box my={3}>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Pick your brand color for the store
                  </Typography>

                </Box>
                <SwatchesPicker
                  label="Brand Color"
                  name="brandColor"
                  color={newStoreInfo.brandColor}
                  onChangeComplete={(color) => {
                    setNewStoreInfo({...newStoreInfo, brandColor: color.hex});
                  }}
                  width={window.width / 2}
                />

                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>
                <Grid justify="flex-end" direction='row' container mt={3}>
                  <Grid container justify="flex-end" sm={6} xs={12} spacing={3}>
                    <Grid item>
                      {registerationStep !== 1 ?
                        <Button className={classes.navigationButtons} variant="contained" color="secondary"
                                onClick={() => setRegisterationStep(registerationStep === 1 ? 1 : registerationStep - 1)}>
                          Back
                        </Button> : ""}
                    </Grid>

                    {registerationStep !== 3 ?
                      <Grid item><Button className={classes.navigationButtons} variant="contained" color="primary"
                                         onClick={handleSubmit}>
                        Next
                      </Button> </Grid> : ""}


                  </Grid>
                </Grid>
              </form>
            )}
          </Formik> : ''}

          {registerationStep === 3 ? <Formik
            initialValues={{
              storeCategory: newStoreInfo.storeCategory
            }}
            validationSchema={
              Yup.object().shape({
                storeCategory: Yup.string().max(25).required('Store Category is required'),
              })
            }
            onSubmit={async (values,{setSubmitting}) => {
              // console.log(values);
              // if(values.storeCategory === 'Other')
              // {
              //   delete values.storeCategory;
              //   if(newStoreInfo.storeCategory.length > 0)
              //   {
              //     setNewStoreInfo({
              //       ...newStoreInfo,
              //       ...values
              //     });
              //   }
              // }
              // else{
                setNewStoreInfo({
                  ...newStoreInfo,
                  ...values
                });
              // }
              setSubmitting(false);
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <form>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Please, provide further details of your business
                  </Typography>

                </Box>
                <TextField
                  select
                  label="Select"
                  value={values.storeCategory}
                  onChange={handleChange}
                  helperText={"Please select the category of the store"}
                  variant="outlined"
                  onBlur={handleBlur}
                  name="storeCategory"
                  fullWidth
                  error={Boolean(touched.storeCategory && errors.storeCategory)}
                  margin="normal"
                >
                  {categories.map(category=><MenuItem value={category}>
                    {category}
                  </MenuItem>)}
                </TextField>

                {/*{values.storeCategory === 'Other'?*/}
                {/*  <TextField*/}
                {/*  label="Store Category"*/}
                {/*  value={newStoreInfo.storeCategory}*/}
                {/*  onChange={(e)=>{setNewStoreInfo({*/}

                {/*    ...newStoreInfo,*/}
                {/*    storeCategory: e.target.value*/}
                {/*  });*/}
                {/*  console.log(e.target)}}*/}
                {/*  helperText={"Please provide the category of the store"}*/}
                {/*  variant="outlined"*/}
                {/*  onBlur={handleBlur}*/}
                {/*  name="storeCategory"*/}
                {/*  fullWidth*/}
                {/*  error={newStoreInfo.hasOwnProperty('storeCategory') && newStoreInfo.storeCategory.length}*/}
                {/*  margin="normal"*/}
                {/*/>:''}*/}

                <DropzoneArea
                  filesLimit={1}
                  onChange={(files) => imageConversion(files)}
                  name = "storeBanner"
                />

                <Box my={2}>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign up now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>
                <Grid justify="flex-end" direction='row' container mt={3}>
                  <Grid container justify="flex-end" sm={6} xs={12} spacing={3}>
                    <Grid item>
                      {registerationStep !== 1 ?
                        <Button className={classes.navigationButtons} variant="contained" color="secondary"
                                onClick={() => setRegisterationStep(registerationStep === 1 ? 1 : registerationStep - 1)}>
                          Back
                        </Button> : ""}
                    </Grid>

                    {registerationStep !== 3 ?
                      <Grid item><Button type="submit" className={classes.navigationButtons} variant="contained"
                                         color="primary"
                                         onClick={() => setRegisterationStep(registerationStep === 3 ? 3 : registerationStep + 1)}>
                        Next
                      </Button> </Grid> : ""}


                  </Grid>
                </Grid>
              </form>
            )}
          </Formik> : ''}
        </Container>
      </Box>
    </Page>
  );
};


const mapStateToProps = state =>
  ({

  });

const mapDispatchToProps = dispatch => (
  {
    setSnackNotice: (notice) => dispatch(SetSnackNotice(notice))
  });

export default connect(mapStateToProps, mapDispatchToProps)(RegisterView);
