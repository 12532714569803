import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Avatar, Box, Card, CardContent, Divider, Grid, Typography, CardHeader, Chip, Accordion, AccordionSummary, AccordionDetails, Button, Fab } from "@material-ui/core";
import * as FA from "react-fontawesome";
import { SetErrors, SetSnackNotice } from "src/redux/app/app-actions";
import { FetchCurrencyExchangeRate, FetchStoreNotificationsStart, FetchStoreordersStart } from "src/redux/current-store/current-store-actions";
import CachedIcon from "@material-ui/icons/Cached";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import momentTimeZone from "moment-timezone";
import "./checkout-item-card.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SendIcon from "@material-ui/icons/Send";
import Decimal from "decimal.js-light";
import { AddItem, DecreaseQuantity, RemoveItem } from "src/redux/cart/cart.actions";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import getSymbolFromCurrency from "currency-symbol-map";
import { convertToSelectedCurrency } from "src/utils/convertToSelectedCurrency";

function CheckOutItemCard({ storeId, storeInfo, cartItem, RemoveItem, AddItem, DecreaseQuantity, currentStore, fetchCurrencyExchangeRate, ...rest }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),

    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: "white",
    },
    arrow: {
      width: '24px',
      height: '24px',
      minHeight: 'unset',
      borderRadius: '5px',
    }
  }));

  const classes = useStyles();

  const [card, setCard] = React.useState("");
  const [discountApplied, setDiscountApplied] = React.useState(new Decimal("0"));

  useEffect(() => {
    let temp = new Decimal("0");
    if (cartItem.discountData && Object.keys(cartItem.discountData).length) {
      for (const key in cartItem.discountData) {
        temp = temp.plus(cartItem.discountData[key]["discountAmountApplied"]);
      }
    }

    setDiscountApplied(temp);
  }, [cartItem]);

  return (
    <Card className={`${classes.root} CheckOutItemCard`} {...rest}>
      <div className="productImageSection" style={{ backgroundImage: `url(${cartItem.item.images[0].url})` }}></div>
      <div className="productDetailSection">
        <Typography variant="h4">{cartItem.item.title}</Typography>
        <Grid container spacing={2} style={{ flexWrap: 'wrap' }}>
          <Grid item xs={12} md={8}>
            <Typography variant="subtitle1">
              <span className="quantity">
                <strong>Qty :</strong>
                <Fab className={classes.arrow} size="small" color="default" aria-label="reduce" onClick={() => DecreaseQuantity(cartItem.item, storeId)}>
                  <RemoveIcon />
                </Fab>
                <Typography className="value" color="textPrimary" variant="h5" style={{ marginTop: 0, marginBottom: 0 }}>
                  {cartItem.quantity}
                </Typography>
                {cartItem.quantity !== cartItem.item.stockQuantity[cartItem.item.selectedSize] ? (
                  <Fab color="primary" size="small" aria-label="add" className={classes.arrow} onClick={() => AddItem(cartItem.item, storeId)}>
                    <AddIcon />
                  </Fab>
                ) : (
                  ""
                )}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1">
              <strong>Size :</strong> {cartItem.item.selectedSize}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h5">
          {discountApplied.toString() !== "0" ? (
            <>
              <del>{`  ${getSymbolFromCurrency(currentStore.currencyPreference)} ` + convertToSelectedCurrency(new Decimal(cartItem.item.price).times(cartItem.quantity).toString(), currentStore)}</del>
              <ins style={{ color: "red" }}>
                {`  ${getSymbolFromCurrency(currentStore.currencyPreference)} ` + convertToSelectedCurrency(new Decimal(cartItem.item.price)
                  .times(cartItem.quantity)
                  .sub(discountApplied)
                  .toString(), currentStore)}
              </ins>
            </>
          ) : (
            `  ${getSymbolFromCurrency(currentStore.currencyPreference)} ` + convertToSelectedCurrency(new Decimal(cartItem.item.price)
              .times(cartItem.quantity)
              .sub(discountApplied)
              .toString(), currentStore)
          )}
        </Typography>
      </div>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  notifications: state.currentStore.notifications,
  currentStore: state.currentStore,
});

const mapDispatchToProps = (dispatch) => ({
  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
  RemoveItem: (item, storeId) => dispatch(RemoveItem(item, storeId)),
  AddItem: (item, storeId) => dispatch(AddItem(item, storeId)),
  DecreaseQuantity: (item, storeId) => dispatch(DecreaseQuantity(item, storeId)),
  fetchCurrencyExchangeRate: (convertFrom, convertTo, storeId) => dispatch(FetchCurrencyExchangeRate(convertFrom, convertTo, storeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutItemCard);
