import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./footer.scss";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Grid, makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
  },
  
  headings: {
    color: theme.palette.primary.contrastText,
  },
}));

const Footer = ({ currentCustomer }) => {
  const classes = useStyles();
  return (
    <Paper >

      <Grid container spacing={1} className={`${classes.footer}`}>
        <Grid item className={`${classes.navigation} Navigation`} xs={6} lg={3}>
          <h1 className={`${classes.headings}`}>Navigation</h1>
          <ul>
            <li>
              <Link to="/registerCustomer">Become a member</Link>
            </li>
            <li>
              <Link to="/register">Register your store</Link>
            </li>
            {currentCustomer ? (
              <li>
                <Link to="/account">Account</Link>
              </li>
            ) : (
              <li>
                <Link to="/login">Login</Link>
              </li>
            )}
          </ul>
        </Grid>
        <Grid item className="FAQs" xs={6} lg={3}>
          <h1 className={`${classes.headings}`}>FAQs</h1>
          <ul>
            <li>
              <Link to="/faq">When does free ad expire?</Link>
            </li>
            <li>
              <Link to="/faq">How to make your car available for reservation?</Link>
            </li>
            <li>
              <Link to="/faq">All Questions</Link>
            </li>
          </ul>
        </Grid>
        <Grid item className="SocialMediaLinks" xs={6} lg={3}>
          <h1 className={`${classes.headings}`}>Links</h1>
          <ul>
            <li>
              <a href={"https://www.facebook.com/Car-Vendor-Nepal-110939690769219"}>
                <FacebookIcon />
                <span>Facebook</span>
              </a>
            </li>
            <li>
              <a href={"https://www.instagram.com/carvendornepal/?hl=en"}>
                <InstagramIcon />
                <span>Instagram</span>
              </a>
            </li>
            <li>
              <a href={""}>
                <TwitterIcon />
                <span>Twitter</span>
              </a>
            </li>
          </ul>
        </Grid>
        <Grid item className="Contacts" xs={6} lg={3}>
          <h1 className={`${classes.headings}`}>Contacts</h1>
          <ul>
            <li>
              <svg viewBox="0 0 20 20" fill="currentColor" className="device-mobile w-6 h-6">
                <path fill-rule="evenodd" d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path>
              </svg>
              <span>
                <a href={"tel://+61405502783"}>+61405502783</a> | <a href={"tel://+9779857029182"}>+9779857029182</a>
              </span>
            </li>
            <li>
              <svg viewBox="0 0 20 20" fill="currentColor" className="phone w-6 h-6">
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
              </svg>
              <span>
                <a href={"tel://+977071540220"}>071540220</a>
              </span>
            </li>
            <li>
              <svg viewBox="0 0 20 20" fill="currentColor" className="mail w-6 h-6">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
              <span>
                <a href={"mailto:sthrajanu@gmail.com"}>admin@kripsonstore.com</a>
              </span>
            </li>
          </ul>
        </Grid>
      </Grid>

      <div className="footercopyrightbox">
        <span className="copyrightmark">2020 © kripsonstore.com All Rights Reserved</span>
      </div>

    </Paper >
  );
};

const mapStateToProps = (state) => ({
  currentCustomer: state.currentCustomer.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
