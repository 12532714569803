import React, { useEffect, useState } from "react";
import { Avatar, Box, Chip, Divider, Grid, TextField, makeStyles } from "@material-ui/core";

import Page from "src/components/Page";

import "react-awesome-slider/dist/styles.css";
import "../../../customStyles.scss";
import { Link, useNavigate } from "react-router-dom";

import { SetErrors, SetSnackNotice } from "../../../redux/app/app-actions";
import { connect } from "react-redux";
import axios from "axios";
import EditIcon from "@material-ui/icons/Edit";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// import RefundForm from "../order-designer-view/edit-order-form";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import * as _ from "lodash";
import HistoryIcon from "@material-ui/icons/History";
import Decimal from "decimal.js-light";
import { SetCustomerInfo } from "src/redux/current-customer/current-customer-actions";
import RefundItemCard from 'src/components/refund-item-card/refund-item-card';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    padding: theme.spacing(3),
    width: "100%",
  },
  orderDetailsSection: {
    padding: "20px !important",
    display: "grid",
    alignContent: "start",
    gridRowGap: "20px",
  },
  orderColor: {
    height: "25px",
    width: "25px",
    borderRadius: "25px",
  },
  sizeOptionContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, 50px)",
    gridColumnGap: "10px",
  },
  sizeChoice: {
    backgroundColor: "white",
    display: "grid",
    justifyContent: "center",
  },
  statusSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  detailsSectionRows: {},
  container: {
    maxHeight: 440,
  },
  customerDetailsSection: {
    marginBottom: "16px",
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    marginLeft: theme.spacing(1),
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginLeft: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: "white",
    marginLeft: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
}));

const RefundForm = ({ currentCustomer, order, mode, setMode, productImages, setSnackNotice, setErrors, setCustomerInfo }) => {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1);
  const [refundRequest, setRefundRequest] = React.useState({});
  const [refundTotal, setRefundTotal] = React.useState("0");
  const [storeInfo, setStoreInfo] = React.useState({});
  const [validationErrors, setValidationErrors] = React.useState({});

  useEffect(() => {
    setRefundRequest({
      products: order.products.reduce((acc, product) => {
        return {
          ...acc,
          [product._id]: {
            quantityToRefund: 0,
            amount: "0",
          },
        };
      }, {}),
    });
  }, [order]);

  useEffect(() => {
    console.log(validationErrors);
  }, [validationErrors]);


  useEffect(() => {
    retrieveStore(order.storeId);
  }, [order])

  const retrieveStore = (storeId) => {
    axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/stores',
      method: 'POST',
      data: {
        sessionId: currentCustomer.sessionId,
        filter: {
          _id: storeId
        }
      }
    }).then((response) => {
      if (response.data.status === 1) {
        setStoreInfo(response.data.result[0])
      }
      else if (response.data.status === 3) {
        setCustomerInfo({});
        setSnackNotice({
          severity: 'warning',
          message: response.data.message
        });
      }
      else {
        setErrors(response.data.errors);
        setSnackNotice({
          message: 'The store that was associated with this order has been shut down.',
          severity: 'error'
        });
      }

    });
  }

  const validateRequest = () => {
    Object.keys(refundRequest.products).forEach(function (productId, idx) {
      if (refundRequest.products[productId].quantityToRefund > 0 && !refundRequest.products[productId].reason) {
        setValidationErrors({
          ...validationErrors,
          [productId]: {
            reason: "Please, provide a reason",
          },
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [productId]: {
            reason: null,
          },
        });
      }
    });
  };

  useEffect(() => {
    if (refundRequest && refundRequest.products && Object.keys(refundRequest, refundRequest.products).length) {
      setRefundTotal(calculateTotalRefundAmount());
      validateRequest();
    }

    console.log(refundRequest);
  }, [refundRequest]);

  useEffect(() => {
    console.log(refundTotal);
  }, [refundTotal]);

  const handleQuantityChange = (event) => {
    const savedProduct = order.products.find((product) => product._id + product.selectedSize === event.target.name);
    const previousRefundedQuantity = savedProduct.refundedQuantity || 0;

    if (previousRefundedQuantity + Number(event.target.value) <= savedProduct.quantity) {
      setRefundRequest({
        ...refundRequest,
        products: {
          ...refundRequest.products,
          [savedProduct._id]: {
            ...refundRequest.products[savedProduct._id],
            quantityToRefund: Number(event.target.value),
            amount: new Decimal(event.target.value).mul(new Decimal(savedProduct.price)).toString(),
          },
        },
      });
    }
  };

  const handleReasonChange = (event) => {
    console.log(event.target.value);
    console.log(event.target.name);
    console.log(order.products);
    const savedProduct = order.products.find((product) => product._id + product.selectedSize === event.target.name);

    setRefundRequest({
      ...refundRequest,
      products: {
        ...refundRequest.products,
        [savedProduct._id]: {
          ...refundRequest.products[savedProduct._id],
          reason: event.target.value,
        },
      },
    });
  };

  const calculateTotalRefundAmount = () => {
    return Object.keys(refundRequest.products).reduce((acc, currentValue) => {
      return new Decimal(acc).add(new Decimal(refundRequest.products[currentValue].amount)).toString();
    }, "0");
  };

  const createRefundRequest = async () => {
    if (refundTotal !== "0" && Object.keys(validationErrors).every((productId) => !validationErrors[productId].reason)) {
      if (new Decimal(refundTotal).add(new Decimal(order.refundedAmount || "0")).lte(new Decimal(order.total))) {
        setSnackNotice({
          message: "Order editing in process",
          severity: "info",
        });

        try {
          const response = await axios({
            url: "https://kripson-store-server-8qq76.ondigitalocean.app/refundRequests/createRefundRequest",
            method: "POST",
            onUploadProgress: (progressEvent) => console.log(progressEvent),
            data: {
              customerId: currentCustomer._id,
              sessionId: currentCustomer.sessionId,
              products: Object.keys(refundRequest.products)
                .filter((productId) => refundRequest.products[productId].quantityToRefund > 0)
                .reduce((acc, currentValue) => {
                  return {
                    ...acc,
                    [currentValue]: refundRequest.products[currentValue],
                  };
                }, {}),
              refundTotal: refundTotal,
              storeId: order.storeId,
              orderId: order._id,
            },
          });
          if (response.data.status === 1) {
            setSnackNotice({
              message: "Refund Request Created",
              severity: "success",
            });

            setMode("detailMode");
          }
          else if (response.data.status === 3) {
            setCustomerInfo({});
            setSnackNotice({
              severity: 'warning',
              message: response.data.message
            });
          }
          else {
            setErrors(response.data.errors);
            setSnackNotice({
              message: "Request could not be created",
              severity: "error",
            });
          }
        } catch (e) {
          console.log(e);
          setSnackNotice({
            message: "Request could not be created",
            severity: "error",
          });
        }
      } else {
        setErrors(["Refund amount cannot exceed order totals"]);
      }
    } else {
      const errors = [];
      if (refundTotal === "0") {
        errors.push("Refund amount must be greater than 0");
      }

      if (!Object.keys(validationErrors).every((productId) => !validationErrors[productId].reason)) {
        errors.push("Please provide a reason for each item refund");
      }
      setErrors(errors);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "productImage", label: "Product", minWidth: 100 },
    { id: "title", label: "Title", minWidth: 70 },
    {
      id: "quantity",
      label: "Quantity",
      minWidth: 50,
      align: "center",
      format: (value) => value.toLocaleString("en-IN"),
    },
    {
      id: "selectedSize",
      label: "Size",
      minWidth: 35,
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "refundedQuantity",
      label: "Items refunded already",
      minWidth: 35,
      align: "center",
      format: (value) => value.toFixed(2),
    },
    {
      id: "refundQuantity",
      label: "No of items to refund",
      minWidth: 150,
      align: "left",
      format: (value) => value.toLocaleString("en-IN"),
    },
    {
      id: "reason",
      label: "Reason For Refund",
      minWidth: 150,
      align: "left",
      format: (value) => value.toLocaleString("en-IN"),
    },
  ];

  return (
    <Page className={classes.root} title="Order Details">
      <Box mb={2} className={classes.statusSection}>
        <Box display="flex" alignItems="center">
          <Typography variant="h3">Refund Form</Typography>
        </Box>
      </Box>
      <Box display={{ xs: 'none', lg: 'block' }}>
        <Paper>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {order && order.products
                  ? order.products.filter(prd => prd.isRefundable).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, idx) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={product._id}>
                        {columns.map((column) => {
                          const value = column.id === "productImage" ? Object.keys(productImages).length ? <Avatar src={productImages[product._id]} /> : "" : product[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "refundQuantity" ? (
                                (product.hasOwnProperty("refundedQuantity") ? (
                                  product.refundedQuantity !== product.quantity
                                ) : (
                                  true
                                )) ? (
                                  <TextField
                                    error={Boolean(validationErrors["customer.email"])}
                                    label="Quantity to refund"
                                    fullWidth={true}
                                    margin="normal"
                                    name={product._id + product.selectedSize}
                                    onChange={handleQuantityChange}
                                    value={refundRequest && refundRequest.products && refundRequest.products[product._id] ? refundRequest.products[product._id].quantityToRefund : 0}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                      setSnackNotice({
                                        severity: "error",
                                        message: "Typing not allowed. Please, use the increase/decrease buttons instead",
                                      });
                                    }}
                                    type="number"
                                    variant="outlined"
                                    InputProps={{
                                      inputProps: { min: 0, max: product.quantity - (product.refundedQuantity || 0) },
                                    }}
                                  />
                                ) : (
                                  <Chip className={classes.error} label={"Fully Refunded"} />
                                )
                              ) : column.id === "reason" ? (
                                <TextField
                                  error={Boolean(validationErrors && validationErrors[product._id] && validationErrors[product._id].reason)}
                                  helperText={validationErrors && validationErrors[product._id] && validationErrors[product._id].reason}
                                  label="Reason for refund"
                                  margin="normal"
                                  name={product._id + product.selectedSize}
                                  value={refundRequest && refundRequest.products && refundRequest.products[product._id] ? refundRequest.products[product._id].reason : ''}
                                  onChange={handleReasonChange}
                                  type="text"
                                  variant="outlined"
                                />
                              ) : column.format && typeof value === "number" ? (
                                column.format(value)
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                  : ""}

                <TableRow>
                  {columns.map((column, idx) =>
                    idx === 0 ? (
                      <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                        <Typography variant="h5">Refund Total</Typography>
                      </TableCell>
                    ) : idx === 6 ? (
                      <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                        <Typography variant="h5">{`${storeInfo.storeCurrencySymbol} ${refundTotal}`}</Typography>
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={order && order.products ? order.products.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>


      <Box display={{ xs: 'block', lg: 'none' }}>
        {order && order.products
          ? order.products.map((product, idx) => (
            <RefundItemCard
              product={product}
              productImage={
                product && productImages ? productImages[product._id] : ''
              }
              handleReasonChange={handleReasonChange}
              handleChange={handleQuantityChange}
              value={refundRequest && refundRequest.products && refundRequest.products[product._id] ? refundRequest.products[product._id].quantityToRefund : 0}
              reason={refundRequest && refundRequest.products && refundRequest.products[product._id] ? refundRequest.products[product._id].reason : ''}
            />
          ))
          : ''}
      </Box>

      <Box mt={3}>
        <Box mb={2}>
          <Typography variant="h3" style={{ display: "flex", alignItems: "center" }}>
            Refund History <HistoryIcon />
          </Typography>
        </Box>

        <Paper>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Refund Amount</TableCell>
                  <TableCell>Refund Request Number</TableCell>
                  <TableCell>Reason for refund</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {order && order.refunds
                  ? order.refunds.map((refund, idx) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                        <TableCell key={idx + refund.amount}>{`${'Rs'} ` + refund.amount}</TableCell>
                        {refund && refund.hasOwnProperty() && refund.hasOwnProperty() ?
                          <Link to={`/account/refundRequests/${order.storeId}/${refund.refundRequestId}`}><TableCell key={idx + refund.amount}>{refund.refundRequestNumber}</TableCell></Link> :
                          <TableCell></TableCell>}
                        <TableCell key={idx + refund.reason}>{refund.reason}</TableCell>
                      </TableRow>
                    );
                  })
                  : ""}

                <TableRow>
                  <TableCell>Total Amount Refunded:</TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    {'Rs'}{" "}
                    {order && order.refunds
                      ? order.refunds.reduce((acc, refund) => {
                        const oldValue = new Decimal(acc.amount);
                        return { amount: new Decimal(refund.amount).add(oldValue).toString() };
                      }).amount
                      : 0}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={order && order.products ? order.products.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      {order.total !== order.refundedAmount ? <Grid container justify="flex-end" className={classes.buttonContainer}>
        <Grid item>
          <Grid container spacing={1}>
            {mode === 'refundMode' ? <Grid item xs={6}>
              <Button fullWidth variant="contained" color="primary" startIcon={<EditIcon />} onClick={createRefundRequest}>
                Request
              </Button>
            </Grid> : ''}
            <Grid item xs={6}>
              <Button fullWidth variant="contained" className={classes.error} onClick={() => setMode("detailMode")}>
                {mode === 'refundMode' ? 'Cancel' : 'Back'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid> : ''}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  storeInfo: state.currentStore.storeInfo,
  currentCustomer: state.currentCustomer.customerInfo
});

const mapDispatchToProps = (dispatch) => ({
  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setErrors: (validationErrors) => dispatch(SetErrors(validationErrors)),
  setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundForm);
