export const ToggleCart = () => ({
  type: "TOGGLE_CART",
});

export const AddItem = (item, storeId, quantity = 1) => ({
  type: "ADD_ITEM",
  payload: {
    item,
    storeId,
    quantity
  },
});

export const ClearStoreCart = (storeId) => ({
  type: "CLEAR_STORE_CART",
  payload: {
    storeId,
  },
});

export const ClearCart = () => ({
  type: "CLEAR_CART",
});

export const RemoveItem = (item, storeId) => ({
  type: "REMOVE_ITEM",
  payload: {
    item,
    storeId,
  },
});

export const DecreaseQuantity = (item, storeId) => ({
  type: "DECREASE_QUANTITY",
  payload: {
    item,
    storeId,
  },
});

export const SetStoreCart = (items, storeId) => ({
  type: "SET_STORE_CART",
  payload: {
    items,
    storeId,
  },
});

export const SetStoreCartDiscountMap = (discountMap, storeId) => ({
  type: "SET_STORE_CART_DISCOUNT_MAP",
  payload: {
    discountMap,
    storeId,
  },
});



export const ApplyDiscountCodes = (codes, storeId, cartItems, customer) => ({
  type: "APPLY_DISCOUNT_CODES",
  payload: {
    codes,
    storeId,
    cartItems,
    customer,
  },
});
