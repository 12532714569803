import axios from "axios";
import Decimal from "decimal.js-light";
import * as _ from "lodash";

export const applyApplicableDiscounts = async (discountCodesToApply, storeId, cartItems, customer = {}) => {
  console.log(discountCodesToApply, cartItems);
  
  if(discountCodesToApply && discountCodesToApply.length === 0) {
    let arrayToReturn = _.cloneDeep(cartItems);
    arrayToReturn = arrayToReturn.map(item=>{
      const itemToReturn = _.cloneDeep(item);
      delete itemToReturn['discountData'];
      return itemToReturn;
    })
    return {discountedCartItems: arrayToReturn, discountMap: {}};
  }
  
  const storeDiscountsData = await getStoreDiscounts(storeId);

  

  if (storeDiscountsData.data.status === 1) {
    const storeDiscounts = storeDiscountsData.data.result.filter((discount) => discountCodesToApply.includes(discount.code));
    const discountUseCountMap = {};
    const flatArrayOfCartProducts = [];
    let productPoolToApplyDiscount = [];
    let productPoolToSatisfyRequirements = [];

    cartItems.forEach((item) => {
      for (let x = 1; new Decimal(x).lte(item.quantity); x++) {
        const itemToPush = _.cloneDeep(item.item);
        flatArrayOfCartProducts.push(itemToPush);
      }
    });

    productPoolToApplyDiscount = _.cloneDeep(flatArrayOfCartProducts);
    productPoolToSatisfyRequirements = _.cloneDeep(flatArrayOfCartProducts);

    for (const discount of storeDiscounts) {
      let useCount = 0;
      const { productLimitPerUse, value, type, maxNumberOfUsePerOrder } = discount;
      let productsEligibleForDiscount = [];

      if (discount.appliesTo === "order") {
        productsEligibleForDiscount = _.cloneDeep(productPoolToApplyDiscount);
      } else if (discount.appliesTo === "products") {
        productsEligibleForDiscount = productPoolToApplyDiscount.filter((item) => discount.eligibleProducts.includes(item._id));
      }

      //Per Use loop
      while (true) {
        let productsToApplyDiscountOn = productsEligibleForDiscount.slice(0, productLimitPerUse ? productLimitPerUse : productsEligibleForDiscount.length);
        console.log(productsToApplyDiscountOn);
        for (const prd of productsToApplyDiscountOn) {
          console.log(flatArrayOfCartProducts);
          const indexToChange = flatArrayOfCartProducts.findIndex((prd2) => prd2._id === prd._id && prd2.selectedSize === prd.selectedSize && !(prd2.discountApplied));
          console.log(indexToChange);
          const productToModifyFromFlatArray = flatArrayOfCartProducts[indexToChange];
          productToModifyFromFlatArray.discountApplied = discount._id;
          productToModifyFromFlatArray.discountAmountApplied = getDiscountAmountToApply(type, value, prd).toString();

          //remove product from product pool used to apply discount
          let indexToRemove = productPoolToApplyDiscount.findIndex((prd2) => prd2._id === prd._id && prd2.selectedSize === prd.selectedSize);
          productPoolToApplyDiscount.splice(indexToRemove, 1);

          //remove product from eligible products array
          indexToRemove = productsEligibleForDiscount.findIndex((prd2) => prd2._id === prd._id && prd2.selectedSize === prd.selectedSize);
          productsEligibleForDiscount.splice(indexToRemove, 1);
        }

        useCount++;

        if (!(productsEligibleForDiscount.length) || useCount >= maxNumberOfUsePerOrder) {
          break;
        }
      }

      discountUseCountMap[discount._id] = useCount;
    }

    return {discountedCartItems: convertFlatArrayOfCartProducts(flatArrayOfCartProducts), discountMap: discountUseCountMap};
  } else {
  }
};

const convertFlatArrayOfCartProducts = (flatArrayOfCartProducts) => {
  const arrayToReturn = [];

  for (const item of flatArrayOfCartProducts) {
    const possibleItemIndex = arrayToReturn.findIndex((innerItem) => innerItem.item && innerItem.item._id === item._id && innerItem.item.selectedSize === item.selectedSize);

    if (possibleItemIndex > -1) {
      const preExistingItem = arrayToReturn[possibleItemIndex];
      preExistingItem.quantity = preExistingItem.quantity + 1 ;
      if (item.discountApplied) {
        preExistingItem.discountData[Object.keys(preExistingItem.discountData).length] = {
          discountApplied: item.discountApplied,
          discountAmountApplied: item.discountAmountApplied,
        };
      }
    } else {
      const discountObj = {
        0: {
          discountApplied: item.discountApplied,
          discountAmountApplied: item.discountAmountApplied,
        },
      };
      delete item.discountAmountApplied;
      delete item.discountApplied;
      const itemToPush = _.cloneDeep(item);
      const cartItemToPush = { item: itemToPush, quantity: 1 };
      if (discountObj[0]["discountApplied"] && discountObj[0]["discountAmountApplied"]) {
        cartItemToPush.discountData = discountObj;
      }

      arrayToReturn.push(cartItemToPush);
    }
  }

  return arrayToReturn;
};
const getDiscountAmountToApply = (type, value, product) => {
  if (type === "amount") {
    return new Decimal(value);
  } else {
    return new Decimal(value).dividedBy(new Decimal("100")).times(new Decimal(product.price));
  }
};

const getStoreDiscounts = async (storeId) => {
  const discountsData = await axios({
    url: "https://kripson-store-server-8qq76.ondigitalocean.app/discounts/getDiscounts",
    method: "POST",
    data: {
      storeId: storeId,
    },
  });
  return discountsData;
};
