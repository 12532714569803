import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Card, CardContent, Divider, CardHeader, Grid, Typography, makeStyles } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import * as FA from "react-fontawesome";
import { FetchStoreProductsStart, SetStoreInfo } from "src/redux/current-store/current-store-actions";
import { SetCustomerInfo } from "src/redux/current-customer/current-customer-actions";
import { SetErrors } from "src/redux/app/app-actions";
import { AddItem } from "src/redux/cart/cart.actions";
import { connect } from "react-redux";
import { getFormattedDate } from "../../utils/date-time";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "visible",
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
    transform: "scale(1.25)",
  },
  cardHeader: {
    minHeight: "150px",
    minWidth: "200px",
    borderRadius: theme.spacing(1),
    backgroundSize: "cover",
    backgroundPosition: "center center",
    display: "grid",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    alignContent: "center",
    justifyContent: "center",
    "& h2": {
      color: theme.palette.primary.contrastText,
      marginBottom: "0"
    },
  },
  bubblesContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    position: "absolute",
    top: "135px",
  },
  bubbles: {
    position: "relative",
    height: "30px",
    width: "30px",
    borderRadius: "30px",
    backgroundColor: theme.palette.background.dark,
  },
  codeContainer:{
      padding:theme.spacing(1),
      backgroundColor: ['#fff', '#FFF', '#FFFFFF', '#ffffff'].includes(theme.palette.primary.contrastText)? 'rgba(0,0,0,0.4)' : 'rgba(255,255,255,0.4)',
      maxWidth: 'max-content',
  }
}));

const DiscountCard = ({ className, discount, storeInfo, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <div className={classes.bubblesContainer}>
        <div className={classes.bubbles} style={{ marginLeft: "-15px" }}></div>
        <div className={classes.bubbles} style={{ marginRight: "-15px" }}></div>
      </div>
      <div className={classes.cardHeader} style={discount.banner && discount.banner.length && discount.banner[0].url ? { backgroundImage: `url(${discount.banner[0].url})` } : {}}>
        <Box className={classes.codeContainer} display="flex" alignItems="center">
          <Typography align="center" color="textPrimary" gutterBottom variant="h2">
            {discount.code}
          </Typography>
        </Box>
      </div>
      <CardContent>
        <Typography align="center" gutterBottom variant="h4">
          Get {discount.type === 'amount' ? storeInfo.storeCurrencySymbol : ''} {discount.value} {discount.type === "percentage" ? "%" : ''} off of {discount.appliesTo === "order" ? "entire order" : `selected ${discount.appliesTo}`}
        </Typography>
        <Typography align="center" gutterBottom variant="h6" color="textSecondary">
          Terms and conditions apply*
        </Typography>
        {/*<Typography*/}
        {/*  align="center"*/}
        {/*  color="textSecondary"*/}
        {/*  variant="body2"*/}
        {/*>*/}
        {/*  {product.description}*/}
        {/*</Typography>*/}
      </CardContent>
      <Box flexGrow={1} />
      <Divider />
      <Box p={2}>
        <Typography variant="body2">Valid Until:</Typography>
        <Typography variant="h6">{getFormattedDate(discount.endDate)}</Typography>
      </Box>
    </Card>
  );
};

DiscountCard.propTypes = {
  className: PropTypes.string,
  store: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  storeInfo: state.currentStore.storeInfo,
  currentCustomer: state.currentCustomer.customerInfo,
  categories: state.currentStore.categories,
  storesCart: state.cart.stores,
});

const mapDispatchToProps = (dispatch) => ({
  FetchStoreProductsStart: (requiredInfo) => dispatch(FetchStoreProductsStart(requiredInfo)),
  setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo)),
  setStoreInfo: (storeInfo) => dispatch(SetStoreInfo(storeInfo)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
  AddItem: (item, storeId) => dispatch(AddItem(item, storeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscountCard);
