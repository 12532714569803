const INITIAL_STATE = {
  storeInfo: {
    ownerFirstName: '',
    ownerLastName: '',
    storeEmail: '',
    storeName: '',
    storeAddress: '',
    colors: { primary: '#000000' }
  },
  currencyPreference: '',
  currencyExchangeRate: 0,
  currencyExchangeRateExpiry: 0,
  fetchingCurrencyRate: false,
  categories: [],
  integrations: [],
  products: []
}

// eslint-disable-next-line import/prefer-default-export
export const currentStoreReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case 'SET_STORE_INFO':
      return {
        ...state,
        storeInfo: {
          ...action.payload
        },
        currencyPreference: action.payload.storeCurrency
      };
    case 'RESET_STORE_INFO':
      return {
        ...state,
        storeInfo: {
          ownerFirstName: '',
          ownerLastName: '',
          storeEmail: '',
          storeName: '',
          storeAddress: '',
          colors: { primary: '#000000' }
        }
      };

    case 'SET_CURRENCY_PREFERENCE':
      return {
        ...state,
        currencyPreference: action.payload,
        currencyExchangeRate: 0,
        currencyExchangeRateExpiry: 0,
      };
    case 'FETCH_CURRENCY_EXCHANGE_RATE':
      return {
        ...state,
        fetchingCurrencyRate: true
      };
    case 'SET_CURRENCY_RATE':
      return {
        ...state,
        currencyExchangeRate: action.payload.rate,
        currencyExchangeRateExpiry: action.payload.expiry,
        fetchingCurrencyRate: false
      };
    case 'FETCH_STORE_CATEGORIES_SUCCESS':
      return {
        ...state,
        categories: [...action.payload]
      };
    case 'FETCH_STORE_CATEGORIES_ERROR':
      return {
        ...state,
        categories: []
      };
    case 'FETCH_STORE_INTEGRATIONS_SUCCESS':
      return {
        ...state,
        integrations: [...action.payload]
      };
    case 'FETCH_STORE_INTEGRATIONS_ERROR':
      return {
        ...state,
        integrations: []
      };
    case 'FETCH_STORE_PRODUCTS_SUCCESS':
      return {
        ...state,
        products: [...action.payload]
      };
    case 'FETCH_STORE_PRODUCTS_ERROR':
      return {
        ...state,
        products: []
      };
    default:
      return state;
  }
}
