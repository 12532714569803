import React, { useEffect, useState } from "react";
import { Box, Container, Grid, makeStyles, Avatar, Chip, useMediaQuery, Card, Button, Typography } from "@material-ui/core";
import { Pagination, Skeleton } from "@material-ui/lab";
import Page from "src/components/Page";
// import Toolbar from './Toolbar';
import { FetchStoreDiscountsStart, SetStoreInfo } from "../../redux/current-store/current-store-actions";
import { SetErrors, SetSnackNotice } from "../../redux/app/app-actions";
import { connect } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import DiscountCard from "src/components/discount-card/discount-card";
import NoResults from "../custom/no-results";
// import CustomNoRowsOverlay from 'src/components/custom-no-rows/custom-no-rows';
import { GiPartyPopper } from "react-icons/gi";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  discountCard: {
    height: "100%",
  },
  container: {
    backgroundColor: "white",
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: "white",
  },
}));

const DiscountList = ({ setSnackNotice, setStoreInfo, setErrors, storeInfo, storeId, categories }) => {
  const classes = useStyles();
  const location = useLocation();
  const [discounts, setDiscounts] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [searchFilters, setSearchFilters] = useState({});
  const [mode, setMode] = useState("card");
  const [fetchingDiscounts, setFetchingDiscounts] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [discountToEdit, setDiscountToEdit] = useState(undefined);
  const [totalDiscounts, setTotalDiscounts] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (totalDiscounts && page >= Math.ceil(totalDiscounts / rowsPerPage)) {
      setPage(Math.ceil(totalDiscounts / rowsPerPage) - 1);
    }
  }, [totalDiscounts]);

  useEffect(() => {
    if (setDiscountToEdit) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [setDiscountToEdit]);

  useEffect(() => {
    if (storeInfo && storeInfo._id) {
      setColumns([
        {
          field: "code",
          headerName: "Code",
          width: 150,
        },
        { field: "type", headerName: "type", width: 150 },
        {
          field: "value",
          headerName: "value",
          width: 100,
        },
        {
          field: "expired",
          headerName: "status",
          width: 150,
          renderCell: (params) => {
            return <Chip className={params.value === true ? classes.warning : classes.success} label={params.value === true ? "expired" : "active"}></Chip>;
          },
        },
        {
          field: "",
          headerName: "",
          width: 150,
          renderCell: (params) => {
            console.log(params);
            return (
              <Button className={classes.warning} variant="contained" onClick={(e) => setDiscountToEdit(discounts.find((discount) => discount._id === params.id))}>
                Edit
              </Button>
            );
          },
        },
      ]);
    }
  }, [storeInfo, discounts]);

  let { category } = useParams();
  const navigate = useNavigate();

  const handleSearchFilterChange = (filter) => {
    setSearchFilters({
      ...filter,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchDiscounts();
  }, []);

  const fetchDiscounts = () => {
    const filter = { endDate: { $gte: Date.now() } };
    setFetchingDiscounts(true);
    axios({
      url: "https://kripson-store-server-8qq76.ondigitalocean.app/discounts/getDiscounts",
      method: "POST",
      onUploadProgress: (progressEvent) => console.log(progressEvent),
      data: {
        sessionId: storeInfo.sessionId,
        storeId: storeId,
        filter: filter,
      },
    }).then((response) => {
      if (response.data.status === 1) {
        setTotalDiscounts(response.data.totals);
        if (response.data.result.length === 0) {
          setPage(0);
        }
        setDiscounts([...response.data.result]);
      } else if (response.data.status === 3) {
        setStoreInfo({
          ownerFirstName: '',
          ownerLastName: '',
          storeEmail: '',
          storeName: '',
          storeAddress: '',
          colors: { primary: '#000000' }
        })
        setSnackNotice({
          severity: "warning",
          message: response.data.message,
        });
      } else {
        setErrors(response.data.errors);
      }
      setFetchingDiscounts(false);
    });
  };

  return (
    <Page className={classes.root} title="Discounts">
      <Container maxWidth={false}>
        {/* <Toolbar
          setKeyword={setKeyword}
          setMode={setMode}
          handleSearchFilterChange={handleSearchFilterChange}
        /> */}
        <Box mb={3}>
          <Typography color="textPrimary" variant="h2">
            Discount offers
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            Special store offers
          </Typography>
        </Box>
        {!fetchingDiscounts && discounts.length === 0 ? <NoResults notice="No discount offers currently available." icon={<GiPartyPopper/>} /> : ''}

        {mode === "card" ? (
          <Box mt={3}>
            <Grid container spacing={3}>
              {!fetchingDiscounts &&
                discounts.map((discount) => (
                  <Grid item key={discount.id} lg={4} md={6} xs={12}>
                    <Link to={`/stores/${storeId}/discountOffers`}>
                      <DiscountCard className={classes.discountCard} discount={discount} />
                    </Link>
                  </Grid>
                ))}
              {fetchingDiscounts &&
                [1, 2, 3, 4].map((num) => (
                  <Grid item key={num} lg={4} md={6} xs={12}>
                    <Skeleton component="div" height={350} style={{ transform: "unset" }} />
                  </Grid>
                ))}
            </Grid>

            <Box mt={3} display="flex" justifyContent="center">
              <Pagination
                color="primary"
                count={Math.ceil(totalDiscounts / rowsPerPage) || 1}
                size="small"
                onChange={(e, epage) => {
                  handleChangePage(e, epage - 1);
                }}
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </Box>
        ) : (
          <Box mt={2}>
            {/* <DataGrid
              components={{
                NoRowsOverlay: CustomNoRowsOverlay
              }}
              className={classes.container}
              autoHeight
              rows={discounts.map(discount => ({
                ...discount,
                id: discount._id
              }))}
              columns={columns}
            ></DataGrid> */}
          </Box>
        )}
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  storeInfo: state.currentStore.storeInfo,
  storeId: state.currentStore.storeInfo._id,
  categories: state.currentStore.categories,
});

const mapDispatchToProps = (dispatch) => ({
  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setStoreInfo: (store) => dispatch(SetStoreInfo(store)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscountList);
