import React, { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Avatar, Box, Button, Divider, Drawer, Hidden, List, Typography, makeStyles } from "@material-ui/core";
import NavItem from "./NavItem";
import StyledTreeView from "../../../components/styled-tree-view/StyledTreeView";
import { FetchStoreCategoriesStart, SetStoreInfo } from "../../../redux/current-store/current-store-actions";
import { SetCustomerInfo } from "../../../redux/current-customer/current-customer-actions";
import { connect } from "react-redux";
import axios from "axios";
import { SetErrors } from "src/redux/app/app-actions";


const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
    '& $img':{
      objectFit: 'contain'
    }
  },
}));

const NavBar = ({ onMobileClose, storeInfo, currentCustomer, setCustomerInfo, setSnackNotice, setErrors, openMobile, categories, storeId }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const updateCustomerInfo = (newStoreId) => {
    axios({
      url: "https://kripson-store-server-8qq76.ondigitalocean.app/customers/updateCustomerInfo",
      method: "POST",
      data: {
        sessionId: currentCustomer.sessionId,
        ...currentCustomer,
        memberStores: currentCustomer && currentCustomer.memberStores ? [...currentCustomer.memberStores].concat(newStoreId) : [newStoreId],
      },
    }).then((response) => {
      if (response.data.status === 1) {
        setCustomerInfo(response.data.result);
        addNewMemberToStore(currentCustomer._id);
      } else if (response.data.status === 3) {
        setCustomerInfo({});
        setSnackNotice({
          severity: "warning",
          message: response.data.message,
        });
      } else {
        setErrors(response.data.errors);
      }
    });
  };

  const addNewMemberToStore = (newMemberId) => {
    axios({
      url: "https://kripson-store-server-8qq76.ondigitalocean.app/members/addMember",
      method: "POST",
      data: {
        sessionId: currentCustomer.sessionId,
        storeId: storeInfo._id,
        customerId: newMemberId,
      },
    }).then((response) => {
      if (response.data.status === 1) {
      } else if (response.data.status === 3) {
        setCustomerInfo({});
        setSnackNotice({
          severity: "warning",
          message: response.data.message,
        });
      } else {
        setErrors(response.data.errors);
      }
    });
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      {/* <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar className={classes.avatar} component={RouterLink} src={storeInfo && storeInfo.storeBanner ? storeInfo.storeBanner.url : ""} to={`/stores/${storeInfo._id}/products/`} />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {storeInfo ? storeInfo.storeName : ""}
        </Typography>
        <Box mt={1}>
          {currentCustomer && currentCustomer.memberStores && currentCustomer.isVerified ? (
            currentCustomer.memberStores.includes(storeInfo._id) ? (
              <Typography variant="h3">{`Welcome ${currentCustomer.name.split(" ")[0]}`}</Typography>
            ) : (
              <Button color="primary" variant="contained" onClick={() => updateCustomerInfo(storeInfo._id)}>
                Become a member
              </Button>
            )
          ) : (
            ""
          )}
        </Box>
      </Box>
      <Divider /> */}
      <Box p={2}>
      <NavItem href={"/stores/" + storeId + "/products/"} key={'All'} title={'All'} categoryImage = {''} />
        <List>
          {categories
            ? categories.map((category) => {
              console.log("/stores/" + storeId + "/products/" + category._id);
              return <NavItem href={"/stores/" + storeId + "/products/" + category._id} key={category.categoryName} title={category.categoryName} categoryImage = {category.categoryImage} />;
            })
            : ""}
        </List>
      </Box>
      <Box display="flex" flexGrow={1} p={2} alignItems={'flex-end'}>
        <List style={{ width: '100%' }}>
          <NavItem href={"/stores/" + storeId + "/discountOffers"} key={'discountOffers'} title={"🎉 Discount Offers"} />
          <NavItem href={"/stores/" + storeId + "/refundPolicies"} key={'refundPolicies'} title={"💱 Refund Policy"} />
          <NavItem href={"/stores/" + storeId + "/socials"} key={'socials'} title={"🔗 Socials"} />
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
      <style jsx="true">
        {`
                .slider{
                  --loader-bar-color: ${storeInfo.colors.primary} !important;
                },
              `}
      </style>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false,
};

const mapDispatchToProps = (dispatch) => ({
  setStoreInfo: (storeInfo) => dispatch(SetStoreInfo(storeInfo)),
  setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
});

const mapStateToProps = (state) => ({
  currentCustomer: state.currentCustomer.customerInfo,
  storeInfo: state.currentStore.storeInfo,
  categories: state.currentStore.categories,
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
