import * as yup from 'yup';


const schemaMap = {
    newCustomer:{
        name: yup.string().required(),
        email: yup.string().required(),
        phone: yup.string().required(),
        address: yup.string().required(),
        password: yup.string().required(),
        policy: yup.boolean().oneOf([true]).required()
    },
    updateCustomer: {
        name: yup.string().required(),
        email: yup.string().required(),
        phone: yup.string().required(),
        address: yup.string().required(),
    },
    newStore: {

        ownerFirstName: yup.string().required(),
        ownerLastName: yup.string().required(),
        password:  yup.string().required(),
        policy: yup.boolean().required(),
        storeAddress: yup.string().required(),
        storeName: yup.string().required(),
        brandColor: yup.string().min(7).required(),
        storeEmail: yup.string().email(),
        storeCategory: yup.string().required(),
        storeBanner: yup.object().required().shape({
            type:yup.string().required(),
            base64data: yup.string().required()
        }),
        createdDate: yup.date().default(function () {
            return new Date();
        })
    },
    newStoreCategory: {
        categoryName: yup.string().min(1).max(10).required(),
        storeId: yup.string().min(20).required(),
    },
    removeStoreCategory: {
        categoryId: yup.string().min(20).required(),
        storeId: yup.string().min(20).required(),
    },
    getStoreCategories: {
        storeId: yup.string().min(20).required(),
    },
    getStoreProducts: {
      storeId: yup.string().min(20).required(),
    },
    getStoreProductById: {
        storeId: yup.string().min(20).required(),
        productId:yup.string().min(20).required()
    },
    getStoreOrders: {
        storeId: yup.string().min(20).required(),
    },
    getStoreOrderById: {
        storeId: yup.string().min(20).required(),
        orderId:yup.string().min(20).required()
    },
    removeProduct:{
        storeId: yup.string().min(20).required(),
        productId:yup.string().min(20).required()
    },
    forgotPassword:{
        email:yup.string().email().required('Please supply a valid email address'),
    },
    newProduct: {
    
        price: yup.number().min(1).required('Price is required'),
        sizes: yup.array().min(1).required('size is required'),
        color:yup.string().required('Color is required'),
        stockQuantity: yup.object().required('stockQuantity is required'),
        status:  yup.string().required('Status is required'),
        costPrice: yup.number().min(1).required('Cost price is required'),
        title: yup.string().required('Title is required'),
        description: yup.string().required('Description is required'),
        images:yup.array().min(1).required('At least one product image is required')
    },
    updateProduct: {
   
        price: yup.number().min(1).required('Price is required'),
        sizes: yup.array().min(1).required('size is required'),
        color:yup.string().required('Color is required'),
        stockQuantity: yup.object().required('stockQuantity is required'),
        status:  yup.string().required('Status is required'),
        costPrice: yup.number().min(1).required('Cost price is required'),
        title: yup.string().required('Title is required'),
        description: yup.string().required('Description is required'),
        images:yup.array().min(1).required('At least one product image is required')
    },
      removeOrder:{
        storeId: yup.string().min(20).required(),
        orderId:yup.string().min(20).required()
    },
    newOrder: {
        products:yup.array().min(1,'At least one product should be ordered').required('Empty cart!'),
        storeId: yup.string().min(20).required('Store Id is required'),
        customer: yup.object().required().shape({
            name:yup.string().required('name is required'),
            address: yup.string().required('address is required'),
            phonenumber: yup.string().required('phonenumber is required'),
            email: yup.string().required('email is required'),
        }),
    },
    updateOrder: {
        storeId: yup.string().min(20).required('Store Id is required')
    },
    newProductReview: {
        // userId: yup.string().min(20).required(),
        stars: yup.number().required(),
        comment: yup.string(),
    },
    editProductReview:{
        _id: yup.string().min(20).required(),
        userId: yup.string().min(20).required(),
        stars: yup.number().required(),
        comment: yup.string(),
        productId:yup.string().min(20).required()
    },
    newStoreReview: {
        userId: yup.string().min(20).required(),
        responsivestars: yup.number().required(),
        qualitystars: yup.number().required(),
        shippingstars: yup.number().required(), 
        comment: yup.string(),
    }
}



export const validateData = async (schemaName, data)=>{
    let schema = yup.object().shape(schemaMap[schemaName]);

    try{
        const validObj =  await schema.validate(data,{abortEarly:false});
        return {validity: true, validObj:validObj,errors:[]};
    }
    catch (e) {
        console.log(e);
        return {validity:false, errorMap:e.inner, errors:e.errors};
    }



}