import {takeLatest,call,put} from 'redux-saga/effects';
import axios from 'axios';
import {
  FetchStoreCategoriesError,
  FetchStoreCategoriesSuccess, FetchStoreIntegrationsError, FetchStoreIntegrationsSuccess, FetchStoreProductsError,
  FetchStoreProductsSuccess,
  SetCurrencyRate
} from "./current-store-actions";




export function* fetchStoreCategoriesStart()
{
    yield takeLatest('FETCH_STORE_CATEGORIES_START', fetchStoreCategoriesStartAsync);
}


export function* fetchStoreIntegrationsStart()
{
    yield takeLatest('FETCH_STORE_INTEGRATIONS_START', fetchStoreIntegrationsStartAsync);
}

export function* fetchCurrencyExchangeRate()
{
    yield takeLatest('FETCH_CURRENCY_EXCHANGE_RATE', fetchCurrencyExchangeRateStartAsync);
}

export function* fetchStoreProductsStart()
{
  yield takeLatest('FETCH_STORE_PRODUCTS_START', fetchStoreProductsStartAsync);
}

export function* fetchCurrencyExchangeRateStartAsync(action)
{

    try
    {

        const rate = yield fetchExchangeRate(action.payload);
     
        yield put(SetCurrencyRate(rate));
    }
    catch(e)
    {
      yield put(SetCurrencyRate({rate: 0, expiry: 0}));
    }
}


export function* fetchStoreIntegrationsStartAsync(action)
{

    try
    {

        const integrations = yield fetchStoreIntegrations(action.payload);
      
        yield put(FetchStoreIntegrationsSuccess(integrations));
    }
    catch(e)
    {
      yield put(FetchStoreIntegrationsError());
    }
}

export function* fetchStoreCategoriesStartAsync(action)
{

    try
    {
        const categories = yield fetchStoreCategories(action.payload);

        yield put(FetchStoreCategoriesSuccess(categories));
    }
    catch(e)
    {
        yield put(FetchStoreCategoriesError(e.message));
    }
}

export function* fetchStoreProductsStartAsync(action)
{

  try
  {
    const categories = yield fetchStoreProducts(action.payload);

    yield put(FetchStoreProductsSuccess(categories));
  }
  catch(e)
  {
    yield put(FetchStoreProductsError(e.message));
  }
}


const fetchExchangeRate = async ({currencyToConvertFrom, currencyToConvertTo, storeId})=>
{
    console.log(currencyToConvertFrom, currencyToConvertTo, storeId);  
    const response = await axios({
        url:'https://kripson-store-server-8qq76.ondigitalocean.app/exchangeRates/getExchangeRate',
        method:'POST',
        data:{currencyToConvertFrom, currencyToConvertTo, storeId},
    });

    if(response.data.status === 1)
    {
        return(response.data.result);
    }
    else
    {
        return ({rate: 0, expiry: 0});
    }

};

const fetchStoreCategories = async (storeId)=>
{
    console.log(storeId);
    const response = await axios({
        url:'https://kripson-store-server-8qq76.ondigitalocean.app/stores/getCategories',
        method:'POST',
        data:{storeId:storeId}
    });
    if(response.data.status === 1)
    {
        return(response.data.result);
    }
    else
    {
        return ([]);
    }

};

const fetchStoreIntegrations = async (storeId)=>
{
    console.log(storeId);
    const response = await axios({
        url:'https://kripson-store-server-8qq76.ondigitalocean.app/stores/getIntegrations',
        method:'POST',
        data:{storeId:storeId}
    });
    if(response.data.status === 1)
    {
        return(response.data.result);
    }
    else
    {
        return ([]);
    }

};


const fetchStoreProducts = async (requiredInfo)=>
{

  const response = await axios({
    url:'https://kripson-store-server-8qq76.ondigitalocean.app/products/getProducts',
    method:'POST',
    data: {...requiredInfo}
  });

  if(response.data.status === 1)
  {
    return(response.data.result);
  }
  else
  {
    return ([]);
  }

};




