import React from 'react';
import {
    Box,
    Container,
    Typography,
    makeStyles,
    Card,
    Grow,
    fade,
    colors
} from '@material-ui/core';
import Page from 'src/components/Page';
import { connect } from "react-redux";
import parse from 'html-react-parser';
import ImageExtensions from 'src/image-extension';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 560
    },
    socialLink: {
        padding: theme.spacing(2),
        marginRight: theme.spacing(1),
        width: 'fit-content',
        height: 'auto',
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        gridRowGap: theme.spacing(1),
        flexWrap: 'wrap',

        '&:hover': {
            cursor: 'pointer',
            backgroundColor: fade(colors.common['black'], 0.1)
        },
        '& img': {
            width: '120px',
            height: 'auto',

        }
    }
}));

const Socials = ({ storeInfo }) => {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title="Confirmed"
        >
            <Container maxWidth="lg">
                <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                        Follow us on
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2">
                        Get in touch
                    </Typography>
                </Box>
                <Box display="flex" flexWrap="wrap">
                    {['facebook', 'instagram', 'youtube'].filter((social) => storeInfo && storeInfo.socials && storeInfo.socials.hasOwnProperty(social)).map((social, idx) => <Grow
                        in={true}
                        key={idx}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(true ? { timeout: 1000 } : {})}
                    >
                        <a href={storeInfo.socials[social]} target="_blank" rel="noreferrer">
                            <Card className={classes.socialLink}>
                                <img key={idx} src={`/static/images/${social}_logo${ImageExtensions[social]}`} alt={social} />
                                <Typography variant='h6' color="textSecondary">
                                    {social}
                                </Typography>
                            </Card>
                        </a>
                    </Grow>)}
                </Box>

            </Container>
        </Page >
    );
};

const mapStateToProps = (state) => ({
    storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Socials);
