import React, { useState } from "react";
import { Box, TextField, makeStyles, InputAdornment, SvgIcon, colors, Button, useMediaQuery } from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import { connect } from "react-redux";
import { FetchStoresStart } from "../../redux/app/app-actions";
import { useNavigate } from "react-router-dom";
import Tilt from "react-tilt";
import Marquee from "react-fast-marquee";
import "./home-search-section.scss";

const HomeSearchSection = ({ FetchStoresStart, updateKeyword }) => {
  
  const isSmallScreen = useMediaQuery("(max-width:1050px)");
  
  const useStyles = makeStyles((theme) => ({
    root: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      width: "100vw",
      marginTop: isSmallScreen ? theme.spacing(1) : 0,
      padding: isSmallScreen ? theme.spacing(1) : 0
    },
    backgroundBox: {
      position: "absolute",
      width: "100vw",
      height: isSmallScreen ? "15vh" : "40vh",
      overflow: "hidden",
      display: isSmallScreen ? "none" : "flex",
      justifyContent: "space-between",
      alignItems: "center",

      "& img": {
        maxHeight: "375px",
      },
    },
    foregroundBox: {
      width: "100vw",
      display: "flex",
      overflow: "hidden",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#0c0e15",
      height: isSmallScreen ? "15vh" : "40vh",
      padding: theme.spacing(1),
      borderRadius: isSmallScreen ? "10px" : 0,
    
    },
    homeSearchBar: {
      width: "800px",
      maxWidth: "100%",
      height: "60px",
      zIndex: 1,

      "& .MuiInputBase-root": {
        backgroundColor: colors.common.white,
        height: "60px",
        fontSize: "18px",
        borderRadius: "5px 0 0 5px",
      },
    },
    searchButton: {
      height: "60px",
      borderRadius: "0 5px 5px 0",
      zIndex: 1,
      
    },
    backgroundImages: {
      height: "calc(100vh/3)",
    },
  }));

  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const images = ["iphone", "headphone", "bagpack", "jordans", "smartwatch"];
  const classes = useStyles();


  const search = () => {
    FetchStoresStart(keyword ? { storeCategory: { $regex: keyword, $options: "ix" } } : {});
    updateKeyword(keyword);
  };

  return (
    <Box
      className={classes.root}
      onKeyDown={(e) => {
        const t = e.key === "Enter" ? search(e) : "";
      }}
    >
      <Box className={classes.backgroundBox}>
        <Marquee className={classes.ticker} pauseOnHover={true} gradient={isSmallScreen ? false : true} gradientColor={[12, 14, 21]} speed={40}>
          {images && images.length
            ? images.map((image, index) => (
                <Tilt className={` Tilt`} key={index} options={{ max: 25 }} style={{ maxWidth: 450 }}>
                  <img src={`/static/images/${images[index]}.png`} alt="" className={`Tilt-inner ${classes.backgroundImages}`} />
                </Tilt>
              ))
            : ""}
        </Marquee>
      </Box>
      <Box className={classes.foregroundBox}>
        <TextField
          className={classes.homeSearchBar}
          fullWidth
          variant="outlined"
          helperText=""
          placeholder="What are you looking for?"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Button variant="contained" color="primary" className={classes.searchButton} onClick={search}>
          Search
        </Button>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  stores: state.app.stores,
});

const mapDispatchToProps = (dispatch) => ({
  FetchStoresStart: (filter) => dispatch(FetchStoresStart(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeSearchSection);
