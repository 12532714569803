import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  CardHeader,
  Chip,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button
} from '@material-ui/core';
import * as FA from 'react-fontawesome';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import {
  FetchStoreordersStart
} from 'src/redux/current-store/current-store-actions';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import momentTimeZone from 'moment-timezone';
import './refund-item-card.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SendIcon from '@material-ui/icons/Send';
import Decimal from 'decimal.js-light';

function RefundItemCard({
  storeId,
  storeInfo,
  product,
  productImage,
  setSnackNotice,
  value,
  handleChange,
  handleReasonChange,
  reason,
  ...rest
}) {
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      maxWidth: '150px'
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: 'white'
    },
    strong: {
      fontWeight: '450'
    }
  }));

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getOrderStatusIcon = status => {
    switch (status) {
      case 'processing':
        return <CachedIcon />;
      case 'cancelled':
      case 'refunded':
        return <ClearIcon />;
      case 'delivered':
        return <CheckIcon />;
      default:
        return;
    }
  };

  const getOrderStatusClass = status => {
    switch (status) {
      case 'processing':
        return classes.warning;
      case 'cancelled':
      case 'refunded':
        return classes.error;
      case 'delivered':
        return classes.success;
      default:
        return;
    }
  };

  return (
    <Card className={`${classes.root} orderProductCard`} {...rest}>
      <div
        className="productImageSection"
        style={{ backgroundImage: `url(${productImage})` }}
      ></div>
      <div className="productDetailSection">
        <Typography variant="h5">{product.title}</Typography>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong className={classes.strong}>Qty :</strong>{' '}
              {product.quantity}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong className={classes.strong}>Size :</strong>{' '}
              {product.selectedSize}
            </Typography>
          </Grid>
        </Grid>
        {product.refundedQuantity ? (
          <Typography variant="body2">
            <strong className={classes.strong}>
              # of items refunded already :
            </strong>{' '}
            {product.refundedQuantity}
          </Typography>
        ) : (
          ''
        )}

        <Typography variant="body2">
          {/* <strong className={classes.strong}>Total amount refunded : </strong>
          {`${storeInfo.storeCurrencySymbol} `}
          {updatedProduct && updatedProduct.refundedQuantity
            ? updatedProduct.refundedQuantity * product.price
            : ''} */}
        </Typography>
        {(product.hasOwnProperty('refundedQuantity') ? (
          product.refundedQuantity !== product.quantity
        ) : (
          true
        )) ? (
          <>
            <TextField
              label="Quantity to refund"
              margin="normal"
              fullWidth={true}
              name={
                product._id + product.selectedSize
              }
              onChange={handleChange}
              value={value}
              type="number"
              variant="outlined"
              InputProps={{
                inputProps: {
                  min: 0,
                  max: product.quantity - (product.refundedQuantity || 0)
                }
              }}
            />

            <TextField
              label="Reason for refund"
              margin="normal"
              fullWidth={true}
              name={
                product._id + product.selectedSize
              }
              onChange={handleReasonChange}
              value={reason}
              type="text"
              variant="outlined"
            />

          </>
        )

          : (
            <Chip className={classes.error} label={'Fully Refunded'} />
          )}
      </div>
    </Card>
  );
}

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
  notifications: state.currentStore.notifications
});

const mapDispatchToProps = dispatch => ({
  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundItemCard);
