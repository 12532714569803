import React, { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Avatar, Box, Button, Divider, Drawer, Hidden, List, Typography, makeStyles, ListItem, useTheme } from "@material-ui/core";
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
} from "react-feather";
import NavItem from "./NavItem";
import StyledTreeView from "../../../components/styled-tree-view/StyledTreeView";
import { FetchStoreCategoriesStart, SetStoreInfo } from "../../../redux/current-store/current-store-actions";
import { SetCustomerInfo } from "../../../redux/current-customer/current-customer-actions";
import { connect } from "react-redux";
import axios from "axios";
import { SetErrors, SetSnackNotice } from "src/redux/app/app-actions";

import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HistoryIcon from '@material-ui/icons/History';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  button: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.primary.main,
    },
    justifyContent: "space-between",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "100%",
    "& .MuiButton-label":{
      display: 'flex',
      justifyContent: 'flex-start'
    }
  },
}));

const NavBar = ({ onMobileClose, storeInfo, currentCustomer, setCustomerInfo, setSnackNotice, setErrors, openMobile, categories, storeId }) => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);


  const logout = async () => {
    const response = await axios({
      url: 'https://kripson-store-server-8qq76.ondigitalocean.app/customers/logout',
      method: 'POST',
      data: {
        sessionId: currentCustomer.sessionId,
      }
    });

    if (response.data.status === 1 || response.data.status === 3) {

      setCustomerInfo({});
      setSnackNotice({
        severity: 'success',
        message: 'Logged out successfully',
      });
    }
    else {
      setErrors(response.data.errors)
    }
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Typography variant="h4">Kripson Store</Typography>
      </Box>
      <Divider />
      <Box p={2}>
        
        <List>
          {currentCustomer && currentCustomer._id ?
            <>
              <NavItem icon={<PersonIcon/>} href={"/account"} key={storeId} title={"Account"} />
              <ListItem disableGutters style={{ padding: 0 }}>
{/* 
                <a target="_blank" rel="noreferrer" href="https://dashboard.kripsonstore.com/register">
                  <Button className={classes.button}>
                    {`🛍️ Register Your Store`}
                  </Button>
                </a> */}
              </ListItem>
              <NavItem icon={<HistoryIcon/>} href={"/account/orderHistory"} key={storeId} title={"Order History"} />
              <NavItem icon={<AttachMoneyIcon/>} href={"/account/refundRequests"} key={storeId} title={"Refund Requests"} />
              <ListItem disableGutters style={{ padding: 0 }}>


                <Button startIcon={<ExitToAppIcon/>} className={classes.button} onClick={() => logout()}>
                  {`Log out`}
                </Button>

              </ListItem>
            </>
            : <>
              <NavItem icon={<LockOpenIcon/>} href={"/login"} key={storeId} title={"Login/Sign up"} />
              {/* <a target="_blank" rel="noreferrer" href="https://dashboard.kripsonstore.com/register">
                <Button className={classes.button}>
                  {`🛍️ Register Your Store`}
                </Button>
              </a> */}
            </>
          }






        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <style jsx>
        {`
                .slider{
                  --loader-bar-color: ${storeInfo.colors.primary} !important;
                },
              `}
      </style>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false,
};

const mapDispatchToProps = (dispatch) => ({
  setStoreInfo: (storeInfo) => dispatch(SetStoreInfo(storeInfo)),
  setCustomerInfo: (customerInfo) => dispatch(SetCustomerInfo(customerInfo)),
  setSnackNotice: (notice)=>dispatch(SetSnackNotice(notice)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
});

const mapStateToProps = (state) => ({
  currentCustomer: state.currentCustomer.customerInfo,
  storeInfo: state.currentStore.storeInfo,
  categories: state.currentStore.categories,
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
