import * as PasswordValidator from 'password-validator';

export const validatePassword =  password => {
  const passwordValidator = new PasswordValidator();

  passwordValidator.is().min(8);

  passwordValidator.has().uppercase();

  passwordValidator.has().lowercase();

  passwordValidator.has().digits();

  passwordValidator.has().symbols();

  passwordValidator
    .has()
    .not()
    .spaces();

  return passwordValidator.validate(password, { list: true });
};
