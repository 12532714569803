import React from 'react';
import {
    Box,
    Container,
    Typography,
    makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { connect } from "react-redux";
import parse from 'html-react-parser';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 560
    }
}));

const RefundPolicies = ({ storeInfo }) => {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title="Confirmed"
        >
            <Container maxWidth="lg">
                <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                        Refund Policies
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2">
                        Store store policies
                    </Typography>
                </Box>
                {parse(storeInfo.refundPolicies || '')}
            </Container>
        </Page >
    );
};

const mapStateToProps = (state) => ({
    storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundPolicies);
