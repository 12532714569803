import axios from "axios";
import React, { useEffect, useState } from "react";
import "./product-review-section.scss";
import { connect } from "react-redux";
import { SetErrors, SetSnackNotice } from "src/redux/app/app-actions";
import ProductReviewOverall from "../product-review-overall/product-review-overall";
import { makeStyles } from "@material-ui/styles";
import ProductReviewBreakdown from "../product-review-breakdown/product-review-breakdown";
import { Grid, Hidden, useMediaQuery, Box, Button, Accordion, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProductReviewForm from "../product-review-form/product-review-form";
import ProductReviewCard from "../product-review-card/product-review-card";
import { AddCircle, AddCircleOutlined, CachedOutlined } from "@material-ui/icons";

const ProductReviewSection = ({ storeInfo, currentCustomer, productId, setSnackNotice, setErrors }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    productReviewSection: {
      display: "grid",
      padding: theme.spacing(3),
      gridRowGap: theme.spacing(2),
    },
    ratingBox: {
      display: "flex",
      flexWrap: "wrap",
      maxWidth: "600px",
    },
    reviewBox:{
      display:'grid',
      gridRowGap: theme.spacing(1),
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      maxWidth: "150px",
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: "white",
    },
    info:{
      backgroundColor: theme.palette.info.main,
      color: "white",
    },
    strong: {
      fontWeight: "450",
    },
  }));

  const classes = useStyles();

  const isSmallScreen = useMediaQuery("(max-width:557px)");

  const [reviews, setReviews] = useState([]);
  const [reviewGroupedData, setReviewGroupedData] = useState([]);

  useEffect(() => {
    if (storeInfo._id) {
      getReviews();
      getReviewsGroupedData();
    }
  }, [storeInfo]);

  const getReviews = async () => {
    try {
      const result = await axios({
        url: "https://kripson-store-server-8qq76.ondigitalocean.app/reviews/getProductReviews",
        method: "POST",
        data: { storeId: storeInfo._id, filter: { productId: productId }, limit: 3, skip: reviews.length },
      });

      if (result.data.status === 0) {
        setErrors(result.data.errors);
        return;
      }

      setReviews(reviews.concat(result.data.result));
    } catch (e) {
      setSnackNotice({
        severity: "error",
        message: "Something went wrong, please try again.",
      });
      return;
    }
  };

  const refreshReviews = async () => {
    try {
      const result = await axios({
        url: "https://kripson-store-server-8qq76.ondigitalocean.app/reviews/getProductReviews",
        method: "POST",
        data: { storeId: storeInfo._id, filter: { productId: productId }, limit: reviews.length },
      });

      if (result.data.status === 0) {
        setErrors(result.data.errors);
        return;
      }

      setReviews(result.data.result);
      getReviewsGroupedData();
    } catch (e) {
      setSnackNotice({
        severity: "error",
        message: "Something went wrong, please try again.",
      });
      return;
    }
  };

  const getReviewsGroupedData = async () => {
    try {
      const result = await axios({
        url: "https://kripson-store-server-8qq76.ondigitalocean.app/reviews/getProductReviewsGrouped",
        method: "POST",
        data: { storeId: storeInfo._id, filter: { productId: productId } },
      });

      if (result.data.status === 0) {
        setErrors(result.data.errors);
        return;
      }

      setReviewGroupedData(result.data.result);
    } catch (e) {
      setSnackNotice({
        severity: "error",
        message: "Something went wrong, please try again.",
      });
      return;
    }
  };

  return (
    <div className={classes.productReviewSection}>
      <div className={classes.ratingBox}>
        <ProductReviewOverall style={isSmallScreen ? { marginBottom: "10px", width: "100%" } : { marginRight: "10px" }} totalNumberofReviews={reviewGroupedData.reduce((acc, group)=> acc + group.count, 0)} totalStars={reviewGroupedData.reduce((acc, group)=> acc + group.count * Number(group._id), 0)} />
        <ProductReviewBreakdown style={isSmallScreen ? { marginBottom: "10px" } : { marginRight: "10px" }} reviewGroupedData={reviewGroupedData} />
      </div>
      {currentCustomer && currentCustomer._id ? <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h5">
            Click to leave a review
          </Typography>
        </AccordionSummary>
        <Box p={2} style={{ display: "grid", rowGap: "5px" }}>
          <ProductReviewForm productId={productId} refreshReviews={refreshReviews}/>
        </Box>
      </Accordion>
: ''}
      <Box className={classes.reviewBox}>
        {reviews.map(review=><ProductReviewCard review={review} refreshReviews={refreshReviews}/>)}
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Button variant="contained" color= "primary" startIcon={<AddCircle/>} onClick={getReviews}>Show More</Button>
        <Button variant="contained" startIcon={<CachedOutlined/>} className={classes.info} onClick={refreshReviews}>Refresh</Button>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  storeInfo: state.currentStore.storeInfo,
  currentCustomer: state.currentCustomer.customerInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setSnackNotice: (notice) => dispatch(SetSnackNotice(notice)),
  setErrors: (errors) => dispatch(SetErrors(errors)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductReviewSection);
