import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  CardHeader,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import * as FA from 'react-fontawesome';
import { SetErrors, SetSnackNotice } from 'src/redux/app/app-actions';
import {
  FetchStoreNotificationsStart,
  FetchStoreordersStart
} from 'src/redux/current-store/current-store-actions';
import CachedIcon from '@material-ui/icons/Cached';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import momentTimeZone from 'moment-timezone';
// import './order-product-card.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SendIcon from '@material-ui/icons/Send';
import Decimal from 'decimal.js-light';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';

function PasswordRuleBox({passwordErrors, ...rest }) {
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: 'white'
    },
    paymentForm:{
        padding: theme.spacing(2)
    },
    errorRule: {
        backgroundColor: theme.palette.error.main
      },
      errorRuleIcon: {
        color: 'black'
      },
      errorRuleLabel: {
        color: 'black'
      },
      successRuleIcon: {
        color: 'white'
      },
      successRuleLabel: {
        color: 'white'
      },
      successRule: {
        backgroundColor: theme.palette.success.main
      },
      passwordRuleBox: {
        padding: theme.spacing(2),
        position: 'fixed',
        bottom: '15px',
        backgroundColor: 'white',
        zIndex: 1000
      },
      attachedPasswordRuleBox: {
        display: 'flex',
        flexWrap: 'wrap'
        // '& span':{
        //   marginTop:'2px',
        //   fontSize: '11px',
        //   color: '#333333',
        //   textAlign: 'justify'
        // }
      },
      passwordRule: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        columnGap: '10px',
        alignItems: 'start',
        margin: '4px 0'
      }
  }));

  const classes = useStyles();
  
  const errorKeys = [
    'min',
    'uppercase',
    'lowercase',
    'digits',
    'symbols',
    'spaces'
  ];
  const errorKeyMessageMap = {
    min: `Eight charactors long`,
    uppercase: `One uppercase character`,
    lowercase: `One lowercase character`,
    digits: `One number`,
    symbols: `One special character (!$%#@ etc)`,
    spaces: `No spaces`
  };

  return (
    <div className={`${classes.attachedPasswordRuleBox}`}>
    {passwordErrors
      ? Object.keys(passwordErrors).map(errorKey => {
          if (passwordErrors[errorKey]) {
            return (
              <div className={classes.passwordRule}>
                <Chip
                  className={classes.errorRule}
                  classes={{
                    icon: classes.errorRuleIcon,
                    label: classes.errorRuleLabel
                  }}
                  color="default"
                  icon={<SentimentVeryDissatisfiedIcon />}
                  label={errorKeyMessageMap[errorKey]}
                />
              </div>
            );
          } else {
            return (
              <div className={classes.passwordRule}>
                <Chip
                  className={classes.successRule}
                  classes={{
                    icon: classes.successRuleIcon,
                    label: classes.successRuleLabel
                  }}
                  icon={<InsertEmoticonIcon />}
                  label={errorKeyMessageMap[errorKey]}
                />
              </div>
            );
          }
        })
      : ''}
  </div>
  );
}

const mapStateToProps = state => ({
  storeId: state.currentStore.storeInfo._id,
  storeInfo: state.currentStore.storeInfo,
});

const mapDispatchToProps = dispatch => ({

  setSnackNotice: notice => dispatch(SetSnackNotice(notice)),
  setErrors: errors => dispatch(SetErrors(errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRuleBox);
