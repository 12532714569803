import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  makeStyles,
  Hidden
} from '@material-ui/core';
import Logo from 'src/components/Logo/Logo';
import { Box } from "@material-ui/core";
import { connect } from 'react-redux';
import AccountMenu from 'src/components/account-menu/account-menu';
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles(({
  root: {},
  toolbar: {
    height: 64
  },
  rightLinksContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    '&>a': {
      fontSize: '14px',
      color: 'white',
      marginLeft: '20px',
      textAlign: 'center'
    },
    '&>div': {
      fontSize: '14px',
      color: 'white',
      width: '40px',
      marginLeft: '20px',
    }
  }
}));

const TopBar = ({ className, currentCustomer, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box className={classes.rightLinksContainer}>
          <Hidden mdDown>
            {/* <a target="_blank" rel="noreferrer" href="https://dashboard.kripsonstore.com/register">
              Register Your Store
            </a> */}
            {currentCustomer && currentCustomer._id ?
              <AccountMenu />
              : <RouterLink to="/login">
                Login/Sign up
              </RouterLink>
            }
          </Hidden>


          <Hidden lgUp>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Box>

      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = dispatch => (
  {

  });
const mapStateToProps = state =>
({

  currentCustomer: state.currentCustomer.customerInfo,
});


export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
