export const SetSnackNotice = (notice) => ({
  type: 'SET_SNACK_NOTICE',
  payload: notice
})


export const SetErrors = (errors)=>({
  type: 'SET_ERRORS',
  payload: errors
})


export const FetchStoresStart = (filter)=>({
  type: 'FETCH_STORES_START',
  payload: filter
})

export const FetchStoresSuccess = (stores)=>({
  type: 'FETCH_STORES_SUCCESS',
  payload: stores
})

export const FetchStoresError = (e)=>({
  type: 'FETCH_STORES_ERROR',
  payload: e
})
