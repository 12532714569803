import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Card } from "@material-ui/core";
import "./checkout-detail-form.scss";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import usePlacesAutocomplete from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import "@reach/combobox/styles.css";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
  detailsForm: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(2),
  },
}));
const CheckoutDetailForm = ({ setCustomer, orderCustomer, currentCustomer }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    address: "",
    email: "",
    phonenumber: "",
  });

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete();

  const handleInput = (e) => {
    setValue(e.target.value);
    setValues({ ...values, address: e.target.value });
  };

  const handleSelect = (e, value) => {
    setValue(value.description);
    setValues({ ...values, address: value.description });
  };

  useEffect(() => {
    setOptions(data.map(({ place_id, description }) => ({ place_id, description })));
  }, [data]);

  useEffect(() => {
    console.log(currentCustomer);
    if (values && orderCustomer && orderCustomer._id) {
      setValues({
        name: orderCustomer.name ?? currentCustomer.name,
        address: orderCustomer.address ?? currentCustomer.address,
        email: orderCustomer.email ?? currentCustomer.email,
        phonenumber: orderCustomer.phone ?? currentCustomer.phone,
        _id: orderCustomer && orderCustomer._id ? orderCustomer._id : (currentCustomer && currentCustomer._id ? currentCustomer._id : null),
      });
    } else if (values && currentCustomer.name && currentCustomer._id) {
      setValues({
        name: currentCustomer.name,
        address: currentCustomer.address,
        email: currentCustomer.email,
        phonenumber: currentCustomer.phone,
        _id: currentCustomer && currentCustomer._id ? currentCustomer._id : null,
      });
    }
  }, [currentCustomer]);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    console.log(values);
    setCustomer({ ...values });
  }, [values]);

  return (
    <Card className={`checkout-detail-form ${classes.detailsForm}`}>
      <Typography variant="h3">Please fill in your details and confirm the order</Typography>
      <TextField id="name" label="name" onChange={handleChange} variant="outlined" className="form-control" value={values.name} required />

      <div className="MuiFormControl-root MuiTextField-root form-control">
        {/* <Combobox className={"MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl"} onSelect={handleSelect} aria-labelledby="demo">
          <ComboboxInput
            className={"MuiInputBase-input MuiOutlinedInput-input MuiFormLabel-root"}
            style={{ height: "1.1876em", padding: "18.5px 14px", border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "4px" }}
            value={value}
            onChange={handleInput}
            placeholder="shipping Address *"
            disabled={!ready}
          />
          <ComboboxPopover>
            <ComboboxList
              style={{
                backgroundColor: "white",
                zIndex: 1001,
                position: "relative",
              }}
            >
              {status === "OK" && renderSuggestions()}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox> */}

        <Autocomplete
          id="asynchronous-demo"
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          fullWidth
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option.description === value.description}
          getOptionLabel={(option) => option.description}
          options={options}
          loading={open && ready}
          onChange={handleSelect}
          inputValue={values.address}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Address"
              onChange={handleInput}
              fullWidth
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {open && ready ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              required
            />
          )}
        />
      </div>

      {/*       
      <TextField
        ref={ref}
        id="address"
        label="address"
        onChange={handleChange}
        variant="outlined"
        className="form-control"
        value={values.address}
        required
      /> */}
      <TextField id="email" label="email address" onChange={handleChange} variant="outlined" className="form-control" value={values.email} required />
      <TextField id="phonenumber" label="phone number" onChange={handleChange} variant="outlined" className="form-control" value={values.phonenumber} required />
      <div className="button-group"></div>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  currentCustomer: state.currentCustomer.customerInfo,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDetailForm);
