import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, Divider, Typography, Button, Box, makeStyles, Chip } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { getFormattedDate } from 'src/utils/date-time';
import SendIcon from '@material-ui/icons/Send';


const RefundRequestCard = ({ refundRequest }) => {

    const useStyles = makeStyles((theme) => ({
        footerBox: {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            padding: theme.spacing(2)
        },
        moneyChip: {
            backgroundColor: '#b3ffb3'
        }
    }))

    const classes = useStyles();


    return <Card>

        <Box className={classes.footerBox}>
            <Box mb={1}>
                <Typography variant="h6">
                    {'Refund request # :  ' + refundRequest.refundRequestNumber}
                </Typography>
                <Typography variant="body1">
                    {getFormattedDate(refundRequest.refundRequestDate)}
                </Typography>
            </Box>
            <RouterLink to={`/account/refundRequests/${refundRequest.storeId}/${refundRequest.refundRequestId}`}><Button fullWidth variant="contained" color="primary" endIcon={<SendIcon />}>
                Details
            </Button></RouterLink>
        </Box>

    </Card>
}

export default RefundRequestCard;