export const fonts = {
    'Roboto': {
        url:'https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto+Slab|Roboto:300,400,500,700',
        fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif;'
    },
    'RobotoCondensed': {
        url:'https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap',
        fontFamily: "'Roboto Condensed', sans-serif;"
    },
    'Akaya Telivigala':{
        url:'https://fonts.googleapis.com/css2?family=Akaya+Telivigala&display=swap',
        fontFamily:"'Akaya Telivigala', cursive"
    },
    'Bebas Neue':{
        url:"https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap",
        fontFamily: "'Bebas Neue', cursive;"

    },
    'Lato':{
        url:"https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap",
        fontFamily:"'Lato', sans-serif;"
    }
}