import {takeLatest,call,put} from 'redux-saga/effects';
import axios from 'axios';
import {
  FetchCustomerOrderHistoryError,
  FetchCustomerOrderHistorySuccess,
  SetCustomerInfo
} from "./current-customer-actions";
import { ClearCart } from '../cart/cart.actions';
import { SetSnackNotice } from '../app/app-actions';




export function* fetchCustomerOrderHistoryStart()
{
    yield takeLatest('FETCH_CUSTOMER_ORDER_HISTORY', fetchCustomerOrderHistoryStartAsync);
}


export function* clearEntireCart()
{
    yield takeLatest('SET_CUSTOMER_INFO', clearEntireCartAsync);
}

export function* clearEntireCartAsync(action)
{
    
        try
        {
            if(Object.keys(action.payload).length === 0)
            {
                yield put(ClearCart());
            }
        }
        catch(e)
        {
            yield put(ClearCart());
        }
   
}






export function* fetchCustomerOrderHistoryStartAsync(action)
{

    try
    {
        const orderHistory = yield fetchCustomerOrderHistory(action.payload);

        yield put(FetchCustomerOrderHistorySuccess(orderHistory));
    }
    catch(e)
    {
        if(e.code === 'CUSTOM_3')
        {
          yield put(SetCustomerInfo({}));
          yield put(SetSnackNotice({
            severity: 'warning',
            message: e.message
          }));
        }

        yield put(FetchCustomerOrderHistoryError(e.message));
    }
}


const fetchCustomerOrderHistory = async (customerId)=>
{

    const response = await axios({
        url:'https://kripson-store-server-8qq76.ondigitalocean.app/stores/getCategories',
        method:'POST',
        data:{customerId}
    });
    if(response.data.status === 1)
    {
  
      return(response.data.result);
    }
    else if(response.data.status === 3)
    {
      const err = new Error(response.data.message);
      err.code = 'CUSTOM_3';
      throw err;
    }
    else
    {
      return ([]);
    }

};







