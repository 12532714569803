import { addItemToCart } from "./cart.utils.js";
import { removeItemFromCart } from "./cart.utils";
import { decreaseItemQuantity } from "./cart.utils";

const INITIAL_STATE = {
  hidden: true,
  stores: {},
};

export const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "TOGGLE_CART": {
      return {
        ...state,
        hidden: !state.hidden,
      };
    }
    case "CLEAR_STORE_CART": {
      return {
        ...state,
        stores: {
          ...state.stores,
          [action.payload.storeId]: {
            cartItems: [],
            discountMap: {}
          },
        },
      };
    }
    case "CLEAR_CART": {
      return {
        ...state,
        stores: {},
      };
    }
    case "REMOVE_ITEM": {
      return {
        ...state,
        stores: {
          ...state.stores,
          [action.payload.storeId]: state.stores.hasOwnProperty(action.payload.storeId)
            ? {
              ...state.stores[action.payload.storeId],
              cartItems: removeItemFromCart(state.stores[action.payload.storeId].cartItems, action.payload.item),
            }
            : {
              cartItems: removeItemFromCart([], action.payload.item),
              discountMap: {}
            },
        },
      };
    }
    case "ADD_ITEM": {
      return {
        ...state,
        stores: {
          ...state.stores,
          [action.payload.storeId]: state.stores.hasOwnProperty(action.payload.storeId)
            ? {
              ...state.stores[action.payload.storeId],
              cartItems: addItemToCart(state.stores[action.payload.storeId].cartItems, action.payload.item, action.payload.quantity),
            }
            : {
              cartItems: addItemToCart([], action.payload.item, action.payload.quantity),
              discountMap: {}
            },
        },
      };
    }
    case "DECREASE_QUANTITY": {
      return {
        ...state,
        stores: {
          ...state.stores,
          [action.payload.storeId]: state.stores.hasOwnProperty(action.payload.storeId)
            ? {
              ...state.stores[action.payload.storeId],
              cartItems: decreaseItemQuantity(state.stores[action.payload.storeId].cartItems, action.payload.item),
            }
            : {
              cartItems: decreaseItemQuantity([], action.payload.item),
              discountMap: {}
            },
        },
      };
    }
    case "SET_STORE_CART": {
      return {
        ...state,
        stores: {
          ...state.stores,
          [action.payload.storeId]: {
            ...state.stores[action.payload.storeId],
            cartItems: action.payload.items,
          },
        },
      };
    }
    case "SET_STORE_CART_DISCOUNT_MAP": {
      return {
        ...state,
        stores: {
          ...state.stores,
          [action.payload.storeId]: {
            ...state.stores[action.payload.storeId],
            discountMap: action.payload.discountMap,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};
