import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { FetchStoreCategoriesStart, FetchStoreIntegrationsStart, SetStoreInfo } from "../../redux/current-store/current-store-actions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { FetchStoresStart } from 'src/redux/app/app-actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
  }
}));

const StoreLayout = ({ FetchStoreCategoriesStart, FetchStoreIntegrationsStart, FetchStoresStart, setStoreInfo, stores, existingStoreId, categories, storeInfo }) => {
  const classes = useStyles();
  const { storeId, category, productId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    if (existingStoreId === storeId) {
      FetchStoreCategoriesStart(storeId);
      FetchStoreIntegrationsStart(storeId);
    }
    else {
      FetchStoresStart({ _id: storeId });
    }

  }, [existingStoreId]);

  useEffect(() => {
    if (stores.length && existingStoreId !== storeId) {
      setStoreInfo({ ...stores.find(store => store._id === storeId) });
    }

  }, [stores])




  useEffect(() => {
    let allCategoryId = '';
    if (categories.length) {
      const locationParts = location.pathname.split('/');
      if (locationParts[locationParts.length - 1] === storeId) {
        navigate(`/stores/${storeId}/products`);
      }
    }

  }, [categories]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        storeId={storeId}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => (
  {
    FetchStoreCategoriesStart: (storeId) => dispatch(FetchStoreCategoriesStart(storeId)),
    FetchStoresStart: (filter) => dispatch(FetchStoresStart(filter)),
    FetchStoreIntegrationsStart: (storeId) => dispatch(FetchStoreIntegrationsStart(storeId)),
    setStoreInfo: (info) => dispatch(SetStoreInfo(info)),

  });
const mapStateToProps = state =>
({
  existingStoreId: state.currentStore.storeInfo._id,
  categories: state.currentStore.categories,
  stores: state.app.stores,
});


export default connect(mapStateToProps, mapDispatchToProps)(StoreLayout);
