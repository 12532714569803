import { Typography } from "@material-ui/core";
import React from "react";
import './order-error.scss';

const OrderError = (props) => {
  return (
    <div className="orderError">
        <Typography variant="h3">Your order placement was unsuccessful</Typography>
      <img alt="Your order placement was unsuccessful." className="error-image" src="/static/images/order-error.svg" />
    </div>
  );
};

export default OrderError;
